import React from 'react'

function Notfound() {
  return (
    <>
      <div className='notfound_page_wrapper'>
        {/* <div className='notfound_error_box flex items-center justify-center'>
        <h1 className='border-r-2 pr-5'>404</h1>
           <p className='pl-5'> This page could not be found.</p>
        </div> */}
         <img
      className="h-[87vh]  object-contain object-center"
      src="./notfound/Page-Not-Fount-2.jpg"
      alt="nature image"
    />
    </div>
    <div class="mt-6  flex items-center justify-center">
      <a href="/" className='inline-block'>
        <button class="bg-[#66BE5E] w-full  py-3 px-11 rounded text-white btn_hover">Go Back</button>
      </a>
    </div>
    </>
  )
}

export default Notfound