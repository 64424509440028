import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {useNavigate} from "react-router-dom"
import axios from 'axios'
import NavyaImg from '../../assets/images/loginPageSigeImg.png'
import PinInput from 'react-pin-input';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Audio } from 'react-loader-spinner'
import NoSigningfooter from './NoSigningfooter'
import UseAxios from '../../util/UseAxios'

function SendOtp() {

  
    let newObject = window.localStorage.getItem("otp_data");
    const data = JSON.parse(newObject);
    let dataType = data?.type
    const instance = UseAxios()

  const navigate = useNavigate();


  let [otp, setOtp] = React.useState('')
  const[disableBtn,setDisableBtn]=useState(true)
  let [otpResponseData, setOtpResponseData] = React.useState('')
  let [otpErrorData, setOtpErrorData] = React.useState('')
  const [spinner, setSpinner] = React.useState(false);


      const handleOtpChange = (value) => {
        const otpValue = Array.from(value).reduce((acc, currentValue) => {
          return acc + currentValue;
        }, '');

        if(otpValue.length<6){
          setDisableBtn(true)
        }else{
          setDisableBtn(false)
        }
        setOtp(otpValue);
      }

      const otp_json = {
        ...data,
        otp: parseInt(otp),
      }
    
  const otpSubmit = (event) => {
    event.preventDefault()
    if(otp.length < 6){
      toast.error('Not A Valid Otp')
    }
    setSpinner(true);

    instance
      .post('/api/v1/auth/forget-password/verify-otp', otp_json)
      .then((response) => {
        console.log(response)
          toast.success('Verified Successfully')
          setOtpResponseData(response.data)
          localStorage.setItem('otp_data',JSON.stringify(otp_json));
          setTimeout(() => {
            navigate('/changePass'); // Navigate to other page after 2 seconds
          }, 2000);
        // }
      })
      .catch((error) => {
        console.log(error)
        setSpinner(false);

        if (error) {
          // console.log(error)
          toast.error(error.response.data.msg)
        }
      })
  }

  const [resendSpineremail,setresendSpineremail] = useState(false)
      function ResendOtp(){
   console.log(data)
          setresendSpineremail(true)
          const email_data =data

      
        instance
            .post('api/v1/auth/forget-password/generate-otp',email_data )
            .then((response) => {
              console.log(response)
              setresendSpineremail(false)
              toast.success(response.data.msg)
            })
            .catch((error) => {
              console.log(error)
              toast.error(error.response.data.msg)
              setresendSpineremail(false)
            
            })
          
        
          
      }

  
  const clearResponse = () => {
    console.log("jdfhsadvdfghfdh")
    localStorage.clear();
    console.log("after local clear ")
        navigate('/forgot_password',
        {
          state: dataType === "email" 
            ? { mail: data.email, country_code: data.country_code }
            : { phone_no: data.phone_no, country_code: data.country_code }
        });
  };
  

  return (
    <>
     <ToastContainer />


    {spinner && (
      <Audio
        height="80"
        width="80"
        radius="9"
        color="green"
        ariaLabel="loading"
        background="transparent"
        wrapperClass="loader"
      />
    )}
    <div className='flex login_signup_main_section'>
     
            <div className='basis-1/2 md:block hidden'>
                <img src={NavyaImg} alt="" className='img-fluid login_pgae_img' />
            </div>
            <div className='w-[40%] mx-auto p-5 flex justify-between flex-col'>
                <div>
                    <div>
                        <button onClick={clearResponse}><i className='fa fa-arrow-left font-normal'></i><span className='px- text-lg' > Back</span></button>
                    </div>
                    <div className='font-bold text-2xl pt-8'>
                        <h1>Verification Code</h1>
                    </div>
                    <div className='pt-2 '>
                        <p>We texted a one-time verification code To</p>
                        <p>{dataType === "phone" ? "+"+data.country_code + " " + data.phone_no :data.email} <span className='text-[red] cursor-pointer' onClick={clearResponse}>Edit Email?</span></p>
                        
                    </div>

                    <div className='pt-10'>
                    <form onSubmit={otpSubmit}>
                      <PinInput
                          length={6} 
                          // initialValue=""
                          // secret
                          // secretDelay={100} 
                          onChange={handleOtpChange} 
                          type="numeric" 
                          inputMode="number"
                          style={{padding: '10px',display:'flex',justifyContent:"space-between"}}
                          inputFocusStyle={{borderColor: '#D1D1D1'}}
                          onComplete={handleOtpChange}
                          autoSelect={true}
                          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                          name="otp"
                          id="otp"
                          value={otp.split('')}
                          focus={true}
                          className="opt_input"

                        />

                      <div>
                        <div className='flex justify-end select-none'>
                      { !resendSpineremail ? <h6 onClick={ResendOtp} className='text-[#3085d6] cursor-pointer'>Resend Otp
                          </h6>
                      : <div className='loadingbox'>
                           <div class="resendloading"></div>

                      </div>}
                      </div>
                      <div className='my-7'>
                                <div className='bg-[#66BE5E] rounded py-3' >
                                    <input  disabled={disableBtn} type="submit" name="" id="" className='w-full border-none focus:border-none focus:outline-none text-white' value="Verify And Continue" />
                                </div>
                            </div>
                      </div>
                    </form>
                    </div>
                </div>
                <NoSigningfooter/>
            </div>
        </div>
      </>
  )
}

export default SendOtp