import React from 'react'
import UseAxios from '../../util/UseAxios'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import ReactHtmlParser from 'html-react-parser';
function Privacypolicy({mode}) {
    const instance = UseAxios()
    const usertoken = localStorage.getItem('user_token')
    const [spinner, setSpinner] = React.useState(false)
    let [responseData, setResponseData] = React.useState('')
    let [errorData, setErrorData] = React.useState('')
  
    // const usertoken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDcxODEwYWJhMzc0OGZmZDc4ZjAwN2IiLCJyb2xlIjoidXNlciIsImlhdCI6MTY4NTI1MTUzOSwiZXhwIjoxNjg3ODQzNTM5fQ.5V86CNEz3a_OEzpCMaAF4b5bW-vhigMqUi7a_LIugBQ';
  
    React.useEffect(() => {
      const fetchData = async () => {
        setSpinner(true)
  
        try {
         
  
          const response = await instance.get('/api/v1/app/privacy-policy')
          console.log(response.data)
          // setResponseData(response.data.privacy_policy)
  
          setResponseData({
            ...response.data.privacy_policy,
            description: response.data.privacy_policy.description.replaceAll(
              '&lt;',
              '<'
            ),
          });
  
  
          setSpinner(false)
        } catch (error) {
          setSpinner(false)
          setErrorData(error)
        }
      }
  
      fetchData()
    }, [usertoken])



  return (
    
    
    <section  className=" tabs_side_gap">
        
            <div className="w-[100%]  m-auto other_tabs_headings">
            {spinner && 
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loder_raiseticket"
        />
      }
              <div style={{color:mode==="dark"&&"white"}}
                dangerouslySetInnerHTML={{
                  __html: responseData.description,
                }}
              ></div>

              {/* <ul>
                <li>
                  {' '}
                  <FaLongArrowAltRight className="icons" />
                  Donec quis urna sagittis, pulvinar justo id, imperdiet elit.
                  Quisque ornare nibh mauris, quis.
                </li>
                <li>
                  {' '}
                  <FaLongArrowAltRight className="icons" /> Donec quis urna
                  sagittis, pulvinar justo id, imperdiet elit. Quisque ornare
                  nibh mauris, quis efficitur diam facilisis{' '}
                </li>
                <li>
                  {' '}
                  <FaLongArrowAltRight className="icons" /> cus. Morbi in porta
                  quam. Phasellus ut felis pulvinar, maximus ante ut, porta
                  nunc. Integer porttitor nulla vitae enim elementum,
                  ullamcorper molestie arcu accumsan. Pellentesque imperdiet
                  justo....
                </li>
                <li>
                  {' '}
                  <FaLongArrowAltRight className="icons" /> cus. Morbi in porta
                  quam. Phasellus ut felis pulvinar, maximus ante ut, porta
                  nunc. Integer porttitor nulla vitae enim elementum,
                  ullamcorper molestie arcu accumsan. Pellentesque imperdiet
                  justo....
                </li>
              </ul> */}

              {/* <p className="pt-3">
                Donec quis urna sagittis, pulvinar justo id, imperdiet elit.
                Quisque ornare nibh mauris, quis efficitur diam facilisis
                rhoncus. Morbi in porta quam. Phasellus ut felis pulvinar,
                maximus ante ut, porta nunc. Integer porttitor nulla vitae enim
                elementum, ullamcorper molestie arcu accumsan. Pellentesque
                imperdiet justo....{' '}
              </p> */}
            </div>
          </section>
  )
}

export default Privacypolicy