import { Fragment, useState,useRef,useEffect, useContext } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import context from 'react-bootstrap/esm/AccordionContext';
import { useNavigate } from 'react-router';
import { MdOutlineDeleteForever } from 'react-icons/md'

function Deletemodel(props) {
  return (
    <Transition show={props.open} as={Fragment}>
    <Dialog
      as="div"
      className="relative z-10"
      // initialFocus={cancelButtonRef}
      onClose={props.setOpen}
    
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4  ">
                <div className="success_edit_profile">
                  <i className='text-4xl pb-2 text-[red]'><MdOutlineDeleteForever/></i>
                  <h4>Delete </h4>
                  <p>Do You Really want to Delete ?</p>
                </div>

                <div className="Personal_Info_div ">
                  <div className="deactivate_modal">
         
                      <div className="text-center">
                        <div className="text-center Deactivate_tab_buttons">
                          <button
                            className=" rounded text-white font-semibold btn_hover"
                            onClick={()=>props.setOpen(false)}
                          >
                            No , Go Back
                          </button>
                          <button
                            className=" rounded  font-semibold Deactivate_btn btn_hover_white"
                            onClick={() => props.deletapi()}
                          >
                            Yes, Delete
                          </button>
                        </div>
                      </div>
                  
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
  )
}

export default Deletemodel