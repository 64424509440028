import React, { useEffect, useRef } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { userContext } from '../../Context/UserContextProvider';

function ReCaptcha() {
  // const recaptchaRef = useRef(null);
    const {capVal,setCapval,recaptchaRef }=React.useContext(userContext)
    
    function reCaptcha(e){
        setCapval(e)
    }
    // const location =window.location.hostname
     
    //  console.log(location)



  return (
    <div className='flex justify-center w-full' >

                
                <ReCAPTCHA  
                 ref={recaptchaRef}
                  // sitekey={location === "localhost" ?"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" :"6Ld4_SopAAAAAOVuzaNjSBQOMgABhaobYZqgNnMO6Ld4_SopAAAAAOVuzaNjSBQOMgABhaobYZqgNnMO"}
                  // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // test site key 
                  sitekey="6Ld4_SopAAAAAOVuzaNjSBQOMgABhaobYZqgNnMO" //domain site key 
                  onChange={reCaptcha}
                 
                    
                 
                 
                />
                </div>
  )
}

export default ReCaptcha;