import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {useNavigate} from "react-router-dom"
import axios from 'axios'
import NavyaImg from '../../assets/images/loginPageSigeImg.png'
import PinInput from 'react-pin-input';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Audio } from 'react-loader-spinner'
import { FcHighPriority,FcApproval } from "react-icons/fc";
import NoSigningfooter from './NoSigningfooter'
import Cookies from 'js-cookie'
import UseAxios from '../../util/UseAxios'


function PhoneNotVerify() {


  const navigate = useNavigate();

  const location = useLocation()
  console.log(location)
  const [email, setEmail] = useState(location.state.phone)
  const [phone, setPhone] = useState(location.state.phone)

 

  let [otp, setOtp] = React.useState('')
  let [Potp, setPotp] = React.useState('')
  let [otpResponseData, setOtpResponseData] = React.useState('')
  let [otpErrorData, setOtpErrorData] = React.useState('')
  const [spinner, setSpinner] = React.useState(false);
  const [emaildisabled, setEmaildisabled] = React.useState(true);
  const [phonedisabled, setPhonedisabled] = React.useState(true);
  const[EotpDisable,setEOtpDisable]=useState(false)
  

  const handleOtpChange = (value) => {
    const otpValue = Array.from(value).reduce((acc, currentValue) => {
      return acc + currentValue;
    }, '');
    if(otpValue.length < 6){
      setPhonedisabled(true)
    }else{
      setPhonedisabled(false)
    }
    setOtp(otpValue);
  }
  const handlePOtpChange = (value) => {
    const otpValue = Array.from(value).reduce((acc, currentValue) => {
      return acc + currentValue;
    }, '');
    if(otpValue.length<6){
      setPhonedisabled(true)
    }else{
      setPhonedisabled(false)
  }
    setPotp(otpValue);

  }
 

    const instance = UseAxios()

     
    

      const Potp_json = {
        country_code: location.state.country_code,
        phone_no: phone,
        otp: parseInt(Potp),
      }
    
    
      
      const [formSubmitted, setFormSubmitted] = useState(false);

      const otpSubmit = (event) => {
        console.log("click button")
      event.preventDefault()
      setFormSubmitted(true);
      if (formSubmitted) {

      
        setSpinner(true);

        instance
          .post('api/v1/auth/account-verification/activate-account', Potp_json)
          .then((response) => {
            console.log(response)
            toast.success('Phone Verified Successfully')
              setOtpResponseData(response.data)
              setSpinner(false);
          
                navigate('/signIn'); 
           
            

          })
          .catch((error) => {
            console.log(error)
            setSpinner(false);
            toast.error(error.response.data.msg)
          
          })
      }

      
      }
const [resendSpiner,setresendSpiner] = useState(false)
const [resendSpinerphone,setresendSpinerphone] = useState(false)


      
function ResendOtp(){
        
  setresendSpinerphone(true)
  const Pacc_jason = {
    country_code: location.state.country_code,
    phone_no:phone

  }
  instance
  .post('/api/v1/auth/account-verification/generate-otp', Pacc_jason)
  .then((response) => {
    console.log(response)
    toast.success(response.data.msg)
    setresendSpinerphone(false)
  })
  .catch((error) => {
    console.log(error)
    toast.error(error.response.data.msg)
    setresendSpinerphone(false)
  })
          
        
       
      }

useEffect(()=>{
    ResendOtp()
},[phone])


  
  const clearResponse = () => {
    localStorage.clear();
    Cookies.remove('user_token')
        navigate('/signIn');
  };
  

  return (
    <>
      <ToastContainer />

    {spinner && (
      <Audio
        height="80"
        width="80"
        radius="9"
        color="green"
        ariaLabel="loading"
        background="transparent"
        wrapperClass="loader"
      />
    )}
    <div className='flex login_signup_main_section'>
      {/* <ToastContainer /> */}
            <div className='basis-1/2 md:block hidden'>
                <img src={NavyaImg} alt="" className='img-fluid login_pgae_img' />
            </div>
            <div className='w-[40%] mx-auto p-5 flex justify-between flex-col'>
                <div>
                    <div>
                        <button onClick={clearResponse}><i className='fa fa-arrow-left font-normal'></i><span className='px- text-lg' > Back</span></button>
                    </div>
                    <div className='font-bold text-2xl pt-8'>
                        <h1>Verification Code</h1>
                    </div>
                    <div className='pt-2'>
                        <p>We texted a one-time verification code To</p>
                        
                          <p className='flex items-center'>+{location.state.country_code} {phone}  <span className='flex items-center pl-1'>
                            <FcHighPriority /> 
                            {/* <FcApproval /> */}
                            </span></p>
                        {/* <p className='text-[red] select-none ps-1 cursor-pointer' onClick={clearResponse} >Edit Email?</p> */}
                        
                    </div>

                    <div className='pt-10'>
                    <form onSubmit={(event) => {otpSubmit(event,'phone');
                    }}>
                      <h5>Phone Verification </h5>
                      <PinInput
                          length={6} 
                          initialValue=""
                          // secret
                          // disabled={phonedisabled}
                          // secretDelay={100} 
                          onChange={handlePOtpChange} 
                          type="numeric" 
                          inputMode="number"
                          style={{padding: '10px',display:'flex',justifyContent:"space-between"}}
                          inputFocusStyle={{borderColor: '#D1D1D1'}}
                          onComplete={handlePOtpChange}
                          autoSelect={true}
                          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                          name="otp"
                                id="otp"
                                value={otp.split('')}
                                className="opt_input"

                        />
                       
                        <div className='flex justify-end select-none'>
                        { !resendSpinerphone ? <button  onClick={()=>{ResendOtp()}} className={'text-[#3085d6] cursor-pointer'}>Resend Phone Otp
                          </button>
                      : <div className='loadingbox'>
                           <div class="resendloading"></div>

                      </div>}
                      
                        </div>                              
                        <button 
                           disabled={phonedisabled}
                            type="submit" name="" id="" 
                            className={`${phonedisabled ? "bg-[#a0a0a0]" :"bg-[#66BE5E]"} w-full py-3 border-none focus:border-none focus:outline-none text-white  rounded my-7`} value="Verify And Continue" >
                            Verify And Continue
                        </button>
                        
                          
                    
                    </form>
                    </div>
                 
                </div>
                <NoSigningfooter/>
            </div>
        </div>
      </>
  )
}

export default PhoneNotVerify