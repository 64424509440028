import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  MdOutlineKeyboardBackspace,
  MdOutlineDeleteForever,
} from "react-icons/md";
import { BsCheckCircle } from "react-icons/bs";
import { CiDiscount1 } from "react-icons/ci";
import { Link } from "react-router-dom";
import GrandTotal from "../Payment/GrandTotal";
import SummaryItems from "../Payment/SummaryItems";
import LoginNavbar from "../LoginNavbar/LoginNavbar";
import Footer from "../Footer/Footer";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NoCourses from "../new/NoCourses";
import PaymentMenthodCard from "../Payment/PaymentMethodCard";
import PriceSummary from "../Payment/PriceSummary";
import useRazorpay from "react-razorpay";
import { useCallback } from "react";
import CryptoJS from "crypto-js";
import { userContext } from "../../Context/UserContextProvider";
import no_events from "../../assets/images/no.png";
import ReactHtmlParser from "html-react-parser";
import { IoPricetagsOutline } from "react-icons/io5";

import loreal_img from '.././../assets/images/logoonly.png'

import Paystatuspayment from "./Paystatuspayment";
import Deletemodel from "../modal/Deletemodel";
import UseAxios from '../../util/UseAxios'
import MetaData from "../MetaTitle/MetaData";

// const purchaseDetails =()=>{
//   let courseData = localStorage.getItem('courseid')
//   if(!courseData){
//     return []
//   }else{
//     return JSON.parse(courseData)
//   }
// }

function Orders() {
  const {
    setContextcart_length,
    mode,
    contextcart_length,
    merchantransaction_id,
    setMerchantTransactionId,
    profiledata
  } = React.useContext(userContext);

  const [Successopen, setSuccessOpen] = useState(false);
  const SuccesshandleShow = (e) => {
    return e.preventDefault(), setSuccessOpen(true);
  };
  const SuccesshandleClose = (e) => {
    return e.preventDefault(), setSuccessOpen(false);
  };
  const cancelButtonRef = useRef(null);
  const [showPrice, showPriceState] = React.useState([]);

  const usertoken = localStorage.getItem("user_token");
  const [spinner, setSpinner] = React.useState(false);
  const[emptyCart, setEmptyCArt]=useState(false)

  let [tresponseData, setTresponseData] = React.useState([]);
  let [terrorData, setTerrorData] = React.useState("");

  let [totalDiscountedPrice, settotalDiscountedPrice] = React.useState(0);
  let [totalOriginalPrice, settotalOriginalPrice] = React.useState(0);
  let [totalplanspoints, setTotalplanspoints] = React.useState(0);
  let [gstadded, setGstadded] = React.useState(0);
  let [totalorgstadded, setTotalorGstadded] = React.useState(0);
  let [totaldisPer, settotaldisPer] = React.useState(0);
  let [planchecked, setPlanchecked] = React.useState(false);
  // const[courseId,setCouseId]=useState(purchaseDetails())

  console.log(totalorgstadded);
  const instance = UseAxios()

  console.log(tresponseData);

  //  useEffect(()=>{
  //   localStorage.setItem('purchasedetails',JSON.stringify(courseId))
  //  },[purchaseDetails])

  const toggleShowPrice = (id) => {
    // Check if the id is already in showPrice array
    const isIdInArray = showPrice.includes(id);

    // Use the spread operator to create a new array with the toggled values
    const updatedShowPrice = isIdInArray
      ? showPrice.filter((itemId) => itemId !== id)
      : [...showPrice, id];

    // Update the state
    showPriceState(updatedShowPrice);
  };

  // ==========================code of Atiq=========================
  const [purchasedCourses, setpurchasedCourse] = useState([]);
  const [purchasedPlan, setPurchasedPal] = useState([]);

  const fetchData = async (cartdata) => {
    let id = cartdata[0]?.course._id;

    try {
      const response = await instance.get(
        "/api/v1/courses/transaction/my-orders"
      );
      let data = response.data.data;
      const uniqueIds= data.map((x)=>x.planId)
      setPurchasedPal(uniqueIds)
      setpurchasedCourse(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchcartData = async () => {
    setSpinner(true);

    try {
      const response = await instance.get(
        "/api/v1/courses/transaction/my-cart"
      );
      console.log(response.data.courses);
      if(!response.data.courses.length){
        setEmptyCArt(true)
      }

      setTresponseData(
        response.data.courses.filter((x) => {
          return  x.course != null
        })
      );
      setContextcart_length(response.data.courses.length);
      fetchData(response.data.courses);
     
    } catch (error) {
      setSpinner(false);
      setTerrorData(error);
    }
    setSpinner(false);
  };

  React.useEffect(() => {
    fetchcartData();
  }, [usertoken, setContextcart_length]);

  let [totalamount, setTotalamount] = React.useState([]);
  // const newTotalDiscountPrice = totalamount.reduce((total, item) => total + item.discountprice, 0)
  console.log(totalamount);
  console.log(totalDiscountedPrice);

  function pricestore(
    e,
    originalprice,
    discountprice,
    points,
    series_id,
    cart_id
  ) {
    // console.log(course_id)
    //  if(!courseId.length){
    //   setCouseId(pre=>[...pre,course_id])
    //  }else{
    //   courseId.includes(course_id)===false && setCouseId(pre=>[...pre,course_id])
    //  }
    //  localStorage.setItem('courseid',JSON.stringify(courseId))
    // Check if an item with the given series_id already exists in totalamount
    const existingItemIndex = totalamount.findIndex(
      (item) => item.series_id === series_id
    );

    if (existingItemIndex !== -1) {
      // If an item with the same series_id exists, update its properties
      setTotalamount((prevState) =>
        prevState.map((item, index) => {
          if (index === existingItemIndex) {
            return {
              ...item,
              discountprice,
              originalprice,
              points,
            };
          }
          return item;
        })
      );
    } else {
      // If no item with the same series_id exists, add a new item
      setTotalamount((prevState) => [
        ...prevState,
        { series_id, discountprice, originalprice, points },
      ]);
    }
  }

  useEffect(() => {
    const totaldiscount = totalamount.reduce(
      (total, item) => total + item.discountprice,
      0
    );
    const totaloriginal = totalamount.reduce(
      (total, item) => total + item.originalprice,
      0
    );
    const pointplans = totalamount.reduce(
      (total, item) => total + item.points,
      0
    );
    const totalplanspoints_1 = pointplans;
    if (totaloriginal !== 0) {
      const discountPercentage = (totaldiscount / totaloriginal) * 100;
      settotaldisPer(discountPercentage.toFixed(2));
    }
    console.log(totaldiscount);
    settotalDiscountedPrice(totaldiscount);
    settotalOriginalPrice(totaloriginal);

    const gstadded = (totalDiscountedPrice / 100) * 18;
    setGstadded(gstadded.toFixed(2));
    if (
      totalplanspoints_1 <= refpointresponseData.remaining_points ||
      totalamount.points <= refpointresponseData.remaining_points
    ) {
      setTotalplanspoints(totalplanspoints_1);
      // let remaining_points_value = refpointresponseData.remaining_points -totalplanspoints
      // setTotalplanspoints()
      // console.log(remaining_points_value);
    } else {
      setTotalplanspoints(refpointresponseData.remaining_points);
    }
    let sum_of_gst_totalprice = totalDiscountedPrice + gstadded;
    let point_minus = totalDiscountedPrice - totalplanspoints / 1;
    setTotalorGstadded(point_minus);
  }, [totalamount, gstadded, totalDiscountedPrice, totalplanspoints]);

  // referal points user
  let [refpointresponseData, setrefpointResponseData] = React.useState("");
  let [refpoint, setRefpoint] = React.useState("");
  let [refpointerrorData, setrefpointErrorData] = React.useState("");

  const fetchpointData = async () => {
    // setSpinner(true)

    try {
      const response = await instance.get(
        "/api/v1/auth/referral/referred-user"
      );
      // console.log(response.data)
      setrefpointResponseData(response.data);
      // setSpinner(false)
    } catch (error) {
      // setSpinner(false)
      setrefpointErrorData(error);
    }
  };
  React.useEffect(() => {
    fetchpointData();
  }, [usertoken]);

  // open popup and  delete course autoher: Nitesh
  let [prfresponseData, setprfResponseData] = React.useState("");
  let [prferrorData, setprfErrorData] = React.useState("");

  let [noplanselect, setNoplanselect] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [deletecourse_id, setDeletecourse_id] = React.useState("");
  function deletecourse(course_delete_id) {
    setOpen(true);
    setDeletecourse_id(course_delete_id);
  }

  const runLikeApi = () => {
    // event.preventDefault()
    setSpinner(true);
    instance
      .delete("/api/v1/courses/transaction/my-cart/" + deletecourse_id)
      .then((response) => {
        setSpinner(false);

        setprfResponseData(response.data);
        // console.log(response.data)
        setOpen(false);
        toast.success(response.data.msg);
        fetchcartData();
      })
      .catch((error) => {
        // console.log(error)

        setSpinner(false);
        toast.error(error.response.data.msg);
        setprfErrorData(error.response);
      });

    // window.location.reload();
  };

  ///check if already bought

  let [purchasedresponseData, setpurchasedResponseData] = React.useState([]);
  let [purchaseerrorData, setpurchaseErrorData] = React.useState("");

  // console.log(purchasedresponseData);
  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true);

      try {
        const response = await instance.get(
          "/api/v1/courses/transaction/my-plans"
        );
        // console.log(response.data.plans)
        setpurchasedResponseData(response.data.plans);
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setpurchaseErrorData(error);
      }
    };

    fetchData();
  }, [usertoken]);

  const extractCommonIds = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
      return []; // Return an empty array if either of the inputs is not an array
    }

    const commonIds = arr1.reduce((result, item1) => {
      // if (arr2.some((item2) => item1.plan_purchased._id === item2.added_plan._id)) {
      //   result.push(item1.plan_purchased._id);
      // }
      return result;
    }, []);

    return commonIds;
  };

  const commonIds = extractCommonIds(purchasedresponseData, tresponseData);

  // console.log(commonIds.length);

  //payment

  const [isCheckboxChecked, setIsCheckboxChecked] = React.useState(false);
  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };


  const purchases_plans = {
    totalAmount: "",
    discount: "",
    referredAmount: "",
    gst: "",
    transaction_id: "",
    plans_id: [],
    cart_id: [],
  };

  function addpurchasedata(transid) {
    purchases_plans.totalAmount = totalorgstadded.toFixed(2);
    purchases_plans.discount = totalOriginalPrice;
    purchases_plans.referredAmount = totalplanspoints / 10;
    purchases_plans.gst = gstadded;
    purchases_plans.transaction_id = transid;

    const plan_id_selectedinp = document.getElementsByClassName(
      "plan_id_selectedinp"
    );
    for (let i = 0; i <= plan_id_selectedinp.length - 1; i++) {
      console.log(plan_id_selectedinp[i].attributes[1].value);
      console.log(plan_id_selectedinp);

      if (plan_id_selectedinp[i].checked === true) {
        console.log(
          plan_id_selectedinp[i].checked +
            ">>>>>id>>>>>>seee ---- " +
            plan_id_selectedinp[i].value
        );
        purchases_plans.plans_id.push(plan_id_selectedinp[i].value);

        // purchases_plans.cart_id.push(plan_id_selectedinp[i]?.attributes[1].value);

        const cartId = plan_id_selectedinp[i]?.attributes[1].value;
        if (cartId && !purchases_plans.cart_id.includes(cartId)) {
          purchases_plans.cart_id.push(cartId);
        }
        console.log(cartId);
      }
    }

    console.log(purchases_plans);
  }

 
  async function handlePayment(price, planId, isCheckboxChecked) {
    if(profiledata.phone_no_verified === false){
      toast.error("First Verify Your Mobile Number");
      return false;
    }
    setSpinner(true);


    if (totalorgstadded > 0) {
      try {
        // Define your PhonePe credentials
        const merchantId = "M1L4E1CXTG32"; //'YOUR_PHONEPE_MERCHANT_ID';
        const returnUrl = "YOUR_RETURN_URL";
        // Generate a unique order ID (you can use your own logic)
        const orderId = "123456"; // Replace with your order ID
        // const amount = parseInt(totalorgstadded); // Amount in paisa (100 paisa = 1 INR)
        const amount = totalorgstadded.toFixed(2); // Amount in paisa (100 paisa = 1 INR)

        // Prepare the request data
        const requestData = {
          // merchant_id: merchantId,
          // key: "78f0c756-7c7e-46eb-8ae5-720490557128",//test key
          // merchantId: "PGTESTPAYUAT91",//test merchant id
          // merchantUserId: "MUID123",// test  user id
          key: "78f0c756-7c7e-46eb-8ae5-720490557128",
          merchantId: "M1L4E1CXTG32",
          // merchantTransactionId: "MT7850590068188114",
          merchantUserId: "MUID123",
          amount:amount,
          currency: "INR",
        };

        // Send the payment request to your server
        const response = await instance
          // .post(`/api/v1/courses/transaction/payapi`, requestData)
          .post(`/api/v1/courses/transaction/payapi`, requestData)
          // .then((response) => {
            // Redirect to the PhonePe payment gateway
            console.log(response.data);
            console.log(response.data.data.merchantTransactionId);
            const newData = response.data.data.merchantTransactionId;
            localStorage.setItem("merchantTransactionId", newData);
            setMerchantTransactionId(response.data.data.merchantTransactionId);
            console.log(response.data);
            setSpinner(false)
            toast.success(response.data.message);
            sendpurchasedata(
              response.data.data.merchantTransactionId,
              response.data.data.instrumentResponse.redirectInfo.url
              
            );
          // });

      } catch (error) {
        toast.error(error?.response?.data?.message);
        console.error(error);
        setSpinner(false);
        // Handle any error that occurred during the process
      }
    } else {
      toast.error("Please select a plan ");
      setSpinner(false);
    }
  }


  const sendpurchasedata = async (transid, redirect_url) => {
    addpurchasedata(transid);
    setSpinner(true);

    console.log(purchases_plans);
    try {
      const response = await instance.post(
        "/api/v1/courses/order_initiate",
        purchases_plans
      );
      console.log(response.data.data._id);
      localStorage.setItem("orderid", response.data.data._id);
      setSpinner(false);
      // toast.success(response.data.message);
      window.location.href = redirect_url;
    } catch (error) {
      setSpinner(false);
      console.log(error.response.data.error ? error.response.data.error :error.message);
      toast.error(error.response.data.error ? error.response.data.error :error.message);
      console.log("status not send ");
    }
  };

  return (
    <>
         <MetaData title={"Navya | Cart"} canonicalUrl={"https://navyaedu.com/order_details"}/>
      <ToastContainer />

      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}
      <LoginNavbar></LoginNavbar>
      {tresponseData.length ? (
        <section className="order_details_page_main_section select-none">
          <div>
            {/* <OrderDetails /> */}
            <section className="">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 ">
                    <div className="payment_headings_main">
                      <h1 style={{color:mode==="dark" && "white"}} className="payment_headings">
                        You're Almost There To Complete!
                      </h1>
                      <h1
                      style={{color:mode==="dark" && "white"}}
                        className="payment_headings"
                        onClick={sendpurchasedata}
                      >
                        Your Order
                      </h1>
                    </div>

                    {/* <OrderDetailCard img={1}></OrderDetailCard> */}

                    <div className="container order_detail_card">
                      <div className="row">
                        <div className="col-12">
                          <div className="order_details_box_shadow">
                            {tresponseData?.map((course, indx) => (
                              <div
                                key={indx}
                                className="flex flex-col md:flex-row"
                              >
                                <div className="pl-7 pt-7 w-[20%]">
                                  <img
                                    src={
                                      course?.course?.course_image
                                        ? course?.course?.course_image
                                        : "../plceholdernav/placeholder-img.png"
                                    }
                                    className="w-full h-min img-fluid "
                                    alt=""
                                  />
                                </div>
                                <div className="w-full pb-10 pr-14 pl-10 pt-3">
                                  <div className="flex justify-between items-center">
                                    <h6 className="bg-[#d3fecf] text-[#66BE5E] px-2 py-1 rounded-2xl inline">
                                      {RegExp("&lt;").test(
                                        course?.course?.course_title
                                      )
                                        ? ReactHtmlParser(
                                            `${course?.course?.course_title.replaceAll(
                                              "&lt;",
                                              "<"
                                            )}`
                                          )
                                        : course?.course?.course_title}
                                    </h6>
                                    <div className="">
                                      {" "}
                                      <button
                                        className=""
                                        // onClick={() => runLikeApi(course?._id)}
                                        onClick={() =>
                                          deletecourse(course?._id)
                                        }
                                      >
                                        <p className="text-[red] p-2 rounded-2xl" style={{background:mode==="dark"&&"#151515"}}>
                                          <span>
                                            <i className="fa fa-trash"></i>
                                          </span>{" "}
                                          <span> Remove</span>
                                        </p>
                                      </button>
                                    </div>
                                  </div>

                                  <div className="mt-[-6px] ml-2">
                                    <h5>
                                      {RegExp("&lt;").test(
                                        course?.course?.course_subtitle
                                      )
                                        ? ReactHtmlParser(
                                            `${course?.course?.course_subtitle.replaceAll(
                                              "&lt;",
                                              "<"
                                            )}`
                                          )
                                        : course?.course?.course_subtitle}
                                    </h5>
                                  </div>

                                  <div className="pb-2">
                                    {course?.course?.course_series.map(
                                      (series) => {
                                        return (
                                          <div
                                            className="mt-2"
                                            key={series?._id}
                                          >
                                            <div
                                              className="flex justify-between course_package"
                                              onClick={() => {
                                                toggleShowPrice(series?._id);
                                              }}
                                            >
                                              <div className="flex items-center">
                                                {/* <div className='mr-2'>
                                                  <input type='checkbox' />
                                                </div> */}

                                                <div className="flex items-center">
                                                  <div
                                                    className="w-10  h-10 bg-[#dbdbdb] rounded-full"
                                                    style={{ minWidth: "40px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                  >
                                                    
                                                      <img className="w-[25px]" src={loreal_img} alt="" />
                                                    
                                                  </div>
                                                  <div className="mx-3">
                                                    <p className="font-semibold Title_on_coures mb-[-7px]">
                                                      {ReactHtmlParser(series?.series_name.replaceAll(
                                              "&lt;",
                                              "<"
                                            ))}
                                                    </p>
                                                    {/* <small>
                                                      30 Minutes{" "}
                                                      
                                                    </small> */}
                                                  </div>
                                                </div>
                                              </div>

                                              {/* <div className='text-center'>
                                                <i style={{ cursor: "pointer" }} 
                                                className={showPrice.includes(series?._id) ? "fa fa-caret-up text-[#acabab]" : "fa fa-caret-down text-[#acabab]"} ></i>
                                              </div> */}
                                            </div>
                                            {/* {showPrice.includes(series?._id) &&  */}
                                            <div className="Order_details_Plan flex gap-10 ml-[3.4rem] mb-3">
                                              {series?.course_series_plans.map(
                                                (plan) => {
                                                  return (
                                                    <>
                                                      <div
                                                        className="flex "
                                                        key={plan?._id}
                                                      >
                                                        {/* <div>
                                                        <label class="container-nine">
                                                          <input type="radio" onChange={(e)=>pricestore(e,plan.plan_original_price,plan.plan_discounted_price,plan.plan_points_redeemable)} name={series._id} />
                                                          <span class="checkmark-six"></span>
                                                        </label>
                                                      </div> */}
                                                        <div className="list-none ">
                                                          <div className="flex justify-between items-center gap-2">
                                                            <h6 className="mb-0 h-5 font-bold">
                                                              {
                                                                plan?.plan_duration
                                                              }{" "}
                                                              Months
                                                            </h6>
                                                            <li className="mx-0 font-bold">
                                                              Save Point:
                                                              {
                                                                refpointresponseData?.remaining_points
                                                              }{" "}
                                                              Points :{" "}
                                                              {
                                                                plan?.plan_points_redeemable
                                                              }{" "}
                                                            </li>
                                                          </div>
                                                          <li className="my-1 italic">
                                                            {" "}
                                                            {
                                                              plan?.plan_description
                                                            }{" "}
                                                          </li>

                                                          <label class="container-nine inline-flex">
                                                            <input
                                                            // style={{opacity:`${purchasedPlan.includes(plan._id)} && 0.5`}}
                                                              // checked={totalamount.map((id)=>id.series_id) === series._id && 'checked'}
                                                              className="plan_id_selectedinp"
                                                              value={plan._id}
                                                              cartid={
                                                                course._id
                                                              }
                                                              type="radio"
                                                              disabled={course.is_purchased}
                                                              // disabled={purchasedPlan&&purchasedPlan.includes(plan._id)}
                                                              onChange={(e) => {
                                                                pricestore(
                                                                  e,
                                                                  plan?.plan_original_price,
                                                                  plan?.plan_discounted_price,
                                                                  plan?.plan_points_redeemable,
                                                                  series?._id,
                                                                  course?._id
                                                                );
                                                              }}
                                                              name={series?._id}
                                                            />

                                                            {/* <span className={`checkmark-six ${purchasedPlan.includes(plan._id) && "disabledRadio"} `}></span> */}
                                                            <span className={`checkmark-six ${course.is_purchased && "disabledRadio"} `}></span>
                                                            <strong className="mx-3 Total_discount_price">
                                                              {
                                                                plan?.plan_discounted_price
                                                              }{" "}
                                                              ₹
                                                            </strong>
                                                            <li className="discounted_price Total_original_price">
                                                              {" "}
                                                              {
                                                                plan?.plan_original_price
                                                              }{" "}
                                                              ₹{" "}
                                                            </li>
                                                            {/* <li className='mx-4 '>save point:{refpointresponseData.remaining_points} points : {plan.plan_points_redeemable} </li> */}
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </div>
                                            {/* } */}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>

                                  {/* <CoursePackage></CoursePackage>
                                      <CoursePackage></CoursePackage> */}
                                </div>
                              </div>
                            
                            ))}
                            
      
 

                            <div className="flex flex-col md:flex-row">
                              <div className="w-full pr-20 pl-10">
                                <div className="">
                                  <hr className="bg-[#7D7D7D] cont_width p-[.02rem]" />
                                  <div className="flex justify-between cont_width items-center py-1">
                                    <p className="font-bold text-xl flex items-center">
                                      Total <p className="m-0 font-thin text-sm">(GST Included)</p>
                                    </p>
                                    <p>
                                      <small className="line-through text-[#bdbdbd]">
                                        {totalOriginalPrice}
                                      </small>
                                      <span className="font-bold text-xl">
                                        <i className="fa fa-inr"></i>
                                        {totalDiscountedPrice}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="PaymentMethods_div">
            {/* <PaymentMethods /> */}
            <>
              <div className="container">
                <div className="row">
                  

                  

                  <div className="col-lg-12 col-md-12 col-sm-12">
                    {/* <PriceSummary /> */}

                    <>
                      <div className="  order_details_box_shadow price_summery_box" style={{background:mode==="dark"?"#151515":"white"}}>
                        
                        <div>
                          <h3 className="text-center pb-2 text-[#775EA5]">
                            Amount Breakdown
                          </h3>
                          <GrandTotal
                            title={"Discount "}
                            // detail={parseFloat(1 - totaldisPer) + '%'}
                            detail={
                              <CiDiscount1 className="inline-block text-2xl font-extrabold text-[#775EA5]" />
                            }
                            price={`-${
                              totalOriginalPrice - totalDiscountedPrice
                            }`}
                          ></GrandTotal>
                          <GrandTotal
                            title={"Amount "}
                            // detail={parseFloat(1 - totaldisPer) + '%'}
                            detail={
                              <IoPricetagsOutline className="inline-block text-2xl font-extrabold text-[#775EA5]" />
                            }
                            price={`+${totalDiscountedPrice}`}
                          ></GrandTotal>
                          {/* <GrandTotal
                            title={"GST (18%) "}
                            detail={"India ©"}
                            price={`+${gstadded}`}
                          ></GrandTotal> */}
                          <GrandTotal
                            title={"Referal Points "}
                            detail={"(1points == ₹1)"}
                            price={`-${
                              totalplanspoints === undefined
                                ? "0"
                                : totalplanspoints / 1
                            }`}
                          ></GrandTotal>
                          <div className="flex justify-between items-center my-5">
                            <h1 className="font-bold text-xl flex justify-center items-center">Total <p className="m-0 font-thin text-sm">(GST Included)</p></h1>
                            <h1 className="text-xl font-bold">
                              {/* <span className="line-through text-[#d1d0d0] text-[.8rem]">
                              {totalOriginalPrice}
                            </span> */}
                              <i className="fa fa-inr"></i>
                              {/* {isNaN(totalorgstadded)
                                ? 0
                                : totalorgstadded.toFixed(2)} */}
                              {isNaN(totalorgstadded)
                                ? 0
                                : totalorgstadded.toFixed(2)}
                            </h1>
                          </div>
                        </div>
                        <div className="flex justify-between items-center flex-col md:flex-row mb-5 mt-10">
                          <div className="basis-1/2">
                            <button
                              className="bg-[#66BE5E] text-white text-center  p-2 px-5 rounded btn_hover"
                              // onClick={SuccesshandleShow}
                              onClick={() => {
                                handlePayment(
                                  totalDiscountedPrice,
                                  tresponseData,
                                  isCheckboxChecked
                                );
                              }}
                            >
                              Submit Secure Payment
                            </button>
                          </div>
                          <div>
                            <p className="fomt-bold">
                              <i className="fa fa-lock text-[#66BE5E] font-normal"></i>{" "}
                              Encrypted And Secure Payments
                            </p>
                          </div>
                        </div>
                        <div className=" Terms_of_Service_para">
                        <p style={{color:mode==="dark"&&"white"}}>
                        *Upon clicking the "Submit Secure Payment" button, you hereby acknowledge and consent to our <a
                              href="/setting?page=termservice"
                              className="font-bold text-[#775EA5]"
                            >
                              Terms of Service
                            </a>. We shall proceed to process your personal data for the purpose of fulfilling your order and in accordance with our Privacy Policy. The option to terminate recurring payments is available to you at any juncture.

                            </p>
                        </div>
                      </div>

                      <Transition.Root show={Successopen} as={Fragment}>
                        <Dialog
                          as="div"
                          className="relative z-10"
                          initialFocus={cancelButtonRef}
                          onClose={setSuccessOpen}
                        >
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                          </Transition.Child>

                          <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                              >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4  ">
                                    <div className="success_edit_profile">
                                      <BsCheckCircle
                                        className="icons"
                                        style={{ fontSize: "50px" }}
                                      />
                                      <h4>
                                        Your Payment has Submitted Successfully!{" "}
                                      </h4>
                                    </div>

                                    <div className="Personal_Info_div ">
                                      <div>
                                        <div className="pt-5">
                                          <div className="text-center">
                                            <button
                                              className=" text-white bg-[#66BE5E] py-3 px-5 mt-7 rounded text-white font-semibold w-[90%] m-auto  btn_hover"
                                              onClick={SuccesshandleClose}
                                            >
                                              Go Back
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition.Root>
                    </>
                  </div>
                </div>
              </div>
            </>
          </div>
        </section>
      ): null }

      {
        emptyCart && 
        <section className="order_details_page_main_section">
          <div className="PaymentMethods_div">
            <div className="container">
              <div className="row">
                <div className="w-full flex justify-center ">
                  <img width={600} src="../notfound/cart.jpg"></img>
                </div>
                <div className="flex justify-center">
                  <Link className="text-white btn_hover" to="/">
                    <button className=" flex justify-center items-center text-white py-3 px-10 font-semibold rounded border bg-[#66BE5E] border-transparent text-center btn_hover">
                      <i className="arrow1">
                        <MdOutlineKeyboardBackspace />
                      </i>
                      Go Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      
     <Deletemodel open={open} setOpen={setOpen} deletapi={runLikeApi} />
      <Footer />
    </>
  );
}

export default Orders;
