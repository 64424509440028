import React from 'react'
import Logo from '../../assets/images/logo.png'
import white from '../../assets/images/logo-white.png'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Fragment, useState,useRef,useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { AiOutlineClose, AiOutlineMenu, AiOutlineUser } from 'react-icons/ai'
import { userContext } from '../../Context/UserContextProvider'
import ReactHtmlParser   from 'html-react-parser';





function LoginNavbar() {
 const { profiledata,setProfiledata}=React.useContext(userContext)
 console.log(profiledata)

  return (
    <>
     <ToastContainer />


      <header className="bg-white main_login_header" >
        <nav
       
          className="mx-auto flex max-w-8xl items-center justify-between p-1 lg:px-0 bg-[#f5f5f5]"
          aria-label="Global"
        >
          <div className="flex lg:flex-2">
            <a href="#" className="-m-1.5 p-1.5">

              <Link to="/" >
                <img className="logo" src={Logo} alt="" />
              </Link>
              
            </a>
          </div>


          <div className='me-4'>
          <img
                     src={profiledata?.profile_image === '' 
                    || profiledata?.profile_image === undefined
                  || profiledata?.profile_image === null ? './plceholdernav/placeholder-img.png'
                  : profiledata?.profile_image }
                    alt="profile"
                    className=" user_img w-[60px] h-[60px] mx-auto object-cover rounded-full"
                  />
          </div>
         
          
             
     

        </nav>
      
      </header>
    </>
  )
}

export default LoginNavbar
