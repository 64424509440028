import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { HiArrowNarrowRight } from "react-icons/hi";
import { BsInfo } from "react-icons/bs";
import { MdFullscreen } from "react-icons/md";
import Testnavbar from '../components/LoginNavbar/Testnavbar'
import ScholarshipReport from "./ScholarshipCertificate";
import {  useNavigate } from "react-router";


function ScholarshipTestpage() {
// const ToggleMenu = () => {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
    
    const [isOpenMenu, setIsOpenMenu] = useState(false);
  
    const toggleMenuTwo = () => {
        setIsOpenMenu(!isOpenMenu);
    };
    function submitfsn(){
        navigate('/scholarshipcertificate')
        
    }
    


    return (
        <>
            <Testnavbar />
            <section className="test_main_section">
                <div class="wrapper min-h-[89.5vh]" >
                    <div className="w-[90%] start_text_section_left_part m-auto flex justify-between flex-col lg:flex-row md:flex-column">
                        <div className="lg:w-full">
                            <div className="my-3 p-10 bg-[#b1b1b122] rounded-lg">
                                {/* <TestDetails  img={1}></TestDetails> */}
                                <div className="flex justify-between items-center test_details_main lg:flex-row md:flex-column test_name_timer_div">
                                    <div className="flex justify-between items-center test_details_main lg:flex-row md:flex-column test_name_timer_div">
                                        <div className="flex items-center lg:flex-row md:flex-column">
                                            <div className="">
                                                <h1 className="font-bold text-2xl lg:text-3xl mr-5 series_font"
                                                // style={{color:mode==="dark"&&"white"}}
                                                >
                                                    Scholarship Test
                                                </h1>
                                            </div>
                                            {/* {responseData[quizNo]?.question_title_hindi != '' && <div onClick={changelang}> */}
                                            <img
                                                src=''
                                                alt=""
                                                width={35}
                                            />
                                        </div>
                                        {/* } */}
                                        <div className="mx-3">
                                            {/* <Dropdown className="View_Instructions_btn"> */}
                                            {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                            <div className="flex justify-between items-center cursor-pointer"  onClick={toggleMenu}>
                                                <span className=" rounded-full p-0.5 mr-2">
                                                    <BsInfo
                                                        className="text-white "
                                                        style={{ fontSize: "20px", background: '#775DAB', borderRadius: '50%' }}
                                                    />
                                                </span>
                                                <span className="text-[#775DA8]">
                                                    View Instructions?
                                                </span>
                                            </div>
                                            
                                            {isOpen && (
                                            <div className="instructuins_main dropdown-menu show" style={{position: "absolute", transform: "translate(0px, 38px)", padding: '20px', width: '40%', top: '100px' }}>
                                                <div className="instructuins series_font">
                                                    <h3>Instructions</h3>
                                                    <p>
                                                        <span>
                                                            <HiArrowNarrowRight />
                                                        </span>
                                                        10 point awarded for a correct answer and no
                                                        marks for a incorrect answer
                                                    </p>
                                                    <p>
                                                        <span>
                                                            <HiArrowNarrowRight />
                                                        </span>
                                                        Tap on options to select the correct answer
                                                    </p>
                                                    <p>
                                                        <span>
                                                            <HiArrowNarrowRight />
                                                        </span>
                                                        Tap on the bookmark icon to save interesting
                                                        questions.
                                                    </p>
                                                    <p>
                                                        <span>
                                                            <HiArrowNarrowRight />
                                                        </span>
                                                        Click submit if you are sure you want to
                                                        complete all the quizzes
                                                    </p>
                                                    <p>
                                                        <span>
                                                            <HiArrowNarrowRight />
                                                        </span>
                                                        Tap on the bookmark icon to save interesting
                                                        questions.
                                                    </p>
                                                </div>
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="time_wid">
                                        {/* { isFullScreen && */}
                                        <div className="mr-4 flex justify-center items-center">
                                            <abbr
                                                title="F11"
                                                //   onClick={() => toggleFullScreen()}
                                                className=" mr-5 !cursor-pointer text-[grey] text-3xl me-5  hover:text-[#775DA8]"
                                            >
                                                <MdFullscreen />
                                            </abbr>
                                        </div>
                                        {/* } */}

                                        <div>
                                            <span className="test_timer series_font"
                                            //  style={{color:mode==="dark"&&"white"}}
                                            >
                                                <i className="fa fa-clock-o text-[red]"></i>{" "}
                                                54 left
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <section className="subject_card"

                                >
                                    <div className="bg-white px-2 py-2 flex items-center my-5 rounded ">
                                        <div class="border p-2 mx-2 bg-[#775DA8] text-[white] rounded">
                                            <div class="View_Instructions_btn subject_name_dropdown dropdown">
                                                <button type="button" class="dropdown-toggle btn btn-primary" style={{ fontSize: '18px' }} onClick={toggleMenuTwo}>
                                                    <h1 style={{ fontSize: '18px' }}>test section </h1>

                                                </button>
                                                {isOpenMenu && (
                                                <div className="dropdown-menu show" style={{ position: "absolute", transform: "translate(0px, 33px)", background: '#fff', color: "#000", padding: "20px", border: "1px solid #00000024"}}>
                                                    <div class="subject_drop_main">
                                                        <p>
                                                            <div class="Answered_mark_reviw subject_num_box">0</div>
                                                            Answered And Marked For Review
                                                        </p>
                                                    </div>
                                                    <div class="subject_drop_main">
                                                        <p>
                                                            <div class="mark_reviw subject_num_box">0</div>
                                                            Marked For Review
                                                        </p>
                                                    </div>
                                                    <div class="subject_drop_main">
                                                        <p>
                                                            <div class="answer subject_num_box">0</div>
                                                            Answered
                                                        </p>
                                                    </div>
                                                    <div class="subject_drop_main">
                                                        <p>
                                                            <div class="not_answer subject_num_box">1</div>
                                                            Not Answered
                                                        </p>
                                                    </div>
                                                    <div class="subject_drop_main">
                                                        <p>
                                                            <div class="not_visit subject_num_box ">0</div>
                                                            Not Visited
                                                        </p>
                                                    </div>
                                                    <div class="subject_drop_main">
                                                        <p>
                                                            <div class="marked subject_num_box">1</div>
                                                            Marked &amp; Answered Question will be considered for evaluation
                                                        </p>
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                {/* <QuestionCard detail={responseData ? responseData[quizNo] : ''} quiz_no={quizNo} additionalData={showAdditionalData ? "swapLanguage" : null} ></QuestionCard> */}

                                <>
                                    {/* {responseData ? ( */}
                                    <div className="question_card_main select-none">
                                        <div className="py-2">
                                            <p className="md:text-lg xl:text-2xl question series_font">
                                                <span className="font-bold text-[#775EA5] series_font"
                                                //  style={{color:mode==="dark"&&"white"}}
                                                >
                                                    Q1.
                                                </span>

                                                <span className="series_font select-none"
                                                // style={{color:mode==="dark"&&"white"}}
                                                >
                                                    What I find remarkable
                                                </span>
                                            </p>
                                        </div>
                                        <>
                                            <div className="p-2">
                                                <div class="question_card_main select-none">

                                                    <div class="p-2">
                                                        <div class="options options_main">
                                                            <div class="flex items-center">
                                                                <div class="w-7 h-7 rounded-full border border-[#775EA5]">
                                                                    <p class="text-center">A</p>
                                                                </div>
                                                                <div class="mx-2 text-[#5b5a5a]">
                                                                    <label class="series_font radio-container">
                                                                        <input class="radio_inp_put_status" type="radio" />
                                                                        <span class="option_content series_font checkmark" style={{ marginLeft: '15px' }}>
                                                                            test
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-2">
                                                        <div class="options options_main">
                                                            <div class="flex items-center">
                                                                <div class="w-7 h-7 rounded-full border border-[#775EA5]">
                                                                    <p class="text-center">B</p>
                                                                </div>
                                                                <div class="mx-2 text-[#5b5a5a]">
                                                                    <label class="series_font radio-container">
                                                                        <input class="radio_inp_put_status" type="radio" />
                                                                        <span class="option_content series_font checkmark" style={{ marginLeft: '15px' }}>Test</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-2">
                                                        <div class="options options_main">
                                                            <div class="flex items-center">
                                                                <div class="w-7 h-7 rounded-full border border-[#775EA5]">
                                                                    <p class="text-center">C</p>
                                                                </div>
                                                                <div class="mx-2 text-[#5b5a5a]">
                                                                    <label class="series_font radio-container">
                                                                        <input class="radio_inp_put_status" type="radio" />
                                                                        <span class="option_content series_font checkmark" style={{ marginLeft: '15px' }}>Test</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-2">
                                                        <div class="options options_main">
                                                            <div class="flex items-center">
                                                                <div class="w-7 h-7 rounded-full border border-[#775EA5]">
                                                                    <p class="text-center">D</p>
                                                                </div>
                                                                <div class="mx-2 text-[#5b5a5a]">
                                                                    <label class="series_font radio-container">
                                                                        <input class="radio_inp_put_status" type="radio" />
                                                                        <span class="option_content series_font checkmark" style={{ marginLeft: '15px' }}>Test</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <>


                                                <></>
                                            </>
                                            {/* )} */}
                                        </>
                                    </div>
                                    {/* ) : ( */}
                                    {/* <button className="submit_button">
                                        Submit Your Course
                                    </button> */}
                                    {/* )} */}
                                </>
                            </div>
                            {/* <Answer></Answer> */}
                            <div>
                                <div className="flex justify-between items-center flex-col lg:flex-row md:flex-column">
                                    <div className="flex md:basis-1/2 w-full flex-col lg:flex-row md:flex-column">
                                        <div className="  ">
                                            <button
                                                className="my-2 md:my-1 w-full border border-[orange] text-center text-[orange] rounded p-3 md:mx-2 series_font hover_border_yellow"
                                            >
                                                Mark For Review & Next
                                            </button>
                                        </div>
                                        <div className="mx-2">
                                            <button
                                                className="my-2 md:my-1 w-full border border-[grey] text-[grey]  text-center rounded p-3 md:mx-2 series_font hover_border_dark"
                                            >
                                                Clear Response
                                            </button>
                                        </div>
                                    </div>

                                    <div className="my-2 md:my-1 Save_Next_btn">
                                        <button
                                            className="my-2 md:my-1 w-full bg-[#66BE5E] p-3 px-5 text-center text-[white] basis-1/4 rounded series_font btn_hover"
                                            onClick={submitfsn}
                                        >
                                            Submit and Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="sidebar">
                    <div className=" px-0 py-3 ml-4">
                        <section className="testtrack_main">
                            <div className="testtrack_main_inner rounded-xl">
                                <div className="flex items-center">
                                    <div>
                                    </div>
                                    <div className="px-3">
                                        <p className="name series_font">
                                            Alpha  edtech
                                        </p>
                                        <p className="email series_font">
                                            {" "}
                                            <small className="series_font">
                                                ialphaedtech@gmail.com
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="py-1">
                                    <p className=" General_Instructions series_font">
                                        General Instructions :
                                    </p>
                                    <div>
                                        <div className="flex items-center">
                                            <div className="bg-[#000] w-7 h-7 text-center justify-center items-center inline-flex rounded-full text-white mr-1 series_font">
                                                1
                                            </div>
                                            <div className="question_report series_font">
                                                Answered And Marked For Review
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-between my-2">
                                        <div>
                                            <div className="flex items-center">
                                                <div className="bg-[orange] w-7 h-7 text-center justify-center items-center inline-flex rounded-full text-white mr-1 series_font">
                                                    2
                                                </div>
                                                <div className="question_report series_font">
                                                    Marked For Review
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-center">
                                            <div className="bg-[#7ee57e] w-7 h-7 justify-center items-center inline-flex text-center rounded-full text-white mr-1 series_font">
                                                3
                                            </div>
                                            <div className="question_report series_font">
                                                Answered
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-between my-2">
                                        <div>
                                            <div className="flex items-center">
                                                <div className="bg-[red] w-7 h-7 text-center rounded-full justify-center items-center inline-flex text-white mr-1 series_font">
                                                    4
                                                </div>
                                                <div className="question_report series_font">
                                                    Not Answered
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center">
                                            <div className="bg-[transparent] justify-center items-center inline-flex w-7 h-7 text-center border rounded-full text-black mr-1 series_font">
                                                5
                                            </div>
                                            <div className="question_report series_font">
                                                Not Visited
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="bg-[#f5f5f5]  rounded-xl my-2 TrackQuestionNumbers_main">
                                        <div>
                                            <p className="series_font">Question Numbers:</p>
                                        </div>
                                        <div className="grid grid-cols-4 gap-2  TrackQuestionNumbers">
                                            <div class="d-flex items-center justify-center w-11 h-11 border-2 brorder-[white] rounded-full text-center font-semibold text-[white] text-xl  bg-[#775EA5] quest_hover">
                                                <h2 class="quiz_no_btn series_font text-[white]">1</h2>
                                            </div>
                                            <div class="d-flex items-center justify-center w-11 h-11 align-middle border-2 brorder-[#775EA5] rounded-full text-center font-semibold text-[white] text-xl quest_hover ">
                                                <h2 class="quiz_no_btn series_font text-[#7d7d7d]">2</h2>
                                            </div>
                                            <div class="d-flex items-center justify-center w-11 h-11 align-middle border-2 brorder-[#775EA5] rounded-full text-center font-semibold text-[white] text-xl quest_hover ">
                                                <h2 class="quiz_no_btn series_font text-[#7d7d7d]">3</h2>
                                            </div>
                                            <div class="d-flex items-center justify-center w-11 h-11 align-middle border-2 brorder-[#775EA5] rounded-full text-center font-semibold text-[white] text-xl quest_hover ">
                                                <h2 class="quiz_no_btn series_font text-[#7d7d7d]">4</h2>
                                            </div>
                                            <div class="d-flex items-center justify-center w-11 h-11 align-middle border-2 brorder-[#775EA5] rounded-full text-center font-semibold text-[white] text-xl quest_hover ">
                                                <h2 class="quiz_no_btn series_font text-[#7d7d7d]">5</h2>
                                            </div>
                                            <div class="d-flex items-center justify-center w-11 h-11 align-middle border-2 brorder-[#775EA5] rounded-full text-center font-semibold text-[white] text-xl quest_hover ">
                                                <h2 class="quiz_no_btn series_font text-[#7d7d7d]">6</h2>
                                            </div>
                                            <div class="d-flex items-center justify-center w-11 h-11 align-middle border-2 brorder-[#775EA5] rounded-full text-center font-semibold text-[white] text-xl quest_hover ">
                                                <h2 class="quiz_no_btn series_font text-[#7d7d7d]">7</h2>
                                            </div>
                                            <div class="d-flex items-center justify-center w-11 h-11 align-middle border-2 brorder-[#775EA5] rounded-full text-center font-semibold text-[white] text-xl quest_hover ">
                                                <h2 class="quiz_no_btn series_font text-[#7d7d7d]">8</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" m-auto text-center">
                                    <button
                                        className="text-white bg-[#775EA5] w-1/2 m-auto text-center p-2 rounded my-2"
                                    //  onClick={SuccesshandleShow} >Submit</button>

                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* </div> */}
            </section>
        </>
    )
}
// }

export default ScholarshipTestpage;