import React from 'react'
import conatct_us from '../../assets/images/contact_us.svg'
import Lottie from 'react-lottie'

import invite from '../../assets/images/ContactUs'
function ContactUsImg() {
  const slider1 = {
    loop: true,
    autoplay: true,
    animationData: invite,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <section className="home_page_contact ">
      <div className="p-10">
        <div className="py-10">
          <h1 className="text-2xl font-bold ">Let's Connect Together</h1>
          <p className=" py-2">
            Reach out to us and embark on a transformative educational journey
            with Navya®.
          </p>
        </div>
        <div className="contact_us_gif">
          <Lottie options={slider1} isClickToPauseDisabled={true} height="100%" width="100%" />
        </div>
      </div>
    </section>
  )
}

export default ContactUsImg
