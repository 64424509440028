import React from 'react'
import { Link } from 'react-router-dom'
import instructor1 from '../../assets/images/highlights/1.png'
import instructor2 from '../../assets/images/highlights/2.png'
import instructor3 from '../../assets/images/highlights/3.png'
import instructor4 from '../../assets/images/highlights/4.png'
import instructor5 from '../../assets/images/highlights/5.png'
import instructor6 from '../../assets/images/highlights/6.png'
import arrow_light from '../../assets/images/arrows.png'
import instructor_num from '../../assets/images/label-text.png'
import round_circle_bg from '../../assets/images/round-circle-bg.png'


const BestInstructur = ({mode}) => {
  const BestIinstructor =[
    {
      imgurl:'./teachers/1. Mrs. Ekta Arora.ef4bd608f1e5090f02fb.png',
      Name:'Mrs. Ekta Arora',
    },
    {
      imgurl:'./teachers/2. Mr. Kaushik Kr Solanki.c15a912cf5989aa02aa0.png',
      Name:'Mr. Kaushik Kr Solanki',
    },
    {
      imgurl:'./teachers/3. Mr. D Gangadhara .44b29ea9eb7f35d1cd3d.png',
      Name:'Mr. D Gangadhara',
    },
    {
      imgurl:'./teachers/4. Dr. Rahul Sharma.9a6c5d988e9539284656.png',
      Name:'Dr. Rahul Sharma',
    },
    {
      imgurl:'./teachers/5. Dr. M Rajshekhar Swami.b2daa65355dbef762aa5.png',
      Name:'Dr. M Rajshekhar Swami',
    },
    {
      imgurl:'./teachers/6. Mr. M R Kumawat.5e06a2b45b65c1bd5da6.png',
      Name:'Mr. M R Kumawat',
    },
  ]
  return (
    <>
      <section className="best_Instructors_main_section pos_relative">
      <div className='instructor_boxes_bg_light'></div>
      <div>
      <img src={arrow_light} alt="" className="img-fluid arrow_light" />
      {/* <img src={instructor_num} alt="" className="img-fluid instructor_num" /> */}
      <img src={round_circle_bg} alt="" className="img-fluid round_circle_bg_instructor" />


      </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="best_Instructors_heading_side">
                <h1 style={{color:mode==="dark"&&"white"}}>
                  <span className=''> Best </span> Instructors
                </h1>
                <p style={{color:mode==="dark"&&"white"}}>
                  At the Academy, we strive to bring together the best
                  professors for the best courses
                </p>
                <Link to="/signin" className="index_page_button mt-3">
                  Get Started
                </Link>
              </div>
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12">
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 ">
           
              <div className="row ">

                {
                  BestIinstructor.map((instructor,index)=>{
                    return(
                      <div className="col-lg-4 col-md-4 col-sm-12 col-6">
                  <div className="best_instructor_small_boxes" style={{background:mode==="dark"&&"#151515"}}>
                    <img src={instructor.imgurl} alt="" className="image-fluid " />
                    <div className="instructor_name_box">
                      <h2 style={{color:mode==="dark"&&"white"}}>{instructor.Name}</h2>
                      {/* <h3>GMAT</h3> */}
                    </div>
                  </div>
                </div>
                    )
                  })
                }
                {/* <div className="col-lg-4 col-md-4 col-sm-12 col-6">
                  <div className="best_instructor_small_boxes">
                    <img src={instructor1} alt="" className="img-fluid" />
                    <div className="instructor_name_box">
                      <h2>Jon Kantner</h2>
                      <h3>GMAT</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-6">
                  <div className="best_instructor_small_boxes">
                    <img src={instructor2} alt="" className="img-fluid" />
                    <div className="instructor_name_box">
                      <h2>Debbie LaChusa</h2>
                      <h3>GMAT</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-6">
                  <div className="best_instructor_small_boxes">
                    <img src={instructor3} alt="" className="img-fluid" />
                    <div className="instructor_name_box">
                      <h2>Edwin Diaz</h2>
                      <h3>GMAT</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-6">
                  <div className="best_instructor_small_boxes">
                    <img src={instructor4} alt="" className="img-fluid" />
                    <div className="instructor_name_box">
                      <h2>Cassie Evans</h2>
                      <h3>GMAT</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-6">
                  <div className="best_instructor_small_boxes">
                    <img src={instructor5} alt="" className="img-fluid" />
                    <div className="instructor_name_box">
                      <h2>Erich Andreas</h2>
                      <h3>GMAT</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-6">
                  <div className="best_instructor_small_boxes">
                    <img src={instructor6} alt="" className="img-fluid" />
                    <div className="instructor_name_box">
                      <h2>Jason Allen</h2>
                      <h3>GMAT</h3>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BestInstructur
