import React from 'react'
import { Link } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'

function MyCourseCard(props) {
  const [spinner, setSpinner] = React.useState(false)
  let [responseData, setResponseData] = React.useState('')
  let [errorData, setErrorData] = React.useState('')
  const [courseId, setCourseId] = React.useState(
    localStorage.getItem('courseId'),
  )

  // const usertoken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDcxODEwYWJhMzc0OGZmZDc4ZjAwN2IiLCJyb2xlIjoidXNlciIsImlhdCI6MTY4NTI1MTUzOSwiZXhwIjoxNjg3ODQzNTM5fQ.5V86CNEz3a_OEzpCMaAF4b5bW-vhigMqUi7a_LIugBQ';

  const usertoken = localStorage.getItem('user_token')

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)

      try {
        const instance = axios.create({
          baseURL: 'https://backend.navyaedu.com/',
          headers: {
            Authorization: 'Bearer ' + usertoken,
          },
        })

        const response = await instance.get(
          '/api/v1/courses/series/' + localStorage.getItem('courseId'),
        )
        console.log(response.data.series)
        setResponseData(response.data.series)
        setSpinner(false)
      } catch (error) {
        setSpinner(false)
        setErrorData(error)
      }
    }

    fetchData()
  }, [usertoken, courseId])

  const handleCourse = (seriesId) => {
    localStorage.setItem('seriesId', seriesId)
  }

  return (
    <>
      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}

      <div className="mycourse_card_main">
        <div className="p-12">
          {/* <div className="flex items-center">
            <Link to="/myorder" className="d-flex">
              <p className="bg-[#775DA8] rounded-full w-5 h-5 relative text-center mt-0.5">
                <i className="fa fa-angle-left absolute top-[2px] left-0 right-0.5 bottom-0 m-auto text-white"></i>
              </p>
              <p className="text-black font-semibold mx-2"> Back</p>
            </Link>
          </div> */}

          {Array.isArray(responseData) ? (
            responseData.map((series) => (
              <div className="mb-3 p-10 bg-[#b1b1b122] rounded-lg">
                <div className="flex justify-between items-start  flex-col lg:flex-row md:flex-column">
                  <div>
                    <div className="">
                      <div className="flex">
                        <h1 className="font-bold text-2xl mr-5 series_font">
                          {series.series_name}
                        </h1>
                        <img
                          src={require(`../../assets/images/MycourseTag/${props.img}.png`)}
                          alt=""
                          style={{ width: '35px', height: '35px' }}
                        />
                      </div>
                      <div className="flex ">
                        <p className="series_font">GET 100 Points</p>
                        <p className="px-2 series_font">
                          4.8{' '}
                          <span>
                            <i className="fa fa-star text-[orange]"></i> (878
                            ratings)
                          </span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="font-semibold py-0 series_font">
                        Please read the text below carefully so you can
                        understand it
                      </p>
                    </div>
                    <div className="series_font">
                      <div className="py-1 font-normal text-[#7D7D7D]">
                        <p className="d-flex my-0 items-center ">
                          <span className="px-2">
                            {' '}
                            <BsArrowRight className="font-light text-[grey]" />{' '}
                          </span>
                          <span>
                            10 point awarded for a correct answer and no marks
                            for a incorrect answer
                          </span>
                        </p>
                      </div>
                      <div className="py-1 font-normal text-[#7D7D7D]">
                        <p className="d-flex my-0 items-center">
                          <span className="px-2">
                            {' '}
                            <BsArrowRight className="font-light text-[grey]" />{' '}
                          </span>
                          <span>
                            Tap on options to select the correct answer
                          </span>
                        </p>
                      </div>
                      <div className="py-1 font-normal text-[#7D7D7D]">
                        <p className="d-flex my-0 items-center">
                          <span className="px-2">
                            {' '}
                            <BsArrowRight className="font-light text-[grey]" />{' '}
                          </span>
                          <span>
                            Tap on the bookmark icon to save interesting
                            questions.
                          </span>
                        </p>
                      </div>
                      <div className="py-1 font-normal text-[#7D7D7D]">
                        <p className="d-flex my-0 items-center">
                          <span className="px-2">
                            {' '}
                            <BsArrowRight className="font-light text-[grey]" />{' '}
                          </span>
                          <span>
                            Click submit if you are sure you want to complete
                            all the Teats
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <h1 className="text-2xl font-bold  series_font">
                        What You Will Face here :
                      </h1>
                    </div>
                    <div className="flex items-cente">
                      <div>
                        <i className="fa fa-file-text-o fa-2x text-[#775DA8]"></i>
                      </div>
                      <div className="px-3">
                        <p className="font-bold mb-0 series_font">{series.course_series_quizzes.length} Tests</p>
                        <small className="series_font">
                          {series.course_series_plans[0]?.plan_points_redeemable} point for a correct answer
                        </small>
                      </div>
                    </div>
                    <div className="flex items-center my-2">
                      <div>
                        <i className="fa fa-clock-o fa-2x text-[#775DA8]"></i>
                      </div>
                      <div className="px-3">
                        <p className="font-bold mb-0 series_font">
                          {' '}
                          {series.series_duration} min
                        </p>
                        <small className="series_font">
                          Total duration of the Test
                        </small>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div>
                        <i className="fa fa-star-o text-[#775DA8] fa-2x"></i>
                      </div>
                      <div className="px-3">
                        <p className="font-bold mb-0 series_font">Win 5 star</p>
                        <small className="series_font">
                          Answer all questions correctly
                        </small>
                      </div>
                    </div>
                    <Link
                      to="/startcourse"
                      onClick={() => handleCourse(series._id)}
                    >
                      <div className="bg-[#66BE5E] text-center text-white font-semibold rounded p-2 my-3 btn_hover_white">
                        <button className="series_font ">Start Test</button>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
          <div className='Nodata  h-[89.5vh]'>
          <p className='text-transform '>No Series available.</p>
          </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MyCourseCard
