import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import NavyaImg from '../../assets/images/loginPageSigeImg.png'
import {useNavigate} from "react-router-dom"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Audio } from 'react-loader-spinner'
import NoSigningfooter from './NoSigningfooter'
import UseAxios from '../../util/UseAxios'
import { Password } from '@mui/icons-material'



function ChangePassword() {
  const instance = UseAxios()
  let newObject = window.localStorage.getItem("otp_data");
  const data = JSON.parse(newObject);
    


  const[phone, setPhone] = useState("");
  let [pass2, setpass2] = useState('')
  let [errorData, setErrorData] = useState('')
  const [spinner, setSpinner] = React.useState(false);

  const body_json = {
    email:data.email,
    otp: data.otp,
    new_password: phone
  }

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault()

    setSpinner(true);
    if(pass2 === phone){


    instance
      .patch('/api/v1/auth/forget-password/change-password', body_json)
      .then((response) => {
        console.log(response)
        toast.success(response.data.msg);
        setTimeout(() => {
          navigate('/signIn'); // Navigate to other page after 2 seconds
        }, 2000);
       
      })
      .catch((error) => {
        setSpinner(false);
        console.log(error)
        if (error) {
          toast.error(error.response.data.msg)
          setErrorData(error.response)
        }
      })
    }else{
      toast.error("Password Not match")
      setSpinner(false);

    }
  }




    return (
      <>
      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}
        <div className='flex login_signup_main_section'>
      <ToastContainer />

            <div className='basis-1/2 md:block hidden'>
                <img src={NavyaImg} alt="" className='img-fluid login_pgae_img' />
            </div>
            <div className='mx-auto p-5 flex justify-between flex-col'>
                <div>
                    <div>
                        <Link to="/signIn" className='font-medium'><i className='fa fa-arrow-left font-normal'></i><span className='px- text-lg'> Back</span></Link>
                    </div>
                    <div className='font-bold text-2xl pt-8'>
                        <h1 >Forgot Password </h1>
                    </div>
                    <div className='pt-2'>
                        <p>Enter Your New Password.</p>
                    </div>
                    <div className='pt-10 w-full'>
                        <div className='pb-2'>
                            <p>New Password: <span></span></p>
                        </div>
                        <form action="" className='' onSubmit={handleSubmit}>
                            <div className='border-[#aeaeae] border rounded p-2'>
                                <input required type="text" name="phone" id="" onChange={(e) => setPhone(e.target.value)}  className='w-full border-none focus:border-none focus:outline-none' placeholder='Enter Your Password' />
                            </div>
                            <div className='pb-2 mt-2'>
                            <p>New Confirm Password: <span></span></p>
                        </div>
                            <div className='border-[#aeaeae] border rounded p-2 '>
                          
                                <input required type="text" name="phone" id="" onChange={(e) => setpass2(e.target.value)}  className='w-full border-none focus:border-none focus:outline-none' placeholder='Enter Your Confirm Password' />
                            </div>
                            <div className='my-7'>
                                <div className='bg-[#66BE5E] rounded py-3' >
                                    <input type="submit" name="" id="" className='w-full border-none focus:border-none focus:outline-none text-white' value="Submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
               <NoSigningfooter/>
            </div>
        </div>
        </>
    )
}

export default ChangePassword