import React, {Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BsCheckCircle } from 'react-icons/bs'


function CloseModel({Successopen,setSuccessOpen,profiledataget}) {
    // const [Successopen, setSuccessOpen] = useState(false)

    const cancelButtonRef = useRef(null)

    function closemodel(){
        profiledataget()
        setSuccessOpen(false)
    }
  return (
    <div>
     <Transition.Root show={Successopen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setSuccessOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4  ">
                    <div className="success_edit_profile">
                      <BsCheckCircle className="icons" />
                      <h4>Your Changes Have Been saved Successfully! </h4>
                    </div>

                    <div className="Personal_Info_div ">
                      <div>
                        <form className="pt-5">
                          <div className="text-center">
                            <button
                              className="bg-[#66BE5E] py-3 mt-7 rounded text-white font-semibold w-[90%] m-auto"
                              onClick={closemodel}
                            >
                              Close
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

export default CloseModel