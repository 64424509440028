import * as React from 'react';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { Link, useParams } from 'react-router-dom';
import { RiCloseCircleLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Audio } from 'react-loader-spinner'
import { userContext } from '../../Context/UserContextProvider';
import UseAxios from '../../util/UseAxios'
 function RatingSize(props) {
  const {capVal,access_token ,setAccess_token ,mode}=React.useContext(userContext)
console.log(props);
  const{courseId,closepopUp}= props

  const [spinner, setSpinner] = React.useState(false)
  const usertoken = localStorage.getItem('user_token')

  const [feedback_value,setRate]=React.useState(2)
const [feedback_description,setDescription]=React.useState()
const Raise_feedback_data =
{
  rating_value:feedback_value,
  rating_description:feedback_description,
}
  const [ratresponse,setRaTresponse] = React.useState('')
  

  const instance =UseAxios()

  function TicketRase(event){
    setSpinner(true);
    event.preventDefault();
    instance
        .post(`api/v1/courses/rating/${courseId._id}`,Raise_feedback_data)
        .then((response) => {
          toast.success("Course Ratings Updated Successfully")
          console.log(response)
          setSpinner(false);
          props.popup(false)
        
        })
        .catch((error) => {
          toast.error(error.response.data.msg)
          setSpinner(false);
          props.popup(false)
        })
       
  }
  
  // const TicketRase = async (event) => {
  //   event.preventDefault();
  //   let baseURL = 'https://backend.navyaedu.com/'
  //   // setSpinner(true);
  //   try{
  //     const config = {
  //       method: 'POST',
  //       headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json',
  //           Authorization: 'Bearer ' + usertoken,
            
  //       },
  //       body: JSON.stringify(Raise_feedback_data)
  //   }

  //   const response = await fetch(`${baseURL}api/v1/courses/rating/${courseId}`, config);
   
  //   // console.log(response);
    
  //   if (response.ok) {
      
  //     const data = await response.json()
  //     setRate("");
  //     setDescription("");
  //     closepopUp()
  //     toast.success("feedback added ", {
  //       position: 'top-right',
  //     })
      
     
  //     // setRaTresponse(data.msg)
  //     // toast.success('Message Successfully Sent!');
  //     // setOpenRaiseTicket(true)
  //     // setTicketOpen(false);
     
      
      
  // } else {

  //     // toast.error(`ERROR : ${response.status}  ${response.statusText}`)
  // }
  //   }catch(error){
  //     // toast.error(error)
  //     console.log(error)
  //   }

  //   setTimeout(() => {
  //     // setSpinner(false);
      
  //   }, 2000);
  // };

  return (
    // <Stack spacing={1}>
    //   <Rating name="size-large" defaultValue={2} size="large" />
    // </Stack>
    
    <div className="fixed inset-0 z-10 overflow-y-auto ">
       
    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
    <div 
    style={{background:mode=="dark"?"#151515":"white"}}
     className="relative transform overflow-hidden rounded-lg  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
    <div className=" px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ">
    
      <div className='absolute top-4 right-4 cursor-pointer font-extrabold text-2xl text-[#865dd3]' onClick={()=>props.popup(false)}>
        <RiCloseCircleLine  style={{color:mode=="dark"&&"white"}} />
      </div>
    <div className="success_edit_profile">
      <h4 style={{color:mode=="dark"&&"white"}}>Rate Our Course </h4>
      {spinner && (
        <Audio
       
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader_feedback"
        />
      )}
      <form className="w-full" onSubmit={TicketRase}>
        <div className="rating_star">
        <Rating 
        // style={{color:mode=="dark"&&"white"}}
        name="size-large" value={feedback_value} onChange={(e)=>setRate(e.target.value)} size="large" />
        </div>
        <div className="mx-0 md:mx-0 my-2 w-full">
                <textarea
                style={{color:mode=="dark"&&"white", background:mode==="dark"&&"transparent"}}
                required
                value={feedback_description}
                onChange={(e) => setDescription(e.target.value)}
                className="rounded p-3 border border-[#cabde2] w-full"
                placeholder="Enter Your suggestion so that we can improve"
                rows={2}
               
                ></textarea>
        </div>
        <div className="Personal_Info_div ">
      <div>
        <div className="pt-5">
          <div className="text-center">
            <button type='submit'
               
              className="text-white bg-[#66BE5E] py-3 px-5 mt-7 rounded text-white font-semibold w-[90%] m-auto">
                Send Feedback
             
            </button>
          </div>
        </div>
      </div>
    </div>
      </form>
    </div>

    
  </div>
  </div>
  </div>
  </div>
  );
}



export default RatingSize;