import React, { useState } from 'react';
import {
  BarChart, Bar, Rectangle, XAxis,Brush, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

function Weakchart(report) {
  console.log(report?.report_data)
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={500}
        height={300}
        data={report?.report_data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="topic_name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="topicScore" fill="#5B9BD5" activeBar={<Rectangle  />} />
        <Bar dataKey="correctAnswers" fill="#ED7D31" activeBar={<Rectangle  />} />
        <Bar dataKey="incorrectAnswers" fill="#A5A5A5" activeBar={<Rectangle  />} />
        {/* <Bar dataKey="unattemptedCount" fill="#FFC000" activeBar={<Rectangle  />} /> */}
       
        <Brush dataKey="name" height={20} startInde={0} endIndex={report?.report_data?.length >= 20 ? 8 : report?.report_data?.length-1} stroke="#8884d8" />

      </BarChart>
    </ResponsiveContainer>
  )
}

export default Weakchart;