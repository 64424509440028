import React from 'react'
import Lottie from 'react-lottie'
import failuregif from '../../assets/images/payments/Animation - 1699690285924.json'
function Failure() {
    const failure = {
        loop: true,
        autoplay: true,
        animationData: failuregif,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }
  return (
    <div className='flex w-[100vw] justify-center h-[100vh] items-center bg-red-200'>


    <div className='paymentsucces'>
    <div >
        <h3>Falied</h3>
        <p>Your Payment has been Failed</p>
        {/* <img className='m-auto' src='../payment/paymentfals.png' alt="" /> */}
        <Lottie options={failure} isClickToPauseDisabled={true} height="100%" width="100%" />
        <div class="my-12 ">
            <a href="/">
                <button class="bg-[#f00] w-4/5  py-3  rounded text-white btn_hover">Done</button>
            </a>
        </div>
    </div>
</div>
</div>
  )
}

export default Failure