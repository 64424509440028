import React from 'react'
import features1 from '../../assets/images/features1.png'
import features2 from '../../assets/images/features2.png'
import features3 from '../../assets/images/features3.png'
import dots_bg from '../../assets/images/dots.png'
import round_circle_bg from '../../assets/images/round-circle-bg.png'

const Features = ({mode}) => {
  return (
    <>
      <section className="features_main_section">
        <img src={dots_bg} className="img-fluid features_bg_img" />
        <img src={round_circle_bg} className="img-fluid round_circle_bg " />

        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div>
                <h1 style={{color:mode==="dark"&&"white"}}>
                  what We Can <span> Help You</span> Get ?
                </h1>
              </div>
            </div>

            <div className="row features_white_box" style={{background:mode==="dark"&&"#151515"}}>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="features_box_main">
                  <div className="features_img_blue_bg">
                    <img src={features1} className="img-fluid" />
                  </div>
                  <div className="features_box_content_side">
                    <h2 style={{color:mode==="dark"&&"white"}}>Personalized Learning Programs</h2>
                    <p>
                      Navya® offers tailored educational courses that cater to
                      individual difficulty levels and conceptual requirements,
                      ensuring a personalized learning experience that propels
                      students to higher levels of achievement.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="features_box_main">
                  <div className="features_img_blue_bg">
                    <img src={features2} className="img-fluid" />
                  </div>
                  <div className="features_box_content_side">
                    <h2 style={{color:mode==="dark"&&"white"}}> Comprehensive Test Series/ Mock Tests</h2>
                    <p>
                      With an extensive collection of test series and mock
                      tests, Navya® covers a diverse range of examinations in
                      India, providing students with ample practice
                      opportunities and resources to succeed in their chosen
                      fields.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="features_box_main">
                  <div className="features_img_blue_bg">
                    <img src={features3} className="img-fluid" />
                  </div>
                  <div className="features_box_content_side">
                    <h2 style={{color:mode==="dark"&&"white"}}>Interactive Learning Content and Assessments</h2>
                    <p>
                      Navya® incorporates interactive learning materials and
                      assessments that foster engagement, understanding, and
                      retention, creating an immersive learning experience that
                      keeps students motivated and curious.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Features
