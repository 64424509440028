import React, { Fragment, useContext, useRef, useState } from 'react'
import Logo from '../assets/images/logo.png'
import Signeture from '../assets/images/signetur.png'
import UseAxios from '../util/UseAxios'
import { userContext } from '../Context/UserContextProvider'

const RADIAN = Math.PI / 180


const ScholarshipCertificate = () => {
  const instance = UseAxios()
  const { showetest, setShoweTest, mode } = useContext(userContext)
  let [responseReport, setResponseReport] = React.useState([]);
  let [showetable, setShowetable] = React.useState(5);

  let filterdata = responseReport?.find(item => item?.reportId === showetest)
  console.log(filterdata?.topicScore)
  return (
    <>
      <div className='display_flex_class'>
        <section className='Scholarship_Certificate_border'>
          <section className="">
            <a href='' >
              <img className="logo" src={Logo} alt="" />
            </a>
          </section>
          <div className='Scholarship_Certificate'>
            <h1>Certificate</h1>
            <span className='Name_of_student'>Jone Sing</span>
            <p className='Certificate_contant'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s wit</p>
            <div className='FounderCEO'>
              <img src={Signeture} alt="..." />
              <small>Founder&CEO,Navya</small>
            </div>
            <div className='Certificate_date_no'>
              <span>
                Date of certification: <small>2023-08-01</small>
              </span>
              <span>
                Certificate no. : <small>1BE4B79D-597D-0C9E-A2E7</small>
              </span>
            </div>
            <p className='certificate_visit'>For certificate authentication, please visit <a href="https://navyaedu.com/certificate/certificate_no">https://navyaedu.com/certificate/certificate_no</a></p>
          </div>
        </section>
      </div>
    </>
  )
}

export default ScholarshipCertificate