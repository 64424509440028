import React, { useState } from 'react';

function Test() {


  return (
    <div className="custom-select">
<p>use any class h1,h2,h3,h4,h5,h6</p>

<div className="font-normal">
      <h1 className="text-2xl font-bold">Heading 1</h1>
      <h2 className="text-xl font-bold">Heading 2</h2>
      <h3 className="text-lg font-bold">Heading 3</h3>
      <h4 className="text-base font-bold">Heading 4</h4>
      <h5 className="text-sm font-bold">Heading 5</h5>
      <h6 className="text-sm font-bold">Heading 6</h6>
    </div>
    <p>use any class main div font-normal h1,h2,h3,h4,h5,h6</p>

    <div className="font-normal">
      <h1 >Heading 1</h1>
      <h2 >Heading 2</h2>
      <h3 >Heading 3</h3>
      <h4 >Heading 4</h4>
      <h5 >Heading 5</h5>
      <h6 >Heading 6</h6>
    </div>

<p>not use any class h1,h2,h3,h4,h5,h6</p>
    <div>
      <h1 >Heading 1</h1>
      <h2 >Heading 2</h2>
      <h3 >Heading 3</h3>
      <h4 >Heading 4</h4>
      <h5 >Heading 5</h5>
      <h6 >Heading 6</h6>
    </div>



    <h1 className="text-2xl font-bold">Heading 1</h1>
      <h2 className="text-xl font-bold">Heading 2</h2>
      <h3 className="text-lg font-bold">Heading 3</h3>
      <h4 className="text-base font-bold">Heading 4</h4>
      <h5 className="text-sm font-bold">Heading 5</h5>
      <h6 className="text-sm font-bold">Heading 6</h6>
    </div>
  );
}

export default Test;
