import { Helmet } from "react-helmet";

const MetaData = ({ title,canonicalUrl }) => {
    return (
        <Helmet>
            {/* <title>{title}</title> */}
            <meta charSet="utf-8" />
                <title>{title}</title>
                <link rel="canonical" href={canonicalUrl} />
        </Helmet>
    );
};

export default MetaData;