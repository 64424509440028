import React from 'react'

function GrandTotal(props) {
  return (
    <div>
        <div className='flex justify-between items-center'>
            <div>
                <p className='font-semibold'>{props.title} <span>{props.detail}</span></p>
            </div>
            <div>
                <p className='font-semibold'>{props.price} <i className='fa fa-inr'></i> </p>
            </div>
        </div>
    </div>
  )
}

export default GrandTotal