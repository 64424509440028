import React, { Fragment} from 'react'
import WishlistCard from './WishlistCard'
import Navbar from '../Header/Navbar'
import LoginNavbar from '../LoginNavbar/LoginNavbar'
import SimilarCourses from '../new/SimilarCourses'
import popular_card_img1 from '../../assets/images/courses/1.png'
import popular_card_img2 from '../../assets/images/courses/2.png'
import tah_m from '../../assets/images/tag_m.png'
import Footer from '../Footer/Footer'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import NoCourses from '../new/NoCourses'
import { userContext } from '../../Context/UserContextProvider'
import { Dialog, Transition } from '@headlessui/react'
import ReactHtmlParser   from 'html-react-parser';
import Deletemodel from '../modal/Deletemodel'
import { Link } from 'react-router-dom'
import UseAxios from '../../util/UseAxios'
import MetaData from '../MetaTitle/MetaData'
function Wishlist() {
  const {contextwislist_length,setContextwislist_length,mode}=React.useContext(userContext)
  const instance = UseAxios()

  const usertoken = localStorage.getItem("user_token");
  const [spinner, setSpinner] = React.useState(false)

  let [tresponseData, setTresponseData] = React.useState('')
  let [terrorData, setTerrorData] = React.useState('')
  let [removecourse, setRemovecourse] = React.useState('')
  
  
  const fetchData = async () => {
    
    setSpinner(true)
  
    try {
  
      const response = await instance.get('/api/v1/courses/wishlist');
      setContextwislist_length(response.data.courses.length)
      // setTresponseData(response.data.courses);
      console.log(response.data.courses)
      setTresponseData(response.data.courses.filter((x) => {
        return  x.wishlisted_course != null
      }));
      setSpinner(false)
      
    } catch (error) {
          setSpinner(false)
          setTerrorData(error);
          console.log(error)
    }

  };
  
  React.useEffect(() => {
    fetchData();
  }, [usertoken] );


  
let [prfresponseData, setprfResponseData] = React.useState('')
let [prferrorData, setprfErrorData] = React.useState('')


const runLikeApi = () => {
  
  // event.preventDefault()
  setSpinner(true)
  // https://backend.navyaedu.com/api/v1/auth/profile/update-profile
  instance
    .delete('/api/v1/courses/wishlist/'+removecourse)
    .then((response) => {
      setSpinner(false)
      
      setprfResponseData(response.data)
        console.log(response.data)
        toast.success(response.data.msg);
        fetchData();
        setOpen(false);
      })
    .catch((error) => {
      console.log(error)

      setSpinner(false)

      if (error) {
        setprfErrorData(error.response)
        toast.error(error.response.data.msg)
      }
    })
}

const [open, setOpen] = React.useState(false);

  const handleClickOpen = (course_id) => {
    setOpen(true);
    setRemovecourse(course_id)
    
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
<ToastContainer />
<MetaData title={"Navya | Wishlist"} canonicalUrl={"https://navyaedu.com/wishlist"}/>

{spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}
      <LoginNavbar></LoginNavbar>
      {/* <Navbar /> */}
      {/* <div className="w-[93%] m-auto">
        <WishlistCard img={1} tag={'tag_m'}></WishlistCard>
        <WishlistCard img={1} tag={'tag_m'}></WishlistCard>
      </div> */}

      <div className="container mywishlist_main_section">
        <div className="row">
          <div className="col-lg-12">
            <div>
              <h1 className="heading" style={{color:mode==="dark"&&"white"}}>My Wish List</h1>
            </div>
          </div>
        </div>

        <div className="row">

        {Array.isArray(tresponseData) && tresponseData.length > 0 ? (
          tresponseData.map((course) => (
            
          <div className="col-lg-12 mywishlist_main_row">
            <div className="flex justify-between items-center flex-col lg:flex-row md:flex-column rounded-xl wishlist_row_main_box" style={{background:mode==="dark"&&"#151515"}}>
              <div className="flex items-center flex-col md:flex-row">
                <Link to={`/course/${course?.wishlisted_course?._id}`}>
                
                
                <div className="relative">
                  <div className='w-[10rem] overflow-hidden  mr-10 p-3'>
                    <img
                      src={course.wishlisted_course?.course_image ? course.wishlisted_course?.course_image : '../plceholdernav/placeholder-img.png'}
                      className="w-full md:w-[100%] object-cover rounded-2xl"
                      alt=""
                    />
                  </div>
                  {/* <div className="absolute top-[-.4rem] left-2">
                    <div className="">
                      <img src={tah_m} alt="" width={50} />
                    </div>
                  </div> */}
                </div>
                </Link>

                <div className=" mt-2  basis-3/5">
                  <div className="inline px-2 my-0 py-1 bg-[#d7ffd7] text-[#66BE5E] font-medium rounded-2xl">
                 
                  {RegExp('&lt;').test(course?.wishlisted_course?.course_title) ? ReactHtmlParser(`${course?.wishlisted_course?.course_title.replaceAll('&lt;', '<')}`) :course?.wishlisted_course?.course_title}
                  </div>
                  <p className=" my-0 font-semibold text-lg">
                 
                  {RegExp('&lt;').test(course?.wishlisted_course?.course_subtitle) ? ReactHtmlParser(`${course?.wishlisted_course?.course_subtitle.replaceAll('&lt;', '<')}`) :course?.wishlisted_course?.course_subtitle}
                  </p>
                  <p className="pt-0 my-0">
                    4.8{' '}
                    <span>
                      <i className="fa fa-star text-[orange]"></i> (878 ratings)
                    </span>
                  </p>
                  <p className="font-medium  my-0">
                    Price:{' '}
                    <span className="font-bold px-1">
                      {' '}
                      <i className="fa fa-inr"></i>1820
                    </span>
                    <span className="line-through text-[#c3c3c3] font-normal px-1">
                      {' '}
                      2200
                    </span>
                  </p>
                </div>
              </div>
              <div className="p-10 px-20">
                {/* <button className="mx-5  rounded-full">
                  <i className="fa fa-heart-o p-2 rounded-full  text-[red] border border-[red] hover:!bg-[#775EA5]"></i>
                </button> */}
                <button 
                style={{background:mode==="dark"&&"black"}}
                className="p-3 remove_card rounded-2xl bg-[#F3F3F3] text-[red]  hover:bg-[#775EA5]" 
                onClick={() => {handleClickOpen(course?.wishlisted_course?._id)} }>
                  <i 
                  className="fa fa-trash rounded-full text-[red] hover:text-white"></i> Remove
                </button>
              </div>
            </div>
          </div>
          
    ))
          ) : (
            <NoCourses />

          )}

          
        </div>
      </div>


      <Deletemodel open={open} setOpen={setOpen} deletapi={runLikeApi} />
      

  
    
   
      {/* <SimiliarCourses></SimiliarCourses> */}
      <Footer/>
    </>
  )
}

export default Wishlist
