
import React, { useState, useEffect } from 'react';

const PaymentStatusComponent = () => {
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    // Make API call and update payment status
    fetch('https://api/v1/courses/transaction/paymentcallbackapi/payment-status')
      .then(response => response.json())
      .then(data => setPaymentStatus(data))
      .catch(error => console.error('Error:', error));
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <div>
      {paymentStatus ? (
        <p>Payment Status: {paymentStatus.status}</p>
      ) : (
        <p>Loading payment status...</p>
      )}
    </div>
  );
};

export default PaymentStatusComponent;






// import axios from 'axios';
// import React from 'react'
// import { Link, useParams } from 'react-router-dom'
// function Payapi() {
// // const mrid = useParams(null)
// // console.log(mrid);
//   const usertoken = localStorage.getItem('user_token')
//   const instance = axios.create({
//       baseURL: 'http://192.168.1.6:9000/',
//       headers: {
//         Authorization: 'Bearer ' + usertoken,
//       },
//     })


    
//       const paymentstatus = async () => {
   
  
//         try {
//           const response = await instance.get(
//             '/api/v1/courses/transaction/paymentcallbackapi',
//           )
//           console.log(response)
         
//         } catch (error) {

//         }
//       }
  

//       React.useEffect(() => {
//             paymentstatus()
//         }, [usertoken])
  
//   return (
//     <div>payapi</div>
//   )
// }

// export default Payapi;