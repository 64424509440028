import React, { useRef } from "react";
import "./assets/css/App.css";
import "./assets/css/responsivestyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Home from "./components/Home/Home";
import CourseDetails from "./components/CourseDetails/CourseDetails";
import Wishlist from "./components/Wishlists/Wishlist";
import Orders from "./components/Order/Orders";
import StartPage from "./components/LoginSignUpSetup/StartPage";
import EmailNotVerify from "./components/LoginSignUpSetup/EmailNotVerify.jsx";
import PhoneNotVerify from "./components/LoginSignUpSetup/PhoneNotVerify.jsx";
import MyOrders from "./components/MyOrders/MyOrders";
import SimilarCourses from "./components/new/SimilarCourses";
// import SimilarCoursesCopy from './components/new/SimilarCoursesCopy'
import MyCourse from "./components/MyCourses/MyCourse";
import CourseConfirmation from "./components/MyCourses/CourseConfirmation";
import MyQuizSection from "./components/MyCourses/MyQuizSection";
import PurchasedSeries from "./components/MyCourses/PurchasedSeries";
import Test from "./components/Test/Test";
import SignUp from "./components/LoginSignUpSetup/SignUp";
import ForgotPassword from "./components/LoginSignUpSetup/ForgotPassword";
import ChangePassword from "./components/LoginSignUpSetup/ChangePassword";
import SendOtp from "./components/LoginSignUpSetup/SendOtp";
import VerifyOtp from "./components/LoginSignUpSetup/VerifyOtp";
import ReferEarn from "./components/Refer&Earn/ReferEarn";
import Myprofile from "./components/profile/Myprofile.jsx";
import Setting from "./components/Setting/Setting";
import SearchResult from "./components/new/SearchResult";
import Events from "./components/Events/Events";
import TestReport from "./components/TestReport/TestReport";
import FinalTestReport from "./components/TestReport/FinalTestReport";
import { useEffect, useState } from "react";
import About from "./components/new/About";
import Contactus from "./components/new/Contactus";
import TandC from "./components/ContactUs/TandC";
import Refund from "./components/ContactUs/Refund";
import PrivacyPolicy from "./components/ContactUs/PrivacyPolicy";
import ProtectedRoute from "./util/ProtectedRoute";
import Notification from "./components/new/Notification";
import Notfound from "./components/notfound/Notfound.jsx";
import Testonly from "./test/Test";
import Paymentsucces from "./test/Paymentsucces.jsx";
import Payapi from "./test/Payapitest.jsx";
import NotificationDetails from "./components/new/NotificattionDetails";
import { userContext } from "./Context/UserContextProvider";
import axios from "axios";
import { toast } from "react-toastify";
import Success from "./components/Payment/Success.jsx";
import Failure from "./components/Payment/Failure.jsx";
import PayStatuspayment from "./components/Order/Paystatuspayment.jsx";
import Invioce from "./components/invoice/invoice.jsx";
import QuizTest from "./components/startest/QuizTest.jsx";
import Cookies from "js-cookie";
import SettingMenu from "./components/Setting/SettingMenu.jsx";
import MenuDetails from "./components/Setting/MenuDetails.jsx";
import Scholarship from "./scholarship/Scholarship.jsx";
import ScholarshipTest from "./scholarship/ScholarshipTest.jsx";
import ScholarshipTestPage from "./scholarship/ScholarshipTestpage.jsx";
import ScholarshipCertificate from "./scholarship/ScholarshipCertificate.jsx";
import ScholarshipStartTest from "./scholarship/ScholarshipStartTest.jsx";
import UseAxios from "./util/UseAxios.jsx";
import Certificate from "./components/new/Certificate.jsx";
import SchlorshipReport from "./scholarship/ScholarshipReport.jsx";


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let usertoken = localStorage.getItem("user_token");
  const recaptchaRef = useRef(null);
  
  // console.log(usertoken);
  
  
  
  // console.log(usertoken)
  
  const checkUserToken = () => {
    if (!usertoken || usertoken === "undefined") {
      setIsLoggedIn(false);
    }
    setIsLoggedIn(true);
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  
  const instance = UseAxios()

  // recaptcha state
  const [capVal, setCapval] = useState(null);
  //context api usestate hooks author:Nitesh
  const [contextvalue, setContextvalue] = useState(null);
  const [contextcart_length, setContextcart_length] = useState("");
  const [contextwislist_length, setContextwislist_length] = useState("");
  const [cart_added, setCart_added] = useState([]);
  const [wislist_added, setWislist_added] = useState([]);
  const [notification_lenght, setNotificationlenght] = useState([]);
  //  ======================for open share modal===========================
  const [sharemodel, setSharemodel] = useState(false);
  const [sharemodelType, setSharemodelType] = useState('');
  const [shareModelcontent, setShareModelcontent] = React.useState();
  const [shareModelitemid, setShareModelitemid] = React.useState();
  const [merchantransaction_id, setMerchantTransactionId] = useState("");
  const [order_id, setOrderId] = useState("");
  const [access_token, setAccess_token] = useState("");
  // profile image path send
  const [profiledata, setProfiledata] = React.useState([]);
  let [responseCourseData, setResponseCourseData] = React.useState([])
  // test-report.jsx  quizid
  let [showetest, setShoweTest] = useState();
  let [mode, setMode] = useState(localStorage.getItem("MODE_NAVYA") && localStorage.getItem("MODE_NAVYA") === "dark"  ? "dark" :'light');
  useEffect(()=>
  {
    console.log(mode);
  },[mode])

  //add to cart functionlity
  const cartitemslength = async () => {
    try {
      const response = await instance.get(
        "/api/v1/courses/transaction/my-cart"
      );
      setCart_added(response.data.courses);
      setContextcart_length(response.data.courses.length);
    } catch (error) {
      console.log(error);
    }
  };

  // const callfunction = (course_id) => {
  //   console.log(course_id);
  //   instance
  //     .post(`/api/v1/courses/transaction/my-cart/${course_id}`)
  //     .then((response) => {
  //       toast.success(response.data.msg);
  //       cartitemslength();
  //     })
  //     .catch((error) => {
  //       console.log(error.response.request.status);

  //       if (error.response.request.status === 401) {
  //         // toast.error(error.response.data.msg)
  //         window.location.href = "/signIn";
  //       } else {
  //         toast.error(error.response.data.msg);
  //       }
  //     });
  // };
  const callfunction = async (course_id) => {
    console.log(course_id);
    if(profiledata.phone_no_verified === false){
      toast.error("First Verify Your Mobile Number");
      return false;
    }
  
  
    try {
      const response = await instance.post(`/api/v1/courses/transaction/my-cart/${course_id}`);
    
      toast.success(response.data.msg);
      cartitemslength();
    } catch (error) {
      console.error(error);
  
      if (error.response && error.response.request && error.response.request.status === 401) {
        // toast.error(error.response.data.msg)
        window.location.href = "/signIn";
      } else {
        toast.error(error.response ? error.response.data.msg : "An error occurred");
      }
    }
   
  };

  // add to wishlist functionli
  function wishlistitemslength() {
    instance.get("/api/v1/courses/wishlist").then((response) => {
      setWislist_added(response.data.courses);
      setContextwislist_length(response.data.courses.length);
    });
  }
  function wislistcourse(course_id) {
    if(!localStorage.getItem("user_token") && !Cookies.get("user_token")){
      window.location.href = "/signIn";
      
    }else{
    instance
      .post("/api/v1/courses/wishlist/" + course_id)
      .then((response) => {
        // setSpinner(false)
        console.log(response.data);
        wishlistitemslength();
        toast.success(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
        // setSpinner(false)
          // window.location.reload();
        
      })}
  }

  //notification read call function
  const fetchnotificationData = () => {
    instance
      .get("/api/v1/app/notification")
      .then((response) => {
        const filter_notification = response.data.notifications.filter(
          (item) => {
            return item.is_seen === false;
          }
        );
        setNotificationlenght(filter_notification);
      })
      .catch((error) => {
        // toast.error(error.response.data.msg)
      });
  };

  function notificationreade(notification_id) {
    instance
      .post(`/api/v1/app/notificationseen/${notification_id}`)
      .then((response) => {
        // toast.success(response.data.msg)
        fetchnotificationData();
      })
      .catch((error) => {
        // toast.error(error.response.data.msg)
      });
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/news",
      element: <Home />,
    },
    {
      path: "/about-us",
      element: <About />,
    },
    {
      path: "/contact-us",
      element: <Contactus />,
    },
    {
      path: "/contact",
      element: <Contactus />,
    },
    {
      path: "/terms-and-condition",
      element: <TandC />,
    },
    {
      path: "/Refund-policy",
      element: <Refund />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/search",
      element: <SearchResult />,
    },
    {
      path: "/payment-PayStatus/:transaction_id",
      element: <PayStatuspayment />,
    },
    // {
    //   path: "/invoice",
    //   element: <Invioce />,
    // },
    {
      path: "/test",
      element: <Testonly />,
    },
    {
      path: "/Payment",
      element: <Paymentsucces />,
    },
    {
      path: "/Payment-status",
      element: <Payapi />,
    },
    {
      path: "/Payment",
      element: <Success />,
    },
    {
      path: "/failure",
      element: <Failure />,
    },
    {
      path: "/wishlist",
      element: (
        <ProtectedRoute>
          <Wishlist></Wishlist>
        </ProtectedRoute>
      ),
    },
    {
      path: "/notification",
      element: (
        <ProtectedRoute>
          <Notification></Notification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/order_details",
      element: (
        <ProtectedRoute>
          <Orders></Orders>
        </ProtectedRoute>
      ),
    },
    {
      path: "/certificate",
      element: (
        <ProtectedRoute>
          <Certificate/>
        </ProtectedRoute>
      ),
    },
    {
      path: "/myorder",
      element: (
        <ProtectedRoute>
          <MyOrders></MyOrders>
        </ProtectedRoute>
      ),
    },
    {
      path: "/mycourse",
      element: (
        <ProtectedRoute>
          <MyCourse></MyCourse>
        </ProtectedRoute>
      ),
    },
    {
      path: "/Similar-Course",
      element: (
        <ProtectedRoute>
          <SimilarCourses></SimilarCourses>
        </ProtectedRoute>
      ),
    },
    {
      path: "/startcourse",
      element: (
        <ProtectedRoute>
          <CourseConfirmation></CourseConfirmation>
        </ProtectedRoute>
      ),
    },
    {
      path: "/My-Purchased-plan",
      element: (
        <ProtectedRoute>
          <PurchasedSeries></PurchasedSeries>
        </ProtectedRoute>
      ),
    },
    {
      path: "/My-Quiz-Section",
      element: (
        <ProtectedRoute>
          <MyQuizSection></MyQuizSection>
        </ProtectedRoute>
      ),
    },
    {
      path: "/startTest",
      element: (
        <ProtectedRoute>
          <Test></Test>
        </ProtectedRoute>
      ),
    },
    {
      path: "/test-report",
      element: (
        <ProtectedRoute>
          <TestReport />
        </ProtectedRoute>
      ),
    },
    {
      path: "/download-test-report",
      element: (
        <ProtectedRoute>
          <FinalTestReport />
        </ProtectedRoute>
      ),
    },
    {
      path: "/my-profile",
      element: (
        <ProtectedRoute>
          <Myprofile />
        </ProtectedRoute>
      ),
    },
    {
      path: "/setting",
      element: (
        // <ProtectedRoute>
          <Setting />
        // </ProtectedRoute>
      ),
    },
    {
      path: "/settingMenu",
      element: (
        <ProtectedRoute>
          <SettingMenu />
        </ProtectedRoute>
      ),
    },
    {
      path: "/MenuDetails",
      element: (
        <ProtectedRoute>
          <MenuDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: "/events",
      element: (
        <ProtectedRoute>
          <Events />
        </ProtectedRoute>
      ),
    },
    {
      path: "/refer-and-earn",
      element: (
        <ProtectedRoute>
          <ReferEarn />
        </ProtectedRoute>
      ),
    },
    {
      path: "/scholarship",
      element: (
        <ProtectedRoute>
          <Scholarship />
        </ProtectedRoute>
      ),
    },
    {
      path: "/scholarship/:ScholarshipID",
      element: (
        <ProtectedRoute>
          <ScholarshipTest />
        </ProtectedRoute>
      ),
    },
    {
      path: "/scholarship/:ScholarshipID/:QuizID",
      element: (
        <ProtectedRoute>
          <ScholarshipStartTest />
        </ProtectedRoute>
      ),
    },
    {
      path: "/Scholarshiptestpage",
      element: (
        <ProtectedRoute>
          <ScholarshipTestPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/scholarship-report",
      element: (
        <ProtectedRoute>
         <SchlorshipReport/>
        </ProtectedRoute>
      ),
    },
    {
      path: "/scholarshipcertificate",
      element: (
        <ProtectedRoute>
          <ScholarshipCertificate />
        </ProtectedRoute>
      ),
    },
    {
      path: "/signIn",
      element: <StartPage></StartPage>,
    },
    {
      path: "/signUp",
      element: <SignUp></SignUp>,
    },
    {
      path: "/forgot_password",
      element: <ForgotPassword></ForgotPassword>,
    },
    {
      path: "/sendOtp",
      element: <SendOtp></SendOtp>,
    },
    {
      path: "/emailVerify",
      element: <EmailNotVerify/>,
    },
    {
      path: "/phoneVerify",
      element: <PhoneNotVerify/>,
    },
    {
      path: "/verifyOtp",
      element: <VerifyOtp></VerifyOtp>,
    },
    {
      path: "/changePass",
      element: <ChangePassword></ChangePassword>,
    },{
      path: "/course/:course_id",
      element: <CourseDetails></CourseDetails>,
    },
    {
      path: "*",
      element: <Notfound></Notfound>,
    },
  ]);

  return (
    <div style={{background:mode==="dark" &&"black",color:mode==="dark" &&"white"}} >
  
    <userContext.Provider
      value={{
        mode, setMode,
        access_token,
        setAccess_token,
        contextvalue,
        setContextvalue,
        setContextcart_length,
        contextcart_length,
        setContextwislist_length,
        contextwislist_length,
        callfunction,
        cartitemslength,
        wislistcourse,
        wishlistitemslength,
        notificationreade,
        setCart_added,
        cart_added,
        wislist_added,
        setWislist_added,
        notification_lenght,
        setNotificationlenght,
        fetchnotificationData,
        sharemodel,
        setSharemodel,
        shareModelcontent,
        setShareModelcontent,
        shareModelitemid,
        setShareModelitemid,
        profiledata,
        setProfiledata,
        merchantransaction_id,
        setMerchantTransactionId,
        order_id,
        setOrderId,
        showetest,
        setShoweTest,
        capVal,
        setCapval,
        responseCourseData,
        setResponseCourseData,
        sharemodelType, setSharemodelType,
        recaptchaRef
      }}
    >
      <RouterProvider router={router} />
    </userContext.Provider>
    </div>
  );
}

export default App;
