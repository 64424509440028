import React, {Fragment, useRef, useState } from 'react'
import { BsCheckCircle, BsFillMoonFill, BsFillSunFill } from 'react-icons/bs'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify'
import UseAxios from '../../util/UseAxios'
import { useNavigate } from 'react-router'
import danger from '../../assets/images/Danger.png'
import { Dialog, Transition } from '@headlessui/react'  
import Cookies from 'js-cookie'

function Dataminimization({mode}) {
    const navigate = useNavigate()
    const instance = UseAxios()
    const usertoken = localStorage.getItem('user_token')
    const [spinner, setSpinner] = React.useState(false)
    let [prferrorData, setprfErrorData] = React.useState('')
    const cancelButtonRef = useRef(null)
    let [prfresponseData, setprfResponseData] = React.useState('')
    let [rsn, setRsn] = React.useState('')
      const [deactivateopen, setdeactivateOpen] = useState(false)

      let [deactivedec, setDeactiveDec] = React.useState('')

      const bodydeac_json = {
        account_deactivation_reason: rsn,
        account_deactivation_description: deactivedec,
      }
      const deacSubmit = (event) => {
        event.preventDefault()
        setSpinner(true)
        // https://backend.navyaedu.com/api/v1/auth/profile/update-profile
        instance
          .patch('/api/v1/auth/profile/deactivate-account', bodydeac_json)
          .then((response) => {
            setSpinner(false)
    
            setprfResponseData(response.data)
            console.log(response.data)
            toast.success('Account Deativated Successfully')
            setTimeout(() => {
              setLogouteOpen(true)
            }, 2000)
          })
          .catch((error) => {
            console.log(error)
    
            setSpinner(false)
    
            if (error) {
              setprfErrorData(error.response)
              toast.error(error.response.data.msg)
            }
          })
    
        event.preventDefault()
        localStorage.clear()
        Cookies.remove('user_token')
        navigate('/signIn')
      }

  const deactivatehandleShow = (e) => {
    return e.preventDefault(), setdeactivateOpen(true)
  }
  const deactivatehandleClose = (e) => {
    return e.preventDefault(), setdeactivateOpen(false)
  }


  //   // logout
  const [Logouteopen, setLogouteOpen] = useState(false)
  const LogouthandleShow = (e) => {
    return e.preventDefault(), setLogouteOpen(true)
  }
  const LogouthandleClosedeactivate = (e) => {
    return e.preventDefault(), setLogouteOpen(false)
  }
  return (
    <section  className=" tabs_side_gap">
            <div className="w-[100%] m-auto other_tabs_headings" style={{ color:mode==="dark"&&"white"}}>
              <h2 style={{ color:mode==="dark"&&"white"}}  >Data Minimization</h2>
              <div>
                <p>
                  Before proceeding with the account minimization process,
                  please take a moment to review the following terms and
                  conditions related to data minimization for Navya®:
                </p>

                <ul className="_Data_deletion_process">
                  <li>
                    <h3 style={{ color:mode==="dark"&&"white"}}>Data Deletion Process:</h3>
                    <ul>
                      <li>
                        By confirming account deletion, you understand and agree
                        that all your personal data, including account
                        information, course details, and purchase history, will
                        be permanently deleted from Navya®'s databases.
                      </li>
                      <li>
                        This process is irreversible, and the deleted data
                        cannot be recovered once the account deletion is
                        completed.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3 style={{ color:mode==="dark"&&"white"}}>Responsibility:</h3>
                    <ul>
                      <li>
                        Navya® will make every effort to promptly delete your
                        account and associated data. However, Navya® shall not
                        be held responsible for any inadvertent delay in the
                        deletion process or any technical issues that may arise
                        during this operation.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3 style={{ color:mode==="dark"&&"white"}}>Loss of Data:</h3>
                    <ul>
                      <li>
                        Please be aware that all your learning progress,
                        achievements, and purchase history will be lost after
                        the account deletion. This action is final and cannot be
                        undone.
                      </li>
                      <li>
                        Navya® will not be responsible for any loss you may
                        incur due to the deletion of your data, including but
                        not limited to progress tracking, certificates, and
                        access to purchased content.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3 style={{ color:mode==="dark"&&"white"}} >Unable to Delete Your Data:</h3>
                    <ul>
                      <li>
                        In the rare event that you encounter difficulties in
                        deleting your account or data through the platform, you
                        can send a deletion request to{" "}
                        <a
                          className="font-semibold"
                          href="mailto:support@navyaedu.com"
                        >
                          support@navyaedu.com
                        </a>
                        . Our support team will assist you in resolving the
                        issue.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h3 style={{ color:mode==="dark"&&"white"}}>Links to Policies:</h3>
                    <ul>
                      <li>
                        For more information on how your data is handled, please
                        refer to our{" "}
                        <a
                          className="font-semibold"
                          href="https://navyaedu.com/privacy-policy"
                        >
                          Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a
                          className="font-semibold"
                          href="https://navyaedu.com/terms-and-condition"
                        >
                          Terms of Service
                        </a>
                        . It is advisable to review these documents to
                        understand the principles and guidelines governing data
                        usage on Navya®.
                      </li>
                    </ul>
                  </li>
                </ul>

                <p>
                  By clicking the "Control My Data", you acknowledge that you
                  have read, understood, and agree to these Data Minimization
                  Terms and Conditions. If you have any questions or concerns,
                  please contact our support team at{" "}
                  <a
                    className="font-semibold"
                    href="mailto:support@navyaedu.com"
                  >
                    support@navyaedu.com
                  </a>
                  before proceeding.
                </p>
              </div>
              <div className="text-center Deactivate_tab_buttons">
                <button
                  className=" rounded  font-semibold Deactivate_btn btn_hover_white"
                  onClick={deactivatehandleShow}
                >
                  Control My Data
                </button>
              </div>
            </div>






            <Transition.Root show={deactivateopen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setdeactivateOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4  ">
                    <div className="success_edit_profile">
                      <img src={danger} alt="" className="danger_img" />
                      <h4>Deactivate your Account? </h4>
                    </div>

                    <div className="Personal_Info_div ">
                      <div className="deactivate_modal">
                        <form className="pt-5" onSubmit={deacSubmit}>
                          <div className="mx-0 md:mx-0 my-2 w-full">
                            <input
                              required
                              type="text"
                              onChange={(e) => setRsn(e.target.value)}
                              className="rounded p-3 border border-[#cabde2] w-full"
                              placeholder="Please Enter Your Issue to deactivate "
                            />
                          </div>

                          <div className="mx-0 md:mx-0 my-2 w-full">
                            <textarea
                              required
                              onChange={(e) => setDeactiveDec(e.target.value)}
                              className="rounded p-3 border border-[#cabde2] w-full"
                              placeholder="Enter Your suggestion so that we can improve"
                              rows={4}
                            ></textarea>
                          </div>

                          <div className="text-center">
                            <div className="text-center Deactivate_tab_buttons">
                              <button
                                className=" rounded text-white font-semibold btn_hover"
                                onClick={deactivatehandleClose}
                              >
                                Go Back
                              </button>
                              <button
                                type="submit"
                                className=" rounded  font-semibold Deactivate_btn btn_hover_white"
                              // onClick={LogouthandleShow}
                              >
                                Deactivate
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
          </section>
  )
}

export default Dataminimization