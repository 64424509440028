import React from 'react'
import Footer from '../Footer/Footer'
import LoginNavbar from '../LoginNavbar/LoginNavbar'
import MyCourseCard from './MyCourseCard'

function MyCourse() {
  return (
    <div>
        <LoginNavbar></LoginNavbar>
        <MyCourseCard img={1}></MyCourseCard>
        {/* <Footer></Footer> */}
    </div>
  )
}

export default MyCourse