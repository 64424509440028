import React from 'react'
import InviteImg from '../../assets/images/invitePic.png'
import { Link } from 'react-router-dom'
import Lottie from 'react-lottie'

import invite from '../../assets/images/InviteNow'

function InviteCard() {
  const slider1 = {
    loop: true,
    autoplay: true,
    animationData: invite,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <>
      {/* <div className="w-[90%] m-auto p-2 shadow-2xl shadow-slate-200 border border-[#66BE5E] rounded-xl">
        <div className="flex justify-center items-center flex-col md:flex-row">
          <div className="flex items-center flex-col md:flex-row md:items-start">
            <div className="invite_card_box">
              <Lottie options={slider1} height="100%" width="100%" />
            </div>
            <div className="lg:w-[60%] mt-3 md:w-[95%] sm:w[90%] md:ml-5">
              <h1 className="text-3xl font-bold">
                Invite & <span className="text-[#66BE5E]">Earn</span>
              </h1>
              <p className="py-3 font-medium">
                Invite your friends to Navya® and earn rewards! For every
                successful referral, you'll receive 50 coins, with a total earning
                potential of up to 150 coins. Start inviting and enjoy the
                benefits of learning and earning with Navya®, the innovative
                e-learning platform revolutionizing education.
              </p>
              <h3 className="py-2 text-xl font-semibold">Get ₹20 for Ticket</h3>
            </div>
          </div>
          <div className="text-center invite__btn invite__earn_btn">
            <button style={{ minWidth: '200px', marginRight: '10px' }}>
              {' '}
              <Link
                className="bg-[#66BE5E] mt-7 rounded text-white font-semibold w-48 px-5 py-3 m-auto btn_hover_white"
                to="/refer-and-earn"
              >
                Invite & Earn
              </Link>{' '}
            </button>
          </div>
        </div>
      </div> */}
      <div className='w-[90%]  m-auto invite_card_main'>
        <div className='row'>
          <div className='col-lg-4 col-md-4 col-sm-12'>
            <div className="invite_card_box">
              <Lottie options={slider1} isClickToPauseDisabled={true} height="100%" width="100%" />
            </div>
          </div>
          <div className='col-lg-8 col-md-8 col-sm-12'>
            <h1 className="text-3xl font-bold mt-2">
              Invite & <span className="text-[#66BE5E]">Earn</span>
            </h1>
            <p className="py-0 font-medium">
              Invite your friends to Navya® and earn rewards! For every
              successful referral, you'll receive 50 coins, with a total earning
              potential of up to 150 coins. Start inviting and enjoy the
              benefits of learning and earning with Navya®, the innovative
              e-learning platform revolutionizing education.
            </p>
            <h3 className="py-2 text-xl font-semibold mb-4">Get ₹20 for Ticket</h3>

            <Link
              className="bg-[#66BE5E]  rounded text-white font-semibold w-48 px-5 py-3 m-auto btn_hover_white"
              to="/refer-and-earn"
            >
              Invite & Earn
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default InviteCard
