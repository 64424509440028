import React from 'react'
import Lottie from 'react-lottie'
import successgif from '../../assets/images/payments/Animation - 1699689011051.json'
function Success() {
    const success = {
        loop: true,
        autoplay: true,
        animationData: successgif,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }
  return (
    <div className='flex w-[100vw] justify-center h-[100vh] items-center '>
    <div className='paymentsucces'>
            <div>
                <h3>Success</h3>
                <p>Your Payment has been success</p>
                {/* <img className='m-auto' src='../payment/paymentsucces.png' alt="" /> */}
                <Lottie options={success} isClickToPauseDisabled={true} height="100%" width="100%" />
                <div class=" mt-8   ">
                    <a href="/invoice">
                        <button class="bg-[#66BE5E] w-4/5  py-3  rounded text-white btn_hover">Check Invoice</button>
                    </a>
                </div>
            </div>
        </div>
        </div>
  )
}

export default Success