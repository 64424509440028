import React,{Suspense,lazy} from 'react'
import Advertise from '../Advertise/Advertise'
import ConatctUs from '../ContactUs/ConatctUs'
import Footer from '../Footer/Footer'
// import Navbar from '../Header/Navbar'
import Invite from '../Invite/Invite'
import Features from '../new/Features'
import BestInstructur from '../new/BestInstructur'
import Services from '../new/Services'
// import Slider from '../new/Slider'
// import GoToTop from '../GoToTop'
// import GoToBottom from '../GoToBottom'
import { userContext } from '../../Context/UserContextProvider'
import { Audio } from 'react-loader-spinner'
import MetaData from '../MetaTitle/MetaData'


const Testimonial =lazy(()=>import('../Testimonial/Testimonial'))
const TrendingCategory =lazy(()=>import('../new/TrendingCategory'))

const GoToTop =lazy(()=>import('../GoToTop'))
const GoToBottom =lazy(()=>import('../GoToBottom'))
const Navbar =lazy(()=>import('../Header/Navbar'))
const Slider =lazy(()=>import('../new/Slider'))
const Popular =lazy(()=>import('../new/Popular'))
const Blog =lazy(()=>import('../new/Blog'))

function Home() {
  const usertoken = localStorage.getItem('user_token')
  const { capVal,mode, setMode } = React.useContext(userContext)

  return (
    <div>
       <MetaData title={"Navya | Home"} canonicalUrl={"https://navyaedu.com"}/>
   

       <Suspense fallback={ 
         <div class="loaderslider"></div>
        }
        >
        <Navbar   mode={mode} />

      <Slider  mode={mode}/>
        </Suspense>


      <Features  mode={mode}/>
      <BestInstructur  mode={mode}/>
      <Services  mode={mode}/>


      <Suspense fallback={ 
      <div className='flex w-[100vw] justify-center items-center'>
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          />
          </div>
        }>
      <TrendingCategory mode={mode}/>

      <Popular mode={mode} />
      <Testimonial mode={mode}/>
      <Blog mode={mode}/>
      </Suspense>


      <Advertise mode={mode}/>
      <ConatctUs mode={mode}/>
      <Invite  mode={mode}/>
      <Footer/>
      <Suspense fallback={ 
      <div className='flex w-[100vw] justify-center items-center'>
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          />
          </div>
        }>

      <GoToBottom/>
      <GoToTop/>
        </Suspense>
    
    </div>
  )
}

export default Home
