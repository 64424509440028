import React from 'react'
import { Link } from 'react-router-dom'
import { FaShareAlt } from 'react-icons/fa'
// import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import NoCourses from '../new/NoCourses'
import ReactHtmlParser from 'html-react-parser';
import UseAxios from "../../util/UseAxios"
function MyOrderCard(props) {
  const [spinner, setSpinner] = React.useState(false)
  let [responseData, setResponseData] = React.useState('')
  let [responseFreeCourse, setResponseFreeCourse] = React.useState([])
  let [errorData, setErrorData] = React.useState('')
  const {mode}=props
  // const instance = AxiosInstance();
  const instance = UseAxios()
  //change plan buy in after purchase author: Nitesh 
  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)

      try {
        const response = await instance.get(
          '/api/v1/courses/transaction/my-orders',
        )
        console.log(response.data)
        setResponseFreeCourse(response.data.freeCourses)
        setResponseData(response.data.data)
        setSpinner(false)
      } catch (error) {
        setSpinner(false)
        setErrorData(error)
      }
    }

    fetchData()
  }, [])

  const handleCourse = (courseId) => {
    localStorage.setItem('TestId', courseId)
  }

  return (
    <>
      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}

      <div className="row mt-4">
        

      <h1 className="text-3xl font-bold">Demo Courses</h1>
         { responseFreeCourse?.map((course,index) => {
          return(
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-2 ">
              <section className={`${mode==="dark" ?"order_dark_mode":"my_order_card "} lg:w-[270px]`}>
                <div className={`${props.width} mx-2 py-2`}>
                  <div className="relative">
                    <div className=' xl:h-[13rem] object-cover  card_image_top'>
                      {/* <Link to={'/startTest?test_id='+ course.plan_purchased.of_course_series._id} */}
                      <Link
                        to={
                          '/My-Purchased-plan?plan_id=' +
                          course?.planId
                          }
                        // onClick={() =>
                        //   handleCourse(
                        //     course?.seriesId,
                        //   )
                        // }
                      >
                        <img
                          src={
                            course.courseImage ? course.courseImage : '../plceholdernav/placeholder-img.png'
                          }
                          className="sm:w-full order_img object-fit sm:h-full m-auto"
                          alt=""
                        />
                      </Link>
                    </div>
                    {/* <div className="absolute top-[-.4rem] left-2">
                      <div className="">
                        <img
                          src={require(`../../assets/images/${props.tag}.png`)}
                          alt=""
                          width={40}
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className='card_text_bottom text-center'>
                  <div className="pt-1 text-center">
                    <p className="bg-[#d1f9cd] text-[#268a1d] px-2 py-1 mx-auto my-0 rounded-xl w-fit">
                      {ReactHtmlParser(`${course?.seriesName?.replaceAll('&lt;', '<')}`)} 
                        {/* {' ( ' +
                        course?.planDiscountedPrice +
                        '₹  )'} */}
                    </p>
                  </div>
                  <div className="pt-0 pb-0 text-center">
                    <p className="font-semibold flex justify-center items-center my-0">
                      {
                        ReactHtmlParser(`${course?.courseName?.replaceAll('&lt;', '<')}`)
                      }

                      {/* <button className="py-0 text-[1.2rem] text-[#565656] font-medium">
                        <FaShareAlt />
                      </button> */}
                    </p>
                    {/* <div
                      className="flex"
                      style={{ justifyContent: 'center' }}
                    >
                      <p className=" text-[1.2rem] text-[#565656] font-medium pera_my_remove">
                        <small>
                          4.8{' '}
                          <span>
                            <i className="fa fa-star text-[#FFD12D]"></i>
                          </span>{' '}
                          (878 ratings)
                        </small>
                      </p>
                      
                    </div> */}
                  </div>
                  <div className="p-0">
                    <p className="font-semibold">
                      Status:{' '}
                      <span className="text-[green] text-medium px-1">
                      Free course
                        {/* {course?.planDiscountedPrice === 0 ? "Free course" : "Purchased"} */}
                      </span>
                    </p>
                  </div>
                  </div>
                </div>
              </section>
            </div>
          )})}



<div className="pt-2">
          <h1 className="text-3xl font-bold">My Courses</h1>
          <p className="pt-2 w-[30%] text-[#727272]" style={{color:mode=="dark"&&"white"}}>
            {/* At the Academy, we strive to bring together the best professors */}
            View and manage all your orders at a glance
          </p>
          {/* <p className="text-[#727272] font-bold">For The Best Courses</p> */}
        </div>

        {Array.isArray(responseData) && responseData.length > 0 ? (
          responseData.map((course,index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-2 ">
              <section className={`${mode==="dark" ?"order_dark_mode":"my_order_card "} lg:w-[270px]`}>
                <div className={`${props.width} mx-2 py-2`}>
                  <div className="relative">
                    <div className=' xl:h-[13rem] object-cover  card_image_top'>
                      {/* <Link to={'/startTest?test_id='+ course.plan_purchased.of_course_series._id} */}
                      <Link
                        to={course?.expired === true
                          ? `/course/${course?.courseId}`
                          :
                          '/My-Purchased-plan?plan_id=' +
                          course?.planId
                          }
                        // onClick={() =>
                        //   handleCourse(
                        //     course?.seriesId,
                        //   )
                        // }
                      >
                        <img
                          src={
                            course.courseImage ? course.courseImage : '../plceholdernav/placeholder-img.png'
                          }
                          className="sm:w-full order_img object-fit sm:h-full m-auto"
                          alt=""
                        />
                      </Link>
                    </div>
                    {/* <div className="absolute top-[-.4rem] left-2">
                      <div className="">
                        <img
                          src={require(`../../assets/images/${props.tag}.png`)}
                          alt=""
                          width={40}
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className='card_text_bottom text-center'>
                  <div className="pt-1 text-center">
                    <p className="bg-[#d1f9cd] text-[#268a1d] px-2 py-1 mx-auto my-0 rounded-xl w-fit">
                      {ReactHtmlParser(`${course?.seriesName?.replaceAll('&lt;', '<')}`)} 
                        {' ( ' +
                        course?.planDiscountedPrice +
                        '₹  )'}
                    </p>
                  </div>
                  <div className="pt-0 pb-0 text-center">
                    <p className="font-semibold flex justify-center items-center my-0">
                      {
                        ReactHtmlParser(`${course?.courseName?.replaceAll('&lt;', '<')}`)
                      }

                      {/* <button className="py-0 text-[1.2rem] text-[#565656] font-medium">
                        <FaShareAlt />
                      </button> */}
                    </p>
                    {/* <div
                      className="flex"
                      style={{ justifyContent: 'center' }}
                    >
                      <p className=" text-[1.2rem] text-[#565656] font-medium pera_my_remove">
                        <small>
                          4.8{' '}
                          <span>
                            <i className="fa fa-star text-[#FFD12D]"></i>
                          </span>{' '}
                          (878 ratings)
                        </small>
                      </p>
                      
                    </div> */}
                  </div>
                  <div className="p-0">
                    <p className="font-semibold">
                      Status:{' '}
                        {/* {course?.planDiscountedPrice === 0 ? "Free course" : "Purchased"} */}
                      {course?.expired === true 
                      ?
                      <span className="text-[red] text-medium px-1">
                      Course Expired
                      </span>
                      :
                      <span className="text-[green] text-medium px-1">
                      Purchased
                      </span>}
                    </p>
                  </div>
                  </div>
                </div>
              </section>
            </div>
          ))
        ) : (
          <NoCourses myorder={"purchase"}/>
        )}


         
        
      </div>
    </>
  )
}

export default MyOrderCard
