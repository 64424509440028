import React, { useEffect } from 'react'
// import Footer from '../Footer/Footer'
import LoginNavbar from '../LoginNavbar/LoginNavbar'
import CourseConfirmationCard from './CourseConfirmationCard'
import { Link } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import { useCallback } from 'react'
import useRazorpay from 'react-razorpay'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useNavigate} from "react-router-dom"


function CourseConfirmation(props) {
  const navigate = useNavigate();

  const [spinner, setSpinner] = React.useState(false)
  let [responseData, setResponseData] = React.useState('')
  let [errorData, setErrorData] = React.useState('')
  const [courseId, setCourseId] = React.useState(
    localStorage.getItem('seriesId'),
  )

  // const usertoken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDcxODEwYWJhMzc0OGZmZDc4ZjAwN2IiLCJyb2xlIjoidXNlciIsImlhdCI6MTY4NTI1MTUzOSwiZXhwIjoxNjg3ODQzNTM5fQ.5V86CNEz3a_OEzpCMaAF4b5bW-vhigMqUi7a_LIugBQ';

  const usertoken = localStorage.getItem('user_token')

  // const usertoken = localStorage.getItem('user_token')

  const handleCourse = (courseId) => {
    localStorage.setItem('courseId', courseId)
  }


  
  let [courseresponseData, setcourseResponseData] = React.useState('')
  let [courseerrorData, setcourseErrorData] = React.useState('')

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const instance = axios.create({
          baseURL: 'https://backend.navyaedu.com/',
          headers: {
            Authorization: 'Bearer ' + usertoken,
          },
        })

        const response = await instance.get(
          '/api/v1/courses/single-series/' + localStorage.getItem('seriesId'),
        )
        console.log(response.data)
        setcourseResponseData(response.data.series)
      } catch (error) {
        setcourseErrorData(error)
      }
    }

    fetchData()
  }, [usertoken])



  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)

      try {
        const instance = axios.create({
          baseURL: 'https://backend.navyaedu.com/',
          headers: {
            Authorization: 'Bearer ' + usertoken,
          },
        })

        const response = await instance.get(
          '/api/v1/courses/plan/' + localStorage.getItem('seriesId'),
        )
        console.log(response.data.plans)
        setResponseData(response.data.plans)
        setSpinner(false)
      } catch (error) {
        setSpinner(false)
        setErrorData(error)
      }
    }

    fetchData()
  }, [usertoken, courseId])

  // const usertoken = localStorage.getItem("user_token");


  let [purchasedresponseData, setpurchasedResponseData] = React.useState('')
  let [purchaseerrorData, setpurchaseErrorData] = React.useState('')

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)

      try {
        const instance = axios.create({
          baseURL: 'https://backend.navyaedu.com/',
          headers: {
            Authorization: 'Bearer ' + usertoken,
          },
        })

        const response = await instance.get(
          '/api/v1/courses/transaction/my-plans',
        )
        console.log(response.data.plans)
        setpurchasedResponseData(response.data.plans)
        setSpinner(false)
      } catch (error) {
        setSpinner(false)
        setpurchaseErrorData(error)
      }
    }

    fetchData()
  }, [usertoken])


  const extractCommonIds = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
      return []; // Return an empty array if either of the inputs is not an array
    }

    const commonIds = arr1.reduce((result, item1) => {
      if (arr2.some((item2) => item1.plan_purchased._id === item2._id)) {
        result.push(item1.plan_purchased._id);
      }
      return result;
    }, []);

  
    return commonIds;
  };
  
  const commonIds = extractCommonIds(purchasedresponseData, responseData);
  
  console.log(commonIds);

  // console.log(purchasedresponseData);
  // console.log(responseData);


  
  const [isCheckboxChecked, setIsCheckboxChecked] = React.useState(false);

  const handleCheckboxChange = (index, isChecked) => {
    setIsCheckboxChecked((prev) => ({
      ...prev,
      [index]: isChecked,
    }));
  };


  
  const apiKey = 'rzp_test_O357DWuaY3ijq9'
  const apiSecret = 'VcyUeVU6NjuiqgqFNh6cIin8'

  const Razorpay = useRazorpay()
  const handlePayment = useCallback(


   
    async (price, planId ,isCheckboxChecked) => {

      if (!isCheckboxChecked) {
        toast.error("Please agree to the Terms and Conditions.");
        return;
      }

      if (commonIds.length > 0) {
        toast.error("You Have Alreay Bought One plan from This Series.");
        return;
      }

      try {

        const options = {
          key: 'rzp_test_O357DWuaY3ijq9',
          amount: parseInt(price * 100),
          currency: 'INR',
          name: 'Navya',
          description: 'Test Transaction',
          image:
            'http://navya.sunitroy.com/static/media/logo.0afc91c322837a8a27f4.png',
          // order_id: data.id,
          // order_id: "QWERTY123456edfvrtg",
          handler: (res) => {
            console.log(res)

            const dataToStore = {
              transaction_id: res.razorpay_payment_id,
              // orderId: res.razorpay_order_id,
              secret_key: 'New Course',
              // Include any other relevant data
            }

            axios
              .create({
                baseURL: 'https://backend.navyaedu.com/',
                headers: {
                  'content-type': 'application/json',
                  Authorization: 'Bearer ' + usertoken,
                },
              })
              .post(
                '/api/v1/courses/transaction/buy-plan/' + planId,
                dataToStore,
              )
              .then((response) => {
                console.log(response)

                toast.success(response.data.msg)
                setTimeout(() => {
                  navigate('/myorder') // Navigate to other page after 2 seconds
                }, 2000)
              })
              .catch((error) => {
                console.log(error)
                setSpinner(false)

                if (error) {
                  toast.error(error.response.data.msg)
                }
              })
          },
          prefill: {
            name: 'Piyush Garg',
            email: 'youremail@example.com',
            contact: '9999999999',
          },
          notes: {
            address: 'Razorpay Corporate Office',
          },
          theme: {
            color: '#3399cc',
          },
        }

        const rzpay = new Razorpay(options)
        rzpay.open()
      } catch (error) {
        console.error(error)
        // Handle any error that occurred during the process
      }
    },
    [Razorpay],
  )



  
let [prfresponseData, setprfResponseData] = React.useState('')
let [prferrorData, setprfErrorData] = React.useState('')


const instance = axios.create({
  baseURL: 'https://backend.navyaedu.com/',
  headers: {      
    'Authorization': 'Bearer ' + usertoken,
  },
});

const runLikeApi = (plan_id) => {
  // event.preventDefault()
  setSpinner(true)
  // https://backend.navyaedu.com/api/v1/auth/profile/update-profile
  instance
    .post('/api/v1/courses/transaction/my-cart/'+plan_id)
    .then((response) => {
      setSpinner(false)
      
      setprfResponseData(response.data)
        console.log(response.data)
        toast.success(response.data.msg);
      })
    .catch((error) => {
      console.log(error)

      setSpinner(false)

      if (error) {
        setprfErrorData(error.response)
        toast.error(error.response.data.msg)
      }
    })
}





  return (
    <div>

<ToastContainer />

      <LoginNavbar></LoginNavbar>

      {Array.isArray(responseData) ? (
        responseData.map((series , index) => (
          <section className="courses_confirmation_card_main_section ">
            <div className="md:p-5  ">
              <div className="my-0 px-10 py-3 bg-[#b1b1b122] rounded-lg">
                <div className="flex md:justify-between justify-center md:items-end items-center flex-col md:flex-row">
                  <div className="basis-1/2">
                    <div className="pt-2">
                      <div className="flex">
                        <h1 className="font-bold text-2xl mr-5 series_font">
                          {courseresponseData.series_name}
                        </h1>
                        <img
                          src={require(`../../assets/images/MycourseTag/${1}.png`)}
                          alt=""
                          style={{ width: '35px', height: '35px' }}
                        />
                      </div>
                      <div className="flex flex-col md:flex-row series_font">
                        <p className="series_font">
                          {series.plan_original_price} /-
                        </p>
                        <p className="md:px-2 my-0 series_font">
                          4.8
                          <span>
                            <i className="fa fa-star text-[orange] "></i> (878
                            ratings)
                          </span>
                        </p>
                      </div>
                      <p className="font-semibold md:py-2 my-0 py-0 series_font">
                        Plan Duration: {series.plan_duration} hrs
                      </p>
                    </div>
                    <div>
                      <p className="font-semibold pt-2 my-0 series_font">
                        {series.plan_description}
                      </p>
                    </div>
                    <div>
                      <div className="py-1 font-normal text-[#7D7D7D]">
                        <p className="d-flex my-0 items-center">
                          <span className="px-2">
                            <BsArrowRight className="font-light text-[grey]" />
                          </span>
                          <span>
                            At a Discount Price of :{' '}
                            {series.plan_discounted_price} /-
                          </span>
                        </p>
                      </div>
                      <div className="py-1 font-normal text-[#7D7D7D]">
                        <p className="d-flex my-0 items-center ">
                          <span className="px-2">
                            <BsArrowRight className="font-light text-[grey]" />
                          </span>
                          <span className="series_font">
                            Tap on options to select the correct answer
                          </span>
                        </p>
                      </div>
                      <div className="py-1 font-normal text-[#7D7D7D]">
                        <p className="d-flex my-0 items-center">
                          <span className="px-2">
                            <BsArrowRight className="font-light text-[grey]" />
                          </span>
                          <span className="series_font">
                            Tap on the bookmark icon to save interesting
                            questions.
                          </span>
                        </p>
                      </div>
                      <div className="py-2 font-normal text-[#7D7D7D]">
                        <p className="d-flex my-0 items-center">
                          <span className="px-2">
                            <BsArrowRight className="font-light text-[grey]" />
                          </span>
                          <span className="series_font">
                            Click submit if you are sure you want to complete
                            all the quizzes
                          </span>
                        </p>
                      </div>
                      <div className="py-1 text-[.8rem] font-semibold text-[#7D7D7D]">
                        <p className="d-flex my-0 items-center">
                          <span className="series_font">
                            <input
                              type="checkbox" Required name={`tnc${index}`} 
                              className="mt-2 px-2 border border-[#66BE5E]"
                              checked={isCheckboxChecked[index] || false}
                              onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                            />
                          </span>
                          <span className="pl-2 series_font">
                            I Agree with Navya
                          </span>
                          <span className="text-[#66BE5E] ml-1 series_font">
                          <Link to="/terms-and-condition">Terms And Conditions
                          </Link>
                            </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex lg:flex-row md:flex-column flex-col md:basis-2/5 basis-full">
                    {/* <Link to="/mycourse" className="w-full bg-[#white] text-center text-[#775EA5] border border-[#775EA5] font-semibold rounded px-5 py-3 mb-3 lg:my-5 md:my-2 sm:my-3"> */}
                    <div className="w-full bg-[#white] text-center text-[#775EA5] border border-[#775EA5] font-semibold rounded px-5 py-3 mb-3 lg:my-5 md:my-2 sm:my-3 btn_hover_white">
                    {!commonIds.includes(series._id) ? (<button className="series_font" onClick={() => runLikeApi(series._id) }>Add to Cart</button>) : ( <button className="series_font">Already Purchased</button> )}
                    </div> 
                    {/* </Link> */}
                    {/* <Link to="/startTest" className="w-full bg-[#66BE5E]  text-center text-white border border-[#775EA5] font-semibold rounded px-5 lg:m-5 md:m-0 py-3 lg:my-5 md:my-0 sm:mt-3"> */}

                    {/* </Link> */}
                  {/* </div>
                  <div className="flex lg:flex-row md:flex-column flex-col md:basis-2/5 basis-full"> */}
                    {/* <Link to="/mycourse" className="w-full bg-[#white] text-center text-[#775EA5] border border-[#775EA5] font-semibold rounded px-5 py-3 mb-3 lg:my-5 md:my-2 sm:my-3"> */}

                    {!commonIds.includes(series._id) && (
                      <div className="w-full bg-[#66BE5E] text-center text-white border border-[#775EA5] font-semibold rounded px-5 lg:m-5 md:m-0 py-3 lg:my-5 md:my-0 sm:mt-3 btn_hover">
                        <button
                          className="series_font"
                          onClick={() =>
                            handlePayment(
                              series.plan_discounted_price,
                              series._id,
                              isCheckboxChecked[index]
                            )
                          }
                        >
                          Buy
                        </button>
                      </div>
                    )}

                    {/* </Link> */}
                    {/* <Link to="/startTest" className="w-full bg-[#66BE5E]  text-center text-white border border-[#775EA5] font-semibold rounded px-5 lg:m-5 md:m-0 py-3 lg:my-5 md:my-0 sm:mt-3"> */}

                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))
      ) : (
        <p>No Series available.</p>
      )}

      {/*                 
      <CourseConfirmationCard img={1}></CourseConfirmationCard> */}
      {/* <Footer></Footer> */}
    </div>
  )
}

export default CourseConfirmation
