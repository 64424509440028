import React from 'react'
import play_store from '../../assets/images/play_store.png'
import apple_store from '../../assets/images/apple_store.png'
import conatct_us from '../../assets/images/download-app.png'

function Advertise({mode}) {
  return (
    <>
      <section className="download_app_main_section">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="bg-[#f7f2ffd5] pr-3 ml-16 py-24 rounded-xl  w-full" style={{background:mode==="dark"&&"#151515",color:mode==="dark"&&"white" }}>
              <div className="w-full px-10">
                <h1 className="text-[2rem] font-bold">
                  Download our app for free now!
                </h1>
                <p 
                
                className="my-5  leading-9 text-lg">
                  Download our app now and embark on a transformative learning
                  journey with Navya®. Experience interactive content,
                  personalized assessments, and unlimited practice
                  opportunities, all designed to help you excel in your
                  educational goals. Trust Navya® to revolutionize your learning
                  experience and unlock your full potential today
                </p>
                <div className="flex justify-start items-center pt-3 flex_start_col">
                  <div className="shadow ">
                    <img
                      src={play_store}
                      alt=""
                      width={200}
                      className="download_app_now_btn_img"
                    />
                  </div>
                  <div className="mx-5 shadow ">
                    <img
                      src={apple_store}
                      alt=""
                      width={200}
                      className="download_app_now_btn_img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-1 col-md-12 col-sm-12"></div> */}
          <div className="col-lg-6 col-md-12 col-sm-12 download_app_img_side">
            <img src={conatct_us} alt="" className="img-fluid " />
          </div>
        </div>
      </section>
    </>
  )
}

export default Advertise
