import React, { Fragment, useEffect, useRef, useState } from "react";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineMessage, AiOutlineStar } from "react-icons/ai";
import {
  BsShieldCheck,
  BsInfoCircle,
  BsTicketPerforated,
} from "react-icons/bs";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BsCheckCircle, BsFillMoonFill, BsFillSunFill } from "react-icons/bs";
import { FaLongArrowAltRight } from "react-icons/fa";
import { HiOutlineLogout, HiOutlineDocumentReport } from "react-icons/hi";
import Slider from "@mui/material/Slider";
import { Dialog, Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import danger from "../../assets/images/Danger.png";
import Logout from "../../assets/images/Logout.png";

import axios from "axios";
import { Audio } from "react-loader-spinner";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactHtmlParser from "html-react-parser";
import { BsChevronDown } from "react-icons/bs";

import { userContext } from "../../Context/UserContextProvider";
import ReCaptcha from "../recaptcha/ReCaptcha";
import Capnotverify from "../recaptcha/Capnotverify";
import MenuDetails from "./MenuDetails";
import LogoutModel from "../modal/LogoutModel";
import UseAxios from "../../util/UseAxios";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const SettingMenu = () => {
  const { capVal, mode } = React.useContext(userContext);
  const usertoken = localStorage.getItem("user_token");

  // password change Success
  const [Successopen, setSuccessOpen] = useState(false);
  const SuccesshandleShow = (e) => {
    return e.preventDefault(), setSuccessOpen(true);
  };

  const SuccesshandleClose = (e) => {
    return e.preventDefault(), setSuccessOpen(false);
  };
  const cancelButtonRef = useRef(null);

  let [prfresponseData, setprfResponseData] = React.useState("");
  let [prferrorData, setprfErrorData] = React.useState("");
  let [oldpass, setOldpass] = React.useState("");
  let [newpass1, setNewpass1] = React.useState("");
  let [newpass2, setNewpass2] = React.useState("");

  const bodyref_json = {
    old_password: oldpass,
    new_password: newpass1,
  };

  const instance = UseAxios()

  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const [urlopen, setUrlOpen] = useState("termservice");
  console.log(urlopen);

  useEffect(() => {
    changeurl();
  }, [url]);

  function changeurl() {
    // Check if the 'page' parameter exists in the URL
    if (url.searchParams.has("page")) {
      setUrlOpen(url.searchParams.get("page"));
    } else {
      console.error("'page' parameter not found in the URL");
    }



    // if(urlopen === "termservice"){
    //   setValue(1)

    // }

    switch (urlopen) {
      case "changepassword":
        setValue(0)
        break;
      case "termservice":
        setValue(1)
        break;
      case "privacypolicy":
        setValue(2)
        break;
      case "raiseticket":
        setValue(3)
        break;
      case "appearance":
        setValue(4)
        break;
      case "dataminimiazation":
        setValue(5)
        break;
      case "faq":
        setValue(6)
        break;
      
      default:
      break;
    }
  }



  let [deacresponseData, setdeacResponseData] = React.useState("");
  let [deacerrorData, setdeacErrorData] = React.useState("");
  let [rsn, setRsn] = React.useState("");
  let [deactivedec, setDeactiveDec] = React.useState("");

  const bodydeac_json = {
    account_deactivation_reason: rsn,
    account_deactivation_description: deactivedec,
  };


  const [value, setValue] = React.useState(1);

  const [spinner, setSpinner] = React.useState(false);
  let [responseData, setResponseData] = React.useState("");
  let [errorData, setErrorData] = React.useState("");

  // const usertoken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDcxODEwYWJhMzc0OGZmZDc4ZjAwN2IiLCJyb2xlIjoidXNlciIsImlhdCI6MTY4NTI1MTUzOSwiZXhwIjoxNjg3ODQzNTM5fQ.5V86CNEz3a_OEzpCMaAF4b5bW-vhigMqUi7a_LIugBQ';

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true);

      try {
        const response = await instance.get("/api/v1/app/privacy-policy");
        console.log(response.data);
        // setResponseData(response.data.privacy_policy)

        setResponseData({
          ...response.data.privacy_policy,
          description: response.data.privacy_policy.description.replaceAll(
            "&lt;",
            "<"
          ),
        });

        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setErrorData(error);
      }
    };

    fetchData();
  }, [usertoken]);

  let [faqresponseData, setFaqresponseData] = React.useState([]);
  let [faqerrorData, setFaqerrorData] = React.useState("");

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true);

      try {
        const response = await instance.get("/api/v1/app/faq");
        // const data = await response.data
        console.log(response.data);
        console.log("faqCategory");
        setFaqresponseData(response.data);
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setFaqerrorData(error);
      }
    };

    fetchData();
  }, [usertoken]);

  let [tresponseData, setTresponseData] = React.useState("");
  let [terrorData, setTerrorData] = React.useState("");

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true);

      try {
    

        const response = await instance.get("/api/v1/app/terms-and-condition");
        console.log(response.data);

        setTresponseData({
          ...response.data.terms_and_condition,
          description: response.data.terms_and_condition.description.replaceAll(
            "&lt;",
            "<"
          ),
        });

        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setTerrorData(error);
      }
    };

    fetchData();
  }, [usertoken]);

  const handleChange = (event, newValue) => {
    console.log(newValue)
    setValue(newValue);
  };

  //   password show hide
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showPasswords, setShowPasswords] = React.useState(false);

  const handleClickShowPasswords = () => setShowPasswords((show) => !show);

  const handleMouseDownPasswords = (event) => {
    event.preventDefault();
  };

  const [showPasswordss, setShowPasswordss] = React.useState(false);

  const handleClickShowPasswordss = () => setShowPasswordss((show) => !show);

  const handleMouseDownPasswordss = (event) => {
    event.preventDefault();
  };

  // Successopen
  const [deactivateopen, setdeactivateOpen] = useState(false);
  const deactivatehandleShow = (e) => {
    return e.preventDefault(), setdeactivateOpen(true);
  };
  const deactivatehandleClose = (e) => {
    return e.preventDefault(), setdeactivateOpen(false);
  };

  // logout
  const [Logouteopen, setLogouteOpen] = useState(false);
  const LogouthandleShow = (e) => {
    return e.preventDefault(), setLogouteOpen(true);
  };
  const LogouthandleClosedeactivate = (e) => {
    return e.preventDefault(), setLogouteOpen(false);
  };

  // logout
  const [Ticketopen, setTicketOpen] = useState(false);
  const TickethandleShow = (e) => {
    handleClose();
    return e.preventDefault(), setTicketOpen(true);
  };
  const TickethandleClose = (e) => {
    return e.preventDefault(), setTicketOpen(false);
  };


  const setting_tabs_main = {
    minWidth: "250px",
    alignItems: "flexStart",
  };
  //  Raise Your Ticket value send api to admin
  const [issue_type, setTicketissue] = useState();
  const [description, setDescription] = useState();
  const Raise_Ticket_data = { issue_type, description };
  function handlechangeTR(e, flag) {
    let inpvalue = e.target.value;
    if (flag === "issue") {
      setTicketissue(inpvalue);
    }
    if (flag === "description") {
      setDescription(inpvalue);
    }
  }

  const [ratresponse, setRaTresponse] = React.useState("");

  const TicketRase = async (event) => {
    event.preventDefault();
    let baseURL = "https://backend.navyaedu.com/";
    setSpinner(true);
    try {
      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + usertoken,
        },
        body: JSON.stringify(Raise_Ticket_data),
      };

      const response = await fetch(
        `${baseURL}/api/v1/auth/raise-ticket`,
        config
      );

      console.log(response);
      if (response.ok) {
        const data = await response.json();
        setRaTresponse(data.msg);
        // toast.success('Message Successfully Sent!');
        setOpenRaiseTicket(true);
        // setTicketOpen(false);
        setTicketissue("");
        setDescription("");
      } else {
        toast.error(`ERROR : ${response.status}  ${response.statusText}`);
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    }

    setTimeout(() => {
      setSpinner(false);
    }, 2000);
  };

  // dark mode functionly autor:Nitesh
  const [isDarkMode, setIsDarkMode] = useState(false);

  React.useEffect(() => {
    const body = document.body;
    if (isDarkMode) {
      body.classList.add("dark-mode");
    } else {
      body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  function Mode(step) {
    console.log(step.target.value);

    if (step.target.value === 0) {
      setIsDarkMode(true);
    }
    if (step.target.value === 100) {
      setIsDarkMode(false);
    }
  }

  const [OpenRaiseTicket, setOpenRaiseTicket] = React.useState(false);

  const handleClose = () => {
    setOpenRaiseTicket(false);
    setTicketOpen(false);
  };
  function genrateUrl(index) {
    switch (index) {
      case 0:
        navigate("/setting?page=changepassword");
        break;
      case 1:
        navigate("/setting?page=termservice");
        break;
      case 2:
        navigate("/setting?page=privacypolicy");
        break;
      case 3:
        navigate("/setting?page=raiseticket");
        break;
      case 4:
        navigate("/setting?page=appearance");
        break;
      case 5:
        navigate("/setting?page=dataminimiazation");
        break;
      case 6:
        navigate("/setting?page=faq");
        break;
      default:
        navigate("/setting");
    }
  }

  return (
    <>
      <div className="container">
        <section className="flex items-baseline">
          {/* ==================== left tabs start==================== */}

          <div
            className="w-[20%] py-10 setting_tabs"
            style={{
              height: "100vh",
              scrollBehavior: "smooth",
              overflow: "scroll",
            }}
          >
            <h2  style={{color:mode==="dark"&&"white"}} className="setting_heading">Settings</h2>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: mode === "dark" && "black",
                display: "flex",
              }}
            >
              <Tabs
                style={setting_tabs_main}
                orientation="vertical"
                //  orientation={smallScreen ? "vertical" : "horizonal"}
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}
              >
                {usertoken && <Tab
                  sx={{ color: mode === "dark" && "white" }}
                  icon={<RiLockPasswordLine className="icons" />}
                  label="Change Password"
                  iconPosition="start"
                  value={0}
                  onClick={() => genrateUrl(0)}
                />}
                <Tab
                  sx={{ color: mode === "dark" && "white" }}
                  icon={<AiOutlineMessage className="icons" />}
                  label="Terms of Service"
                  iconPosition="start"
                  value={1}
                  onClick={() => genrateUrl(1)}
                />
                <Tab
                  sx={{ color: mode === "dark" && "white" }}
                  icon={<BsShieldCheck className="icons" />}
                  label="Privacy Policy"
                  iconPosition="start"
                  value={2}
                  onClick={() => genrateUrl(2)}
                />
                {usertoken && <Tab
                  sx={{ color: mode === "dark" && "white" }}
                  icon={<BsTicketPerforated className="icons" />}
                  label="raise a ticket"
                  iconPosition="start"
                  value={3}
                  onClick={() => genrateUrl(3)}
                />}
                {/* <div className="raise_ticket">
                                    <h3 
                                    // onClick={TickethandleShow}
                                    onClick={()=>genrateUrl(3)}
                                    >
                                        <BsTicketPerforated className="icons" /> raise a ticket
                                    </h3>
                                </div> */}
                <Tab
                sx={{color: mode==="dark"&&"white"}}
                  icon={<AiOutlineStar className="icons" />}
                  label="Appearance"
                  iconPosition="start"
                  value={4}
                  onClick={() => genrateUrl(4)}
                />
                {usertoken && <Tab
                sx={{color: mode==="dark"&&"white"}}
                  icon={<MdOutlineDeleteOutline className="icons" />}
                  label="Data Minimiazation"
                  iconPosition="start"
                  value={5}
                  onClick={() => genrateUrl(5)}
                />}
                <Tab
                sx={{color: mode==="dark"&&"white"}}
                  icon={<BsInfoCircle className="icons" />}
                  label="FAQ'S"
                  iconPosition="start"
                  value={6}
                  onClick={() => genrateUrl(6)}
                />
                {usertoken && <Tab
                sx={{color: mode==="dark"&&"white"}}
                  icon={<HiOutlineDocumentReport className="icons" />}
                  label="Progress Report"
                  iconPosition="start"
                  value={7}
                  href="/test-report"
                  target="blank"
                />}
                {/* <Tab
                sx={{color: mode==="dark"&&"white"}}
                  icon={<LiaFileInvoiceDollarSolid className="icons" />}
                  label="Payment Invoice"
                  iconPosition="start"
                  // {...a11yProps(6)}
                  href="/invoice"
                  target="blank"
                /> */}
                {/* <Tab
          icon={<HiOutlineLogout className="icons" />}
          label="logout"
          iconPosition="start"
         className='logout_tab'
          onClick={LogouthandleShow}
        /> */}

                {usertoken && <div className="raise_ticket logout_tab">
                  <h3 onClick={LogouthandleShow} style={{ color: "#FF5F5F" }}>
                    
                    <HiOutlineLogout
                      className="icons"
                      style={{ color: "#FF5F5F" }}
                    />
                    logout
                  </h3>
                </div>}

                {/* <button onClick={LogouthandleShow}> <HiOutlineLogout className="icons" /> Logout</button> */}
              </Tabs>
            </Box>
          </div>

          {/* ==================== left tabs end==================== */}

          {/* ==================== right tabs start==================== */}

          <MenuDetails mode={mode} />

          {/* ==================== right tabs end==================== */}
        </section>
      </div>
      {Logouteopen && <LogoutModel open={Logouteopen}  setOpen={setLogouteOpen}/> }
    </>
  );
};

export default SettingMenu;
