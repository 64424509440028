import React from 'react'
import { Link } from 'react-router-dom';

function NoSigningfooter() {
  return (
    <div className='pb-5 mt-14 text-center'>
                    <p className=''><span>By Logging In, I Agree  With Navya <Link to={"/setting?page=privacypolicy"}><span className='text-[#38a32e] cursor-pointer'>Privacy Policy</span></Link></span></p>
                    <p className=''><span>And<Link to={"/setting?page=termservice"}><span className='text-[#38a32e] cursor-pointer'>Terms And Conditions</span></Link></span></p>
                </div>
  )
}

export default NoSigningfooter;