import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import LoginNavbar from "../components/LoginNavbar/LoginNavbar";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import parse from "html-react-parser";
import { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { userContext } from "../Context/UserContextProvider";
import ScholarshipTestPage from './ScholarshipTestpage'
import UseAxios from '../util/UseAxios'

function MyQuizSection(props) {
  const {ScholarshipID} = useParams("ScholarshipID")
  const {mode,profiledata}=React.useContext(userContext)
  const usertoken = localStorage.getItem("user_token");
  const [spinner, setSpinner] = React.useState(false);
  const params = new URLSearchParams(window.location.search);
  const [quiz, setQuiz] = React.useState(ScholarshipID);
  let [quizresponseData, setquizResponseData] = React.useState({});
  let [quizerrorData, setquizErrorData] = React.useState("");

  const instance = UseAxios()

  const [quizdetails, setQuizDetails] = useState([]);
  useEffect(() => {
    
    const fetchQuizdetails = async () => {
      // setSpinner(true);

      try {
        const response = await instance.get(
          "/api/v1/scholarship/quiz/" + quiz
        );
        let resdata = response.data.quizzes;
        console.log(resdata);
        setQuizDetails(resdata);
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setquizErrorData(error);
      }
    };
    fetchQuizdetails();
  }, [quizresponseData]);

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true);

      try {
        const response = await instance.get(
          "/api/v1/scholarship/single-quiz/" + quiz
        );
        console.log(response)
        const data = response.data.quiz
        const convertedData = {
          ...data,
          quiz_description: data.quiz_description.replace(/&lt;/g, '<'),
          quiz_name: data.quiz_name.replace(/&lt;/g, '<'),
          quiz_terms_and_condition: data.quiz_terms_and_condition.replace(/&lt;/g, '<'),
          // series_name: data.of_course_series.series_name.replace(/&lt;/g, '<'),
          // of_course_series: {
          //   ...data.of_course_series,
          //   series_name: data.of_course_series.series_name.replace(/&lt;/g, '<')
          // },

          of_plan: {
            ...data.of_plan,
            of_course_series: {
                ...data.of_plan.of_course_series,
                series_name: data.of_plan.of_course_series.series_name.replace(/&lt;/g, '<')
            }
        }
        };
        console.log(convertedData);
        setquizResponseData(convertedData);
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setquizErrorData(error);
        console.log(error)
      }
    };

    fetchData();
  }, [usertoken]);

  const handleCourse = (courseId) => {
    localStorage.setItem("SectionId", courseId);
  };

  const [isCheckboxChecked, setIsCheckboxChecked] = React.useState(false);
  const [Visit, setVisit] = React.useState([]);
  const navigate = useNavigate();

  const sendstatus = {
    is_quiz_live: true,
  };
  const sendquizstatus = async () => {
    try {
      const response = await instance.patch(
        `/api/v1/quizzes/quiz/quiz-attempt-details/${quiz}`,
        sendstatus
      );
      console.log(response.data);
      toast.success(response.data.message);
      // Navigate to the startTest page only if the checkbox is checked
      navigate("/startTest?quiz_id=" + quizresponseData._id);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const fetchData = async () => {
    console.log(quizdetails.is_quiz_live)
    if(quizdetails.is_quiz_live === true){
      toast.error("Test Already In Progress");
      return;
    }

    try {
      const response = await instance.get("/api/v1/quizzes/section/" + quiz);
      console.log(response.data.sections);
      sendquizstatus();
      setVisit(response.data.sections.quiz_section_questions);
    } catch (error) {
      console.log(error);
    }
  };

  const CheckExamStatus = (checkId,attemp) => {
    let {attempt_count} = quizdetails
    let max_attemp=quizdetails?.of_quiz?.max_attempts
    if(profiledata.phone_no_verified === false){
      toast.error("First Verify Your Mobile Number");
      return false;
    }
    if (isCheckboxChecked !== checkId) {
      toast.error("Please agree to the Terms and Conditions.");
      return;
    }
    console.log(Visit);
    // if (attempt_count < max_attemp) {
    //   fetchData();
    // } else {
    //   toast.error("No More Attempts Left!");
    // }
    navigate(`/scholarship/${ScholarshipID}/${checkId}`)
    
    localStorage.removeItem("queData");
  };

  let [isOpen, setIsOpen] = useState(false);
  let [shoTAC, setShoweTAC] = useState('');

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(flag) {
    console.log("clicked");
    setIsOpen(true);
    if(!isOpen){
      setShoweTAC(flag);
    }
  }
  return (
    <>
      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}

      <ToastContainer />

      <LoginNavbar></LoginNavbar>

      <section className="courses_confirmation_card_main_section h-[89.5vh]">
        <div className="md:p-12 p-5 ">
          {quizdetails.map((x, indx) => {
            return (
              <div className="my-0 p-10 bg-[#b1b1b122] rounded-lg mt-2">
                <div className="flex md:justify-between justify-center md:items-end items-center flex-col md:flex-row">
                  <div className="basis-1/2">
                    <div className="py-2">
                      <div className="flex">
                        <h1 className="font-bold text-2xl mr-5 series_font">
                          {parse(`${x.scholarship_quiz_name}`)}
                        </h1>
                        {/* <img
                          src={require(`../../assets/images/MycourseTag/${1}.png`)}
                          alt=""
                          style={{ width: "35px", height: "35px" }}
                        /> */}
                      </div>
                      <div className="flex flex-col md:flex-row series_font">
                        <p className="series_font">
                          Break Between sections : {x.break_between_sections}{" "}
                          Min
                        </p>
                        {/* <p className="md:px-2 series_font">
                          <span>
                            ({quizresponseData.quiz_sections?.length} Sections)
                          </span>
                        </p> */}
                      </div>
                      <p className="font-semibold md:py-2 series_font">
                        Plan Duration: {x.scholarship_quiz_duration} Min
                      </p>
                      {/* <div className="font-light text-[grey]">
                        <p style={{ color: mode === "dark" && "white" }}>
                          Max Attempts: {x?.max_attempts}
                        </p>
                        <p style={{color:mode==="dark"&&"white"}}>Your Attempts: {quizdetails?.attempt_count}</p>
                      </div> */}
                    </div>
                    <div>
                      <div className="py-2 font-normal text-[#7D7D7D]">
                        <p
                          className="d-flex items-center"
                          style={{ color: mode === "dark" && "white" }}
                        >
                          <span className="px-2">
                            <BsArrowRight className="font-light text-[grey]" />
                          </span>

                          {/* <p>{parse(`${bannerData[0]?.content1.replaceAll('&lt;', '<')}`)}</p> */}

                          <span style={{ color: mode === "dark" && "white" }}>
                            {parse(`${x?.scholarship_quiz_description}`)}
                          </span>
                        </p>
                      </div>
                      {/* <div className="py-2 font-normal text-[#7D7D7D]">
                        <p className="d-flex items-center ">
                          <span className="px-2">
                            <BsArrowRight className="font-light text-[grey]" />
                          </span>
                          <span className='series_font'></span>
                        </p>
                      </div>
                      <div className="py-2 font-normal text-[#7D7D7D]">
                        <p className="d-flex items-center">
                          <span className="px-2">
                            <BsArrowRight className="font-light text-[grey]" />
                          </span>
                          <span className='series_font'>
                            Tap on the bookmark icon to save interesting questions.
                          </span>
                        </p>
                      </div>
                      <div className="py-2 font-normal text-[#7D7D7D]">
                        <p className="d-flex items-center">
                          <span className="px-2">
                            <BsArrowRight className="font-light text-[grey]" />
                          </span>
                          <span className='series_font'>
                            Click submit if you are sure you want to complete all the
                            quizzes
                          </span>
                        </p>
                      </div> */}
                      <div className="py-2 text-[.8rem] font-semibold text-[#7D7D7D]">
                        <p
                          className="d-flex items-center"
                          style={{ color: mode === "dark" && "white" }}
                        >
                          <span className="series_font">
                            <input
                              type="checkbox"
                              Required
                              name="tnc"
                              checked={isCheckboxChecked === x._id}
                              onChange={(e) =>
                                setIsCheckboxChecked(
                                  isCheckboxChecked === x._id ? null : x._id
                                )
                              }
                            />
                          </span>
                          <span className="pl-2 series_font">
                            I Agree with Navya
                          </span>

                          <div onClick={()=>openModal(x.scholarship_quiz_terms_and_condition)}>
                            <span className="text-[#66BE5E] ml-1 series_font cursor-pointer">
                              Terms And Conditions
                            </span>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex lg:flex-row md:flex-column flex-col md:basis-2/5 basis-full">
                    <Link
                      to={"/scholarship"}
                      className="w-full bg-[#white] text-center text-[#775EA5] border border-[#775EA5] font-semibold rounded px-5 py-3 mb-3 lg:my-5 md:my-2 sm:my-3 btn_hover_white"
                    >
                      {/* <div className='bg-[#66BE5E] text-center text-white font-semibold rounded p-2 my-5'> */}
                      <button className="series_font">Previous</button>
                      {/* </div>                */}
                    </Link>

                    <div
                      className="w-full bg-[#66BE5E]  text-center text-white border border-[#775EA5] font-semibold rounded px-5 lg:m-5 md:m-0 py-3 lg:my-5 md:my-0 sm:mt-3 btn_hover "
                      onClick={() => CheckExamStatus(x._id, x.max_attemps)}
                    >
                      <button className="series_font">Start Test</button>
                    </div>

                    {/* <p>{quizresponseData.attempt_count}</p>
                    <p>{quizresponseData.max_attempts}</p> */}

                    {/* <Link
                      // to={'/startTest?quiz_id=' + quizresponseData._id}
    
                      onClick={() =>
                        CheckExamStatus(
                          quizresponseData._id
                        )
                      }
                      className="w-full bg-[#66BE5E]  text-center text-white border border-[#775EA5] font-semibold rounded px-5 lg:m-5 md:m-0 py-3 lg:my-5 md:my-0 sm:mt-3 btn_hover"
                    >
                      Start Quiz
                    </Link> */}
                  </div>

                  {/*           
                    <div className="flex lg:flex-row md:flex-column flex-col md:basis-2/5 basis-full">
                        <Link to={"/My-Quiz-Section?quiz_id="+quiz._id} className="w-full bg-[#white] text-center text-[#775EA5] border border-[#775EA5] font-semibold rounded px-5 py-3 mb-3 lg:my-5 md:my-2 sm:my-3">
                          <div className='bg-[#66BE5E] text-center text-white font-semibold rounded p-2 my-5'>
                                      <button className='series_font'>Start Quiz</button>
                          </div>               
                        </Link>
                    </div> */}
                </div>
              </div>
            );
          })}

          <MyModal
            mode={mode}
            content={shoTAC}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            openModal={openModal}
            closeModal={closeModal}
          ></MyModal>
        </div>
      </section>
    </>
  );
}

export default MyQuizSection;

export function MyModal({ isOpen, setIsOpen, openModal, closeModal, content,mode }) {
  return (
    <>
      {/* <div className="fixed inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          className="rounded-md bg-black/20 px-4 py-2 text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
        >
          Open dialog
        </button>
      </div> */}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel  style={{color:mode==="dark"&&"white", background:mode==="dark"?"black":"white"}} className="w-[60vw] transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                  style={{color:mode==="dark"&&"white"}}
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Terms & Conditions
                  </Dialog.Title>
                  <div className="mt-2">
                    <p 
                    style={{color:mode==="dark"&&"white"}}
                    className="text-sm text-gray-500">{parse(`${content}`)}</p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
