import React, { useEffect, useState } from 'react'
// import Footer from '../Footer/Footer'
import LoginNavbar from '../LoginNavbar/LoginNavbar'
import CourseConfirmationCard from './CourseConfirmationCard'
import { Link } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import { useCallback } from 'react'
import useRazorpay from 'react-razorpay'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import parse from 'html-react-parser';
import UseAxios from '../../util/UseAxios'
function PurchasedSeries(props) {
  const [spinner, setSpinner] = React.useState(false)
  let [responseData, setResponseData] = React.useState([])
  let [errorData, setErrorData] = React.useState('')

  const params = new URLSearchParams(window.location.search)
  const [plan, setPlan] = React.useState(params.get('plan_id'))

  const usertoken = localStorage.getItem('user_token')

  const instance = UseAxios()

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)

      try {
        const response = await instance.get('/api/v1/quizzes/quiz/' + plan) // series id change plan id 
        
        const data =response.data.quizzes
        console.log(data)
        const convertedData = data.map(item => ({
          ...item,
          quiz_description: item.quiz_description.replace(/&lt;/g, '<'),
          quiz_name: item.quiz_name.replace(/&lt;/g, '<'),
          quiz_terms_and_condition: item.quiz_terms_and_condition.replace(/&lt;/g, '<')
      }));
     console.log(convertedData)
      setResponseData(convertedData)
        setSpinner(false)
      } catch (error) {
        setSpinner(false)
        setErrorData(error)
      }
    }

    fetchData()
  }, [usertoken, plan])


 

  return (
    <div> 
      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}
      <LoginNavbar></LoginNavbar>

      <div  className="mycourse_card_main h-[100vh] overflow-scroll" >
        <div className="px-12">
          {
          // Array.isArray(responseData)
          responseData.length ? (
            responseData.map((quiz) => (
              <>
                <div className="my-2 px-10 pt-2 mt-3 bg-[#b1b1b122] rounded-lg">
                  <div className="flex justify-between items-start  flex-col lg:flex-row md:flex-column">
                    <div className='My_purchased_series_Details d-flex justify-between items-center w-100'>
                      <div className="pt-2">
                        <div className="flex">
                          <div className="font-bold text-2xl mr-5 series_font">
                            {parse(`${quiz.of_plan.of_course_series.series_name?.replaceAll('&lt;', '<')}`)}
                          </div>
                          {/* <img
                            src={require(`../../assets/images/MycourseTag/${1}.png`)}
                            alt=""
                            style={{ width: '35px', height: '35px' }}
                          /> */}
                        </div>
                        <div className="flex ">
                          <p className="series_font">GET 100 Points</p>
                          <p className="px-2 series_font">
                            4.8{' '}
                            <span>
                              <i className="fa fa-star text-[orange]"></i> (878
                              ratings)
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="series_font d-flex w-[70%] justify-between">
                        <div className="py-1 font-normal ">
                          <p className="d-flex items-center mb-0">
                            {/* <span className="px-2">
                              {' '}
                              <BsArrowRight className="font-light text-[grey]" />{' '}
                            </span> */}
                            <div className='My_Purchased_series_titel'>
                                <span className='text-[#7D7D7D]'>Test Title</span>
                                <p className='font-bold mb-0'>{parse(`${quiz.quiz_name}`)}</p>
                            </div>
                          </p>
                        </div>
                        <div className="py-1 font-normal ">
                          <p className="d-flex items-center mb-0">
                            {/* <span className="px-2">
                              {' '}
                              <BsArrowRight className="font-light text-[grey]" />{' '}
                            </span> */}
                            <div className='My_Purchased_series_titel'>
                            <span className='text-[#7D7D7D]'>
                              Test Duration
                            </span>
                            <p className='font-bold mb-0'>{quiz.quiz_duration} Min</p>
                            </div>
                          </p>
                        </div>
                        <div className="py-1 font-normal ">
                          <p className="d-flex items-center mb-0">
                            {/* <span className="px-2">
                              {' '}
                              <BsArrowRight className="font-light text-[grey]" />{' '}
                            </span> */}
                            <div className='My_Purchased_series_titel'>
                            <span className='text-[#7D7D7D]'>
                            Break Between Sections :{' '}
                            </span>
                            <p className='font-bold mb-0'>{quiz.break_between_sections} Min</p>
                            </div>
                          </p>
                        </div>
                        <Link
                        to={'/My-Quiz-Section?quiz_id=' + quiz._id}
                        className=""
                      >
                        {/* {quiz?.quiz_attempt_details[0]?.attempt_count > 0  */}
                        {quiz?.quiz_attempt_details[0]?.hasTakenTest === true 
                        ?
                        <div className="bg-[#9b9e9b] text-center text-white font-semibold rounded p-2 my-1 btn_hover">
                          <button className="series_font">Test Taken</button>
                        </div>
                       
                        :
                        
                        <div className="bg-[#66BE5E] text-center text-white font-semibold rounded p-2 my-1 btn_hover">
                        <button className="series_font">Start Test</button>
                      </div>
                        }
                        {/* <div className="bg-[#66BE5E] text-center text-white font-semibold rounded p-2 my-1 btn_hover">
                          <button className="series_font">{quiz?.quiz_attempt_details[0].attempt_count > 0 ?"Test Taken" :"Start Test"}</button>
                        </div> */}
                      </Link>
                       
                      </div>
                    
                    </div>
                    
                  </div>
                </div>
              </>
            ))
          ) : (
           <div className='h-[89.5vh]'>
           <p className='capitalize'>No Test available.</p>
           </div>
          )}
        </div>
      </div>

      {/*  <PurchasedSeriesCard img={1}></PurchasedSeriesCard> */}
      {/* <Footer></Footer> */}
    </div>
  )
}

export default PurchasedSeries
