import React, { useState } from "react";
import Navbar from "../Header/Navbar";
import SimiliarCourses from "../SmiliarCourses/SimiliarCourses";
import { Audio } from "react-loader-spinner";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Footer/Footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import Feedback from "./Feedback";
import { userContext } from "../../Context/UserContextProvider";
import ReactHtmlParser from "html-react-parser";
import UseAxios from "../../util/UseAxios";
import Cookies from "js-cookie";
import MetaData from "../MetaTitle/MetaData";

function ScrollToTopOnRouteChange() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function CourseDetails() {
  const [spinner, setSpinner] = React.useState(false);
  let [responseData, setResponseData] = React.useState("");
  let [responsetitle, setResponsetitle] = React.useState("");
  let [responsename, setResponsename] = React.useState("");
  let [errorData, setErrorData] = React.useState("");
  let [classData, setclassData] = React.useState("");
  let [cat_course_id, setCat_course_id] = React.useState("");
  // let [htmlcontent, setHtmlcontent] = React.useState('')
  const {
    
    callfunction,
    cart_added,
    wislistcourse,
    wislist_added,
    cartitemslength,
    mode
  } = React.useContext(userContext);

  console.log(responsename);

  const parems = useParams();
  const { course_id } = parems; //author:Nitesh
  // const params = new URLSearchParams(window.location.search)
  // console.log(params.get('course_id'));
  // const courseIdParam = params.get('course_id');
  // const [course, setCourse] = React.useState(params.get('course_id'))
  const location = useLocation();
  const currentUrl = location.pathname;
  const usertoken = localStorage.getItem("user_token");

  const instance =UseAxios()

  console.log(currentUrl);
  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true);

      try {
        const response = await instance.get(
          "/api/v1/courses/single-course/" + course_id
        );
        console.log(response.data.course);
        setResponseData({
          ...response.data.course,
          course_description:
            response.data.course.course_description.replaceAll("&lt;", "<"),
        });
        setResponsetitle({
          ...response.data.course,
          course_title: response.data.course.course_title.replaceAll(
            "&lt;",
            "<"
          ),
        });
        setResponsename({
          ...response.data.course.of_course_category,
          category_name:
            response.data.course.of_course_category?.category_name.replaceAll(
              "&lt;",
              "<"
            ),
        });

        const cat_course_id = await response.data.course.of_course_category._id;
        console.log(cat_course_id);
        setCat_course_id(cat_course_id);
        // setHtmlcontent( {...response.data.course,
        //   course_description:response.data.course.course_description.replaceAll('&lt;', '<')})
        if (response.data.course.is_wishlisted === true) {
          setclassData(
            "fa fa-heart text-[red] p-2 rounded-full bg-white text-[#775EA5] border border-[#775EA5] hover_heart_red"
          );
        } else {
          setclassData(
            "fa fa-heart-o p-2 rounded-full bg-white text-[#775EA5] border border-[#775EA5] hover_heart_white"
          );
        }

        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setErrorData(error);
      }
    };

    fetchData();
  }, [usertoken, currentUrl, course_id]);

  const handleCourse = (courseId) => {
    localStorage.setItem("courseId", courseId);
  };

  let [prfresponseData, setprfResponseData] = React.useState("");
  let [prferrorData, setprfErrorData] = React.useState("");

  // const wislistcourse = (event) => {
  //   event.preventDefault()
  //   setSpinner(true)
  //   // https://backend.navyaedu.com/api/v1/auth/profile/update-profile
  //   instance
  //     .post('/api/v1/courses/wishlist/' + course_id)
  //     .then((response) => {
  //       setSpinner(false)
  //       setclassData("fa fa-heart text-[red] p-2 rounded-full bg-white text-[#775EA5] border border-[#775EA5] hover_heart_red")
  //       setprfResponseData(response.data)
  //       console.log(response.data)
  //       toast.success(response.data.msg)
  //     })
  //     .catch((error) => {
  //       console.log(error)

  //       setSpinner(false)

  //       if (error) {
  //         setprfErrorData(error.response)
  //         toast.error(error.response.data.msg)
  //       }
  //     })
  // }

  // const [carts, setcarts] = useState([]);
  // useEffect(() => {
  //   const fetchcartItems = async () => {
  //     try {
  //       const response = await instance.get(
  //         "/api/v1/courses/transaction/my-cart"
  //       );
  //       setcarts(response.data.courses);
  //       // setContextcart_length(response.data.courses.length);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchcartItems();
  // }, []);

  const navigate = useNavigate();

  const AddAndBuyNow = (course_id) => {
    console.log(course_id);
    console.log(cart_added);
    const filter = cart_added.filter((x) => x?.course?._id === course_id );
    console.log(filter);
    if (filter.length) {
      navigate("/order_details");
    } else {
      
        instance
        .post(`/api/v1/courses/transaction/my-cart/${course_id}`)
        .then((response) => {
          toast.success(response.data.msg);
          cartitemslength();
          navigate("/order_details");
        })
        .catch((error) => {
          console.log(error.response.request.status);

          if (error.response.request.status === 401) {
            // toast.error(error.response.data.msg)
            window.location.href = "/signIn";
          } else {
            toast.error(error.response.data.msg);
          }
        });
    }
  };

 

  const [feedbackpopup, setFeedbackpopup] = useState(false);

  function ShoweFeedback() {
    if(!localStorage.getItem("user_token") && !Cookies.get("user_token")){
      navigate("/signIn")
    }
    else{

      setFeedbackpopup(true);
    }
  }

  function closePopUp() {
    setFeedbackpopup(false);
  }

 
  console.log(cart_added);
  console.log(responseData.course_description);

  return (
    <>
    <MetaData title={"Navya | Course"} canonicalUrl={`https://navyaedu.com/course/${course_id}`}/>
      <ScrollToTopOnRouteChange />
      <ToastContainer />

      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}

      <div>
        {/* {usertoken ? (
        <LoginNavbar></LoginNavbar>
        )
        :(
          <Navbar></Navbar>
        )} */}
        <Navbar></Navbar>
        {/* <div className='p-10 bg-[#F5F5F5] '>
              <div className='w-[90%] m-auto'>
                  <CourseDetailCard img={1}></CourseDetailCard>
              </div>        
          </div> */}
        <section className="courses_detail_main_section" style={{background:mode=='dark'&& "transparent"}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="">
                  <img
                    src={
                      responseData.course_image
                        ? responseData.course_image
                        : "../plceholdernav/placeholder-img.png"
                    }
                    className="w-full h-min img-fluid "
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="courses_detail_side_content">
                  <div className="flex justify-between items-center">
                    <p className="py-1 px-2 rounded-2xl bg-[#66BE5E] text-white fomt-medium">
                      {ReactHtmlParser(`${responsename?.category_name}`)}
                    </p>
                    <div className="flex justify-between items-center absolute right-8">
                      <p>
                        {wislist_added
                          .map((items) => items?.wishlisted_course?._id)
                          .includes(course_id) ? (
                          <i
                            className="fa fa-heart text-[red] p-2 rounded-full bg-white text-[#775EA5] border border-[#775EA5] hover_heart_red"
                            // onClick={responseData?.is_wishlisted == true ? runLikeApidel : runLikeApi}
                            // onClick={()=>wislistcourse(responseData._id)}
                          ></i>
                        ) : (
                          <i
                            className={classData}
                            // onClick={responseData?.is_wishlisted == true ? runLikeApidel : runLikeApi}
                            onClick={() => wislistcourse(responseData._id)}
                          ></i>
                        )}
                      </p>
                      <button
                        className="RateOurCourse_two"
                        onClick={ShoweFeedback}
                      >
                        <AiFillStar className="icons mr-2" />
                        Rate Course
                      </button>
                    </div>
                  </div>
                  <div className="mt-3">
                    <h1 className="text-3xl font-bold">
                      {ReactHtmlParser(`${responsetitle?.course_title}`)}
                    </h1>
                    <p className="mt-3">
                      {ReactHtmlParser(`${responseData?.course_description}`)}
                    </p>
                  </div>
                  <div className="flex justify-between mt-3 items-center">
                    <p>
                      {responseData?.rating}{" "}
                      <i className="fa fa-star text-[orange]"></i>(
                      {responseData?.rated_by}
                      ratings)
                    </p>

                    {feedbackpopup && (
                      <Feedback
                        popup={setFeedbackpopup}
                        closepopUp={closePopUp}
                        courseId={responseData}
                      />
                    )}

                    {/* <p>
                      14k{' '}
                      <i className="fa fa-users text-[#66BE5E] font-semibold]"></i>{' '}
                      Students Enrolled
                    </p>
                    <p>
                      992 <i className="fa fa-heart text-[red]"></i> Students
                      Liked
                    </p> */}
                  </div>
                  {/* <div className="mt-3">
                    <p className="text-[#66BE5E]">
                      (M) Series{' '}
                      <span className="font-medium text-[black]">1 Hour</span>
                    </p>
                  </div> */}
                  {/* <div className="mt-5">
                    <h1 className="text-xl font-normal">
                      {' '}
                      Price:{' '}
                      <span className="text-xl font-semibold">
                        <i className="fa fa-inr"></i>1820
                      </span>
                      <span className="line-through font-light text-[#a2a1a1]">
                        {' '}
                        2200
                      </span>
                    </h1>
                  </div> */}
                  <div className="flex justify-between my-5 select-none">
                    <div className="w-full">
                      {cart_added
                        .map((items) => items?.course?._id)
                        .includes(course_id) ? (
                        <div className="text-[#775EA5]">
                          <button className="text-[#79797a] rounded p-3 font-semibold border border-[#775EA5] text-center w-full ">
                            Added to cart
                          </button>
                        </div>
                      ) : (
                        <Link
                          className="text-[#775EA5] "
                          onClick={() => callfunction(responseData?._id)}
                        >
                          <button className="text-[#fff] rounded p-3 font-semibold border border-[#775EA5] bg-[#66be5e] text-center w-full btn_hover_white btn_hover_white2">
                            Add To Cart
                          </button>
                        </Link>
                      )}
                    </div>
                    <div className="w-full mx-5">
                      <div
                        className="text-white btn_hover"
                        // to="/order_details"
                        onClick={() => AddAndBuyNow(responseData?._id)}
                      >
                        <button className="text-white select-none p-3 font-semibold rounded border bg-[#775EA5] border-transparent text-center w-full btn_hover">
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SimiliarCourses coursecat_id={cat_course_id} mode={mode}></SimiliarCourses>
      </div>
      <Footer></Footer>
    </>
  );
}

export default CourseDetails;
