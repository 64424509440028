import React from 'react'
import Footer from '../Footer/Footer'
import LoginNavbar from '../LoginNavbar/LoginNavbar'
import MyOrderCard from './MyOrderCard'
import CategoryCard from '../Category/CategoryCard'
import CourseCard from '../Category/CourseCard'
import TrendingCategory from '../new/TrendingCategory'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import Carousel from 'react-multi-carousel'
import NoCourses from '../new/NoCourses'
import { useEffect } from 'react'
import Navbar from '../Header/Navbar'
import { userContext } from '../../Context/UserContextProvider'
import Modal from '../modal/Modal'
import { Audio } from 'react-loader-spinner'
import UseAxios from '../../util/UseAxios'
import MetaData from '../MetaTitle/MetaData'


function MyOrders() {

  const { sharemodel, setSharemodel,profiledata,
    shareModelcontent, setShareModelcontent,
    shareModelitemid, setShareModelitemid,sharemodelType, setSharemodelType, mode}=React.useContext(userContext)


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  const usertoken = localStorage.getItem('user_token')

 
  let [responseData, setResponseData] = React.useState('')
  let [errorData, setErrorData] = React.useState('')

  const instance = UseAxios()

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get('/api/v1/auth/profile/')
        console.log(response.data)
        setResponseData(response.data)
      } catch (error) {
        setErrorData(error)
      }
    }

    fetchData()
  }, [usertoken])

  let [catresponseData, setcatResponseData] = React.useState([])
  let [caterrorData, setcatErrorData] = React.useState('')

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get('/api/v1/courses/category')
        console.log(response.data)
        setcatResponseData(response.data.categories)
      } catch (error) {
        setcatErrorData(error)
      }
    }

    fetchData()
  }, [usertoken])

  let [courseresponseData, setcourseResponseData] = React.useState()
  let [courseerrorData, setcourseErrorData] = React.useState('')




  useEffect(()=>{
    const fetchData = async () => {
           try {
        const response = await instance.get('/api/v1/courses/course/all_courses')
        const allcourses = await response.data
        setcourseResponseData(allcourses.coursesWithDetails);

    } catch (error) {
            setcourseErrorData(error)
          }
  }
  fetchData()
  },[usertoken])


  // console.log('responseData : >>>>' + responseData);
  // console.log('errorData : >>>>' + errorData);
  // console.log('catresponseData : >>>>' + catresponseData);
  // console.log('caterrorData : >>>>' + caterrorData);
  console.log(courseresponseData);
  // console.log('courseerrorData : >>>>' + courseerrorData);


  const handleShow = (cateName, courseId,name) => {
    console.log('clicked for open modalbox');
    setShareModelcontent(cateName)
    setSharemodel(true)
    setShareModelitemid(courseId)
    setSharemodelType(name)
  }

  return (
    <React.Fragment>
      <ToastContainer />
 
    {/* <Navbar></Navbar> */}
      <LoginNavbar></LoginNavbar>
      <MetaData title={"Navya | MyOrders"} canonicalUrl={"https://navyaedu.com/myorder"}/>

      <div className="w-[90%] m-auto py-2">
        {/* <div className="pt-2">
          <h1 className="text-3xl font-bold">My Orders</h1>
          <p className="pt-2 w-[30%] text-[#727272]" style={{color:mode=="dark"&&"white"}}>
      
            View and manage all your orders at a glance
          </p>
         
        </div> */}
        {/* <div className="flex flex-wrap flex-col md:flex-row sm:flex-col"> */}
        <MyOrderCard mode={mode} img={8} tag={'tag_l'}></MyOrderCard>
        {/* </div> */}
      </div>

      <div className="w-[90%] m-auto pt-0">
        <div className="pt-2">
          <h1 className="text-3xl font-bold mb-1">All Categories</h1>
          {/* <p className='pt-2 text-[#727272]'>At the Academy, we strive to bring together the best professors</p>
          <p className='text-[#727272]'>For The Best Courses</p> */}
        </div>
        {/* <div className="flex flex-wrap flex-col md:flex-row sm:flex-col  "></div> */}
        <Carousel responsive={responsive}>
          {Array.isArray(catresponseData) && catresponseData.length > 0 ? (
            catresponseData.map((category) => (
              <div className=' mt-4 lg:w-[270px]'>

              <CategoryCard
              mode={mode}
                img={1}
                tag={'tag_l'}
                width={'basis-1/6'}
                detail={category}
                className='lg:w-[270px]'
                handleShow = {handleShow}
                ></CategoryCard>

                </div>
            ))
          ) : (
            <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            background="transparent"
            wrapperClass="content_loading"
          />
          
          )}
          </Carousel>
        
      </div>

      

      <div className="w-[90%] m-auto">
        <div className="">
          <h1 className="text-3xl font-bold mb-2">All Courses</h1>
          {/* <p className='pt-2 text-[#727272]'>At the Academy, we strive to bring together the best professors</p>
          <p className='text-[#727272]'>For The Best Courses</p> */}
        </div>
        {/* <div className="flex flex-wrap flex-col md:flex-row sm:flex-col"></div> */}

        <Carousel responsive={responsive}>
          {Array.isArray(courseresponseData) &&
          courseresponseData.length > 0 ? (
            courseresponseData.map((course) => (
              <div className='All_courses_crausal mt-3 lg:w-[270px]'>
                <CourseCard
                mode={mode}
                  img={3}
                  tag={'tag_l'}
                  width={'basis-1/6'}
                  detail={course}
                  handleShow = {handleShow}
                ></CourseCard>
              </div>
            ))
          ) : (
            <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            background="transparent"
            wrapperClass="content_loading"
          />
          )}
        </Carousel>
      </div>

      <Modal
                open={sharemodel}
                setOpen={setSharemodel}
                content={shareModelcontent}
                share={'Share with your Friends'}
                courseId={shareModelitemid} 
                // handleCopyClick={handleCopyClick}
                // setText={setText}
                typeShare={sharemodelType}
              />

      <Footer></Footer>
    </React.Fragment>
  )
}

export default MyOrders
