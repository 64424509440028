import React from 'react';
import { Link } from 'react-router-dom';

function ScrollToTopLink({ to, children , mode,color }) {
  const handleClick = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  return (
    <Link to={to}
    style={{color:color}}
    onClick={handleClick} className="block py-2 text-[#7D7D7D]">
      {children}
    </Link>
  );
}

export default ScrollToTopLink;