import React, { Fragment, useRef, useState } from 'react'
import Logo from '../../assets/images/logo.png'
import back_btn from '../../assets/images/back-btn.png'
import { HiArrowNarrowRight } from 'react-icons/hi'
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
} from 'recharts'
import { Link } from 'react-router-dom'

// chart one
const data_All_Tests = [
  {
    name: 'Test A',
    UnattemptedQues: 4000,
    AttemptedQues: 2400,
    CorrectAnswers: 2500,
    IncorrectAnswers: 4000,
    ObtainedMarks: 6000,
    Accuracyin: 2500,
    amt: 2400,
  },
  {
    name: 'Test B',
    UnattemptedQues: 3000,
    AttemptedQues: 1398,
    CorrectAnswers: 2900,
    IncorrectAnswers: 3000,
    ObtainedMarks: 1000,
    Accuracyin: 800,
    amt: 2210,
  },
  {
    name: 'Test C',
    UnattemptedQues: 2000,
    AttemptedQues: 9800,
    CorrectAnswers: 1200,
    IncorrectAnswers: 4000,
    ObtainedMarks: 6000,
    Accuracyin: 2500,
    amt: 2290,
  },
  {
    name: 'Test D',
    UnattemptedQues: 2780,
    AttemptedQues: 3908,
    CorrectAnswers: 8200,
    IncorrectAnswers: 9000,
    ObtainedMarks: 2000,
    Accuracyin: 4000,
    amt: 2000,
  },
]

// chart two
const data_piechart = [
  { name: 'Attempted Ques', value: 400 },
  { name: 'Unattempted Ques', value: 300 },
]

const COLORS = ['#E9A64A', '#E74D4D']

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const data = [
  { name: 'Correct Answers', value: 400 },
  { name: 'Incorrect Answers', value: 100 },
]

const COLORSchart3 = ['#00CBF8', '#65489A']

const data_Chart_Tile = [
  {
    name: 'Physics',
    UnattemptedQues: 4000,
    AttemptedQues: 2400,
    CorrectAnswers: 4000,
    IncorrectAnswers: 1500,
    ObtainedMarks: 1500,
    amt: 2400,
  },
  {
    name: 'Chemistry',
    UnattemptedQues: 3000,
    AttemptedQues: 1398,
    CorrectAnswers: 3000,
    IncorrectAnswers: 1000,
    ObtainedMarks: 2500,
    amt: 2210,
  },
  {
    name: 'Maths',
    UnattemptedQues: 2000,
    AttemptedQues: 700,
    CorrectAnswers: 2500,
    IncorrectAnswers: 300,
    ObtainedMarks: 6500,
    amt: 2290,
  },
]

const FinalTestReport = () => {
  return (
    <>
      <section>
        <section className="only_logo_section">
          <img src={Logo} className="img-fluid" />
        </section>

        <section className="test_main_sections only_logo_top_section">
          <div className="container">
            <div className="mb-4">
              <div className="report_back_btn">
                <Link to="/test-report">
                  {' '}
                  <img src={back_btn} alt="back" className="img-fluid" /> Back
                </Link>
              </div>
            </div>
            <div className="help_feedback_div">
              <div>
                <p>
                  Wao! how good it is?{' '}
                  <Link to="#" className="feedback">
                    {' '}
                    Feedback{' '}
                  </Link>
                </p>
              </div>
              <div>
                <Link to="#" className="help">
                  {' '}
                  NEED HELP?{' '}
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="test_card_dark_blue">
                  <div className="test_name_rate_div">
                    <h1>Test report </h1>
                  </div>

                  <div className="test_report_boxes_main_div">
                    <div className="test_report_boxes_main_flex">
                      <div className="test_report_boxes test_report_boxes_bg_one">
                        <h4>Test 1</h4>
                      </div>

                      <div className="test_report_boxes test_report_boxes_bg_two">
                        <h4>Test 2</h4>
                      </div>

                      <div className="test_report_boxes test_report_boxes_bg_three">
                        <h4>Test 3</h4>
                      </div>

                      <div className="test_report_boxes test_report_boxes_bg_four">
                        <h4>Test 4</h4>
                      </div>


                      <div className="test_report_boxes test_report_boxes_bg_one">
                        <h4>Test 5</h4>
                      </div>

                      <div className="test_report_boxes test_report_boxes_bg_two">
                        <h4>Test 6</h4>
                      </div>

                      <div className="test_report_boxes test_report_boxes_bg_three">
                        <h4>Test 7</h4>
                      </div>

                      <div className="test_report_boxes test_report_boxes_bg_four">
                        <h4>Test 8</h4>
                      </div>

                    </div>
                  </div>
                  <div className="report_table_section">
                    <div className="row ">
                      <div className="col-lg-12">
                        <div className="table-responsive test_report_table">
                          <table class="table  table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Test</th>
                                <th scope="col">Attempted Ques</th>
                                <th scope="col">Unattempted Ques</th>
                                <th scope="col">Correct Ans</th>
                                <th scope="col">Incorrect Ans </th>
                                <th scope="col">Obtained Marks </th>
                                <th scope="col">Accuracy in % </th>
                                <th scope="col">Weak Section </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">1</th>
                                <td>5</td>
                                <td>7</td>
                                <td>4</td>
                                <td>1</td>
                                <td>8</td>
                                <td>50.5%</td>
                                <td>XD</td>
                              </tr>
                              <tr>
                                <th scope="row">2</th>
                                <td>5</td>
                                <td>7</td>
                                <td>4</td>
                                <td>1</td>
                                <td>8</td>
                                <td>50.5%</td>
                                <td>XD</td>
                              </tr>
                              <tr>
                                <th scope="row">3</th>
                                <td>5</td>
                                <td>7</td>
                                <td>4</td>
                                <td>1</td>
                                <td>8</td>
                                <td>50.5%</td>
                                <td>XD</td>
                              </tr>
                              <tr>
                                <th scope="row">4</th>
                                <td>5</td>
                                <td>7</td>
                                <td>4</td>
                                <td>1</td>
                                <td>8</td>
                                <td>50.5%</td>
                                <td>XD</td>
                              </tr>
                              <tr>
                                <th scope="row">5</th>
                                <td>5</td>
                                <td>7</td>
                                <td>4</td>
                                <td>1</td>
                                <td>8</td>
                                <td>50.5%</td>
                                <td>XD</td>
                              </tr>

                              <tr>
                                <th scope="row">6</th>
                                <td>5</td>
                                <td>7</td>
                                <td>4</td>
                                <td>1</td>
                                <td>8</td>
                                <td>50.5%</td>
                                <td>XD</td>
                              </tr>

                              <tr>
                                <th scope="row">7</th>
                                <td>5</td>
                                <td>7</td>
                                <td>4</td>
                                <td>1</td>
                                <td>8</td>
                                <td>50.5%</td>
                                <td>XD</td>
                              </tr>

                              <tr>
                                <th scope="row">8</th>
                                <td>5</td>
                                <td>7</td>
                                <td>4</td>
                                <td>1</td>
                                <td>8</td>
                                <td>50.5%</td>
                                <td>XD</td>
                              </tr>

                              <tr>
                                <th scope="row">9</th>
                                <td>5</td>
                                <td>7</td>
                                <td>4</td>
                                <td>1</td>
                                <td>8</td>
                                <td>50.5%</td>
                                <td>XD</td>
                              </tr>

                              <tr>
                                <th scope="row">10</th>
                                <td>5</td>
                                <td>7</td>
                                <td>4</td>
                                <td>1</td>
                                <td>8</td>
                                <td>50.5%</td>
                                <td>XD</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-12">
                <h2 className="report_other_headings">
                  All Tests Analysis Report
                </h2>

                <div className="test_card_dark_blue">
                  <div style={{ width: '100%', height: "350px" }}>
                    <ResponsiveContainer>
                      <LineChart
                        data={data_All_Tests}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="AttemptedQues"
                          stroke="#E9A64A"
                          activeDot={{ r: 8 }}
                        />
                        <Line
                          type="monotone"
                          dataKey="UnattemptedQues"
                          stroke="#E74D4D"
                        />
                        <Line
                          type="monotone"
                          dataKey="CorrectAnswers"
                          stroke="#00CBF8"
                        />
                        <Line
                          type="monotone"
                          dataKey="IncorrectAnswers"
                          stroke="#3C3942"
                        />
                        <Line
                          type="monotone"
                          dataKey="ObtainedMarks"
                          stroke="green"
                        />
                        <Line
                          type="monotone"
                          dataKey="Accuracyin"
                          stroke="#66BE5E"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-12">
                <h2 className="report_other_headings">Test_01 Analysis</h2>

                <div className="test_card_dark_blue">
                  <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div style={{ width: '100%', height: '350px' }}>
                        <ResponsiveContainer>
                          <PieChart width={400} height={400}>
                            <Pie
                              data={data_piechart}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              label={renderCustomizedLabel}
                              outerRadius={80}
                              fill="#8884d8"
                              dataKey="value"
                            >
                              {data_piechart.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              ))}
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div style={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                          <PieChart width={400} height={400}>
                            <Pie
                              data={data}
                              cx="50%"
                              cy="50%"
                              innerRadius={60}
                              outerRadius={80}
                              fill="#8884d8"
                              dataKey="value"
                            >
                              {data.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={
                                    COLORSchart3[index % COLORSchart3.length]
                                  }
                                />
                              ))}
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="test_report_marks_detail_div">
                        <p>
                          <span>
                            <HiArrowNarrowRight />
                          </span>
                          Navya, you scored 3.75 marks out of 12 on the first
                          attempt of Test_01 on 31/01/2023.
                        </p>
                        <p>
                          <span>
                            <HiArrowNarrowRight />
                          </span>
                          Navya, you scored 04 marks out of 12 on the second
                          attempt of Test_01 on 01/02/2023.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-12">
                <h2 className="report_other_headings">Chart Tile</h2>

                <div className="test_card_dark_blue">
                  <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                      <BarChart
                        width={500}
                        height={300}
                        data={data_Chart_Tile}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="AttemptedQues" fill="#E9A64A" />
                        <Bar dataKey="UnattemptedQues" fill="#E74D4D" />
                        <Bar dataKey="CorrectAnswers" fill="#00CBF8" />
                        <Bar dataKey="IncorrectAnswers" fill="#3C3942" />
                        <Bar dataKey="ObtainedMarks" fill="#FFFFFF" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-12">
                <h2 className="report_other_headings">
                  Correct Answers of the Test_01
                </h2>

                <div className="test_card_dark_blue test_card_right_ques_scroll">
                  <div className="single_question_box">
                    <p className="report_question">
                      <span>Ques 1.</span> Nulla Lorem mollit cupidatat irure.
                      Laborum magna nulla duis ullamco cillum dolor. Voluptate
                      exercitation incididunt aliquip deserunt reprehenderit
                      elit laborum. ?
                    </p>
                    <div className="report_answer">
                      <span className="report_correct_ans">A</span>
                      <p>
                        e. Laborum magna nulla duis ullamco cillum dolor.
                        Voluptate
                      </p>
                    </div>
                  </div>
                  <div className="single_question_box">
                    <p className="report_question">
                      <span>Ques 2.</span> Nulla Lorem mollit cupidatat irure.
                      Laborum magna nulla duis ullamco cillum dolor. Voluptate
                      exercitation incididunt aliquip deserunt reprehenderit
                      elit laborum. ?
                    </p>
                    <div className="report_answer">
                      <span className="report_correct_ans">A</span>
                      <p>
                        e. Laborum magna nulla duis ullamco cillum dolor.
                        Voluptate
                      </p>
                    </div>
                  </div>
                  <div className="single_question_box">
                    <p className="report_question">
                      <span>Ques 3.</span> Nulla Lorem mollit cupidatat irure.
                      Laborum magna nulla duis ullamco cillum dolor. Voluptate
                      exercitation incididunt aliquip deserunt reprehenderit
                      elit laborum. ?
                    </p>
                    <div className="report_answer">
                      <span className="report_correct_ans">A</span>
                      <p>
                        e. Laborum magna nulla duis ullamco cillum dolor.
                        Voluptate
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-12 weal_links_col">
                <h2 className="report_other_headings ">Your Weak Links:</h2>

                <div className="test_card_dark_blue test_card_right_ques_scroll">
                  <h3>Sorry! You must work smartly on the following topics.</h3>
                  <ul>
                    <li>Permutations & Combinations</li>
                    <li>Finding Roots of Quadratic Equations</li>
                  </ul>
                </div>
              </div>
            </div>


            <div className="row mt-5">
              <div className="col-lg-12 weal_links_col">
                <h2 className="report_other_headings ">Your Weak Links:</h2>

                <div className="test_card_dark_blue test_card_right_ques_scroll">
                  <h3>Sorry! You must work smartly on the following topics.</h3>
                  <ul>
                    <li>Permutations & Combinations</li>
                    <li>Finding Roots of Quadratic Equations</li>
                  </ul>
                </div>
              </div>
            </div>

     
            <div className="help_feedback_div">
              <div>
                <p>
                  Wao! how good it is?{' '}
                  <Link to="#" className="feedback">
                    {' '}
                    Feedback{' '}
                  </Link>
                </p>
              </div>
              <div>
                <Link to="#" className="help">
                  {' '}
                  NEED HELP?{' '}
                </Link>
              </div>
            </div>
            <div className="Download_Test_Report_div">
              <button className="Download_Test_Report" style={{ width: '60%' }}>
                Download Full Test Report of All Tests
              </button>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default FinalTestReport
