import React from 'react'
import { PieChart, Pie,Sector, Cell, ResponsiveContainer } from 'recharts';
import { userContext } from '../../../Context/UserContextProvider';
import { useContext } from 'react';





function PieChartnotfill({test_id ,report_data}) {


  const {showetest,setShoweTest} = useContext(userContext)
 console.log(showetest)
console.log(test_id)
console.log(report_data)
let filtervalue =report_data?.filter(item => item?.reportId === test_id ) 
  console.log(filtervalue)


  const data = [
    { name: 'Correct Answers', value:  filtervalue[0]?.correct_answer ? filtervalue[0]?.correct_answer : report_data[0]?.correct_answer },
    { name: 'Incorrect Answers', value:  filtervalue[0]?.incorrect_answer ? filtervalue[0]?.incorrect_answer : report_data[0]?.incorrect_answer },
  ];
  
  const COLORS = ['#A5A5A5', '#FFC000'];


  return (
    <div className="w-full">
      <ResponsiveContainer className="--tes--01--analysis--report">
        <PieChart width={600} height={200}>
          <Pie
            data={data}
            // cx={120}
            // cy={200}
            labelLine={true}
            innerRadius={40}
            outerRadius={80}
            fill="#8884d8"
            // paddingAngle={5}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default PieChartnotfill