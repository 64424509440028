import React, { useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import PopularNew from '../Popular/PopularNew'
import popular_card_img1 from '../../assets/images/courses/1.png'
import popular_card_img2 from '../../assets/images/courses/2.png'
import popular_card_img3 from '../../assets/images/courses/3.png'
import popular_card_img4 from '../../assets/images/courses/4.png'
import popular_card_img5 from '../../assets/images/courses/5.png'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import tah_m from '../../assets/images/tag_m.png'
import NoCourses from './NoCourses'
import Modal from '../modal/Modal'
import { userContext } from '../../Context/UserContextProvider'
import ReactHtmlParser from 'html-react-parser';
import { Audio } from 'react-loader-spinner'
import UseAxios from '../../util/UseAxios'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4.5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4.5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}
export const fetchData = async (instance,setResponseCourseData,setErrorData) => {
  try {


    // const response = await instance.get('/api/v1/courses/featured-course')
    const response = await instance.get(`/api/v1/courses/course/all_courses`)
    const allcourses = await response.data
    setResponseCourseData(allcourses.coursesWithDetails)
    console.log(allcourses);
    // if (Array.isArray(allcourses)) {
    //   console.log(allcourses)
    //   setResponseData(allcourses)
    // } else {
    //   console.log('newnew')
    // }
  } catch (error) {
    setErrorData(error)
  }
}


const Popular = ({mode}) => {
  const usertoken = localStorage.getItem('user_token')
  const instance = UseAxios()
  // let [responseData, setResponseData] = React.useState([])
  let [errorData, setErrorData] = React.useState('')
  const { callfunction, cartitemslength, wishlistitemslength,
    sharemodel, setSharemodel,
    shareModelcontent, setShareModelcontent,
    shareModelitemid, setShareModelitemid,responseCourseData,sharemodelType, setSharemodelType, setResponseCourseData } = React.useContext(userContext)

    
   
  React.useEffect(() => {
    fetchData(instance,setResponseCourseData,setErrorData)
  }, [])



  // added course in cart 
  let [prfresponseData, setprfResponseData] = React.useState('')
  let [prferrorData, setprfErrorData] = React.useState('')

  function addtocart(course_id) {
    console.log(course_id)
    
    instance
      .post(`/api/v1/courses/transaction/my-cart/${course_id}`)
      .then((response) => {
        // setprfResponseData(response.data)
        // console.log(response.data)
        toast.success(response.data.msg)

      })
      .catch((error) => {
        toast.error(error.response.data.msg)

        // console.log("already added ")
        // if (error) {
        //   setprfErrorData(error.response.data.msg)
        //   toast.error(error.response.data.msg)
        // }
      })

  }


  //  ======================for open share modal===========================


  const handleShow = (cateName, courseId,name) => {
    console.log('clicked for open modalbox');
    setShareModelcontent(cateName)
    setSharemodel(true)
    setShareModelitemid(courseId)
    setSharemodelType(name)
  }

  // const handleCopyClick = async () => {
  //   try {
  //     await navigator.clipboard.writeText(text);
  //     alert('Copied to clipboard: ' + text);
  //   } catch (err) {
  //     console.error('Unable to copy to clipboard.', err);
  //   }
  // };





  return (
    <>
      <ToastContainer />
      <section className="popular_cources_main_section mb-0">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12">
              <h1
              style={{color:mode==="dark"&&"white"}}
               className="popular_cources_main_section_heading mb-1">
                Some Popular
                <span>Courses We Offer</span>
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <Carousel
                 
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={1500}
                keyBoardControl={true}
                responsive={responsive}
                itemClass="carousel-item-padding-40-px"

              >
               
                
                {responseCourseData.length > 0 ? 
                responseCourseData.map((courses, index) => {
                  return (
                    <div className='Same_popular_coures_offer' >
                      <div className="card" key={index}  style={{background:mode==="dark"&&"transparent"}}>
                        <div className="card__inner">
                          <div className="card__front" style={{background:mode==="dark"&&"transparent"}}>
                            <div className="">
                              <div className="relative --relative--img--">
                                <div>
                                  <img
                                    src={courses?.course_image === null
                                      || courses?.course_image === undefined
                                      || courses?.course_image === ''
                                      ? './plceholdernav/placeholder-img.png'
                                      : courses?.course_image
                                    }
                                    className="w-[512px]"
                                    alt=""
                                  />
                                </div>
                                {/* <div className="absolute top-0 right-0 p-2">
                                <div>
                                  <i className="fa fa-heart-o text-[red] p-2 rounded-full bg-[white]"></i>
                                </div>
                              </div> */}
                                {/* <div className="absolute top-[-.4rem] left-2">
                            <div className="">
                              <img src={tah_m} alt="" className="tag_img" />
                            </div>
                          </div> */}
                              </div>
                              <div className="py-2">
                                <p
                                // style={{color:mode==="dark"&&"white"}}
                                 className="bg-[#d1f9cd] text-[#268a1d] px-2 py-1 rounded-xl w-fit courses_card_exam_name">


                                  {RegExp('&lt;').test(courses?.course_title) ? ReactHtmlParser(`${courses?.course_title.replaceAll('&lt;', '<')}`) : courses?.course_title}

                                </p>
                              </div>
                              <div className="">
                                <p className="courses_card_heading"
                                style={{color:mode==="dark"&&"white"}}
                                >
                                  {

                                    RegExp('&lt;').test(courses?.course_subtitle) ? ReactHtmlParser(`${courses?.course_subtitle.replaceAll('&lt;', '<')}`) : courses?.course_subtitle
                                  }
                                </p>
                                <p className="courses_card_rating" style={{color:mode==="dark"&&"white"}}>
                                  {/* {courses.ratings} */}
                                  <span >
                                    <i className="fa fa-star text-[#FFD12D]"></i>
                                  </span>
                                  (ratings)
                                </p>
                              </div>
                              <div className="py-1">
                                <p className="courses_card_exam_time"  style={{color:mode==="dark"&&"white"}}>
                                  2 hour <span className=""> ({courses?.courseSeries?.length}) Series</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="card__back" style={{background:mode==="dark"&&"#151515"}} >
                            <div className=" bg-gradient-to-r from-[#b18cf5] to-[#775EA5] rounded-xl shadow p-2 trendingCourse2 h-[430px]">
                              <div className="relative flex justify-between items-center">
                                <div
                                // style={{border:"1px solid red"}}
                                 className="bg-[#66BE5E] text-white py-1 pl-2 pr-1 rounded-2xl">
                                  <p className='course_cat_name'>
                                    {

                                      RegExp('&lt;').test(courses?.category?.category_name) ? ReactHtmlParser(`${courses?.category?.category_name.replaceAll('&lt;', '<')}`) : courses?.category?.category_name
                                    }
                                  </p>
                                </div>
                                {/* <div className="text-white">
                            <p className="font-semibold">
                              <i className="fa fa-inr">
                                </i>400
                              <span className="line-through text-[.7rem] font-light">
                                500
                              </span>
                            </p>
                          </div> */}
                              </div>
                              <div className="py-3">
                                <p className="text-white py-2 text-[.9rem]">
                                  {RegExp('&lt;').test(courses?.course_title) ? ReactHtmlParser(`${courses?.course_title.replaceAll('&lt;', '<')}`) : courses?.course_title}
                                </p>
                                <p className="text-white mt-1 text-[.9rem]">
                                  {courses?.ratings.rating_value} <i className="fa fa-star text-white"></i>
                                  <span> (ratings)</span>
                                </p>
                                <p className="text-white mt-1 text-[.9rem]">
                                  12k <i className="fa fa-users text-white"></i>
                                  <span> Students Enrolled</span>
                                </p>
                                {/* <p className="text-white mt-1 text-[.9rem]">
                            {courses.student_liked} <i className="fa fa-heart text-white"></i>
                            <span> Students Liked</span>
                          </p> */}
                              </div>
                              <div className='course_pera_box'>
                                <p className="text-white text-[.8rem] font-light courses_pera">
                                  {RegExp('&lt;').test(courses?.course_description) ? ReactHtmlParser(`${courses?.course_description?.replaceAll('&lt;', '<')}`) : courses?.course_description}
                                </p>
                              </div>
                              <div className="flex items-center justify-around pt-3">
                                <div>
                                  <button className="w-full" onClick={() => { callfunction(courses._id) }}>
                                    <i className="fa fa-shopping-cart p-2 rounded-full bg-white text-[#775EA5] icon_bg_color_change"></i>
                                  </button>
                                </div>
                                <div className="bg-[#66BE5E] text-white text-center py-1 px-3 rounded-lg hover:bg-green-700">
                                  <Link className='text-white' to={`/course/${courses._id}`}>
                                    <button onClick={() => { cartitemslength(); wishlistitemslength(); }} className="w-full">Buy Now</button></Link>
                                </div>
                                <div>
                                  <button className="w-full" onClick={() => handleShow(courses.course_title, courses._id,'course')}>
                                    <i className="fa fa-share p-2 rounded-full bg-white text-[#775EA5]  icon_bg_color_change"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  )

                })
                :
                <Audio
                height="80"
                width="80"
                radius="9"
                color="green"
                ariaLabel="loading"
                background="transparent"
                wrapperClass="content_loading"
              />
                }

              </Carousel>

              <Modal
                open={sharemodel}
                setOpen={setSharemodel}
                content={shareModelcontent}
                share={'Share with your Friends'}
                courseId={shareModelitemid}
              // handleCopyClick={handleCopyClick}
              // setText={setText}
              typeShare={sharemodelType}
              />

            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Popular

