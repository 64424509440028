import React, { useState } from 'react'
import { BsCheckCircle, BsFillMoonFill, BsFillSunFill } from 'react-icons/bs'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify'
import UseAxios from '../../util/UseAxios'
function Raiseticket({mode}) {
    const usertoken = localStorage.getItem('user_token')
    const [spinner, setSpinner] = React.useState(false)
    const [ratresponse, setRaTresponse] = React.useState('')
    const [OpenRaiseTicket, setOpenRaiseTicket] = React.useState(false);
    const [issue_type, setTicketissue] = useState()
    const [description, setDescription] = useState()
    const Raise_Ticket_data = { issue_type, description }
const instance = UseAxios()

    function handlechangeTR(e, flag) {
        let inpvalue = e.target.value;
        if (flag === 'issue') {
          setTicketissue(inpvalue)
        }
        if (flag === 'description') {
          setDescription(inpvalue)
        }
      }

    const TicketRase = async (event) => {
      event.preventDefault();
      console.log("ticket submit form ")
      setSpinner(true);
      try {

        const response = await instance.post("/api/v1/auth/raise-ticket",Raise_Ticket_data);
        console.log(response);
        if (response.status === 200) {
          const data = await response.data
          setRaTresponse(data.msg)
          // toast.success('Message Successfully Sent!');
          setOpenRaiseTicket(true)
          // setTicketOpen(false);
          setTicketissue("");
          setDescription("");
  
  
        } else {
  
          toast.error(`${response.status}  ${response.statusText}`)
        }
      } catch (error) {
        toast.error(error)
        console.log(error)
      }
  
      setTimeout(() => {
        setSpinner(false);
  
      }, 2000);
    };
  return (
    <section  className=" tabs_side_gap">
          <div>


          <div className=" inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            
                <div className=" transform overflow-hidden  text-left transition-all sm:my-8 sm:w-full sm:max-w-lg" style={{borderRadius:"8px",background:mode==="dark"?"#151515":"white"}}>
                  {OpenRaiseTicket ? (
                    <div className="px-10 pb-3 pt-3 sm:p-6 sm:pb-4" style={{background:mode==="dark"&&"#151515"}}>
                      <div className="success_edit_profile"  style={{background:mode==="dark"?"#151515":"white"}}>
                        <BsCheckCircle className="icons" />
                        <h4 style={{color:mode==="dark"&&"white"}}>Success! </h4>
                        <p style={{color:mode==="dark"&&"white"}}>{ratresponse}!</p>
                      </div>

                      <div className="Personal_Info_div ">
                        <div>
                          <div className="text-center">
                            <button
                              className="bg-[#66BE5E] py-3 mt-7 rounded text-white font-semibold w-[90%] m-auto"
                              onClick={()=>setOpenRaiseTicket(false)}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=" px-4 pb-4 pt-5 sm:p-6 sm:pb-4  raiseticket " style={{background:mode==="dark"?"#151515":"white"}} >
                      <div className="success_edit_profile">
                        <h4 style={{color:mode==="dark"&&"white"}}>Raise Your Ticket </h4>
                      </div>

                      <div className="Personal_Info_div ">
                        <div className="deactivate_modal">
                          {spinner ? (
                            <Audio
                              height="80"
                              width="80"
                              radius="9"
                              color="green"
                              ariaLabel="loading"
                              background="transparent"
                              wrapperClass="loder_raiseticket"
                            />
                          ) : (
                            <form className="pt-5" onSubmit={TicketRase}>
                              <div className="mx-0 md:mx-0 my-2 w-full">
                                <input
                                style={{color:mode==="dark"&&"white"}}
                                  onChange={(e) => handlechangeTR(e, "issue")}
                                  required
                                  value={issue_type}
                                  type="text"
                                  className="rounded p-3 border border-[#cabde2] w-full"
                                  placeholder="Enter Your Issue Topic Here "
                                />
                              </div>

                              <div className="mx-0 md:mx-0 my-2 w-full">
                                <textarea
                                style={{color:mode==="dark"&&"white"}}
                                  onChange={(e) =>
                                    handlechangeTR(e, "description")
                                  }
                                  required
                                  value={description}
                                  className="rounded p-3 border border-[#cabde2] w-full"
                                  placeholder="Enter Your Description Here"
                                  rows={2}
                                ></textarea>
                              </div>

                              <div className="text-center">
                                <div className="text-center Deactivate_tab_buttons">
                                  <button
                                    type="submit"
                                    className="bg-[#66BE5E] py-3 mt-7 rounded text-white font-semibold w-[90%] m-auto btn_hover"
                                //   onClick={TickethandleClose}
                                  >
                                    Send
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
            
            </div>
          </div>
      
      </div>
          </section>
  )
}

export default Raiseticket