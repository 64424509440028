import React from 'react'
import InviteCard from './InviteCard'

function Invite() {
  return (
    <div className='py-10 md:py-12 invite_section'>
        <InviteCard></InviteCard>
    </div>
  )
}

export default Invite