import React, { useContext, useEffect, useRef, useState } from 'react'
import './CoursePop.css'
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { userContext } from '../../../Context/UserContextProvider';
import UseAxios from '../../../util/UseAxios';
import { Audio } from 'react-loader-spinner';
import { Troubleshoot } from '@mui/icons-material';
function CoursePopup(props) {
  const [spinner, setSpinner] = React.useState(false)
  
    const {mode} =useContext(userContext)
    const [AllcatData, setAllcatdata] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    console.log(AllcatData[0]?.courses);
    console.log(filteredData)
    const instance=UseAxios()

   
   


    const fetchData = async () => {
      setSpinner(true)
      try {

        const data = await instance.get(
          "/api/v1/courses/category"
        );
        setSpinner(false)
        const  categories  = await data?.data?.categories;
        setAllcatdata(categories)
        console.log(categories);
        setFilteredData(categories[0]?.courses)
      } catch (error) {
        console.log(error);
        setSpinner(false)
      }
    };




    useEffect(() => {
      fetchData();
    }, []);

    function sendcatCoursedata(Id){
        console.log(Id)
        let coursedata =  AllcatData.filter((item)=> item._id === Id)[0]?.courses;
        console.log(coursedata)
        setFilteredData(coursedata)

    }
  return (
    <>
        {/* <div className={`Menu_popup_background`} onClick={()=>props.setPop(false)} > */}
        <div className={`Menu_popup_background`} >
       
             <div
                  className="overflow-hidden rounded-lg  Menu_popup_background_in_box shadow-lg ring-1 ring-black/5 w-[56vw]"
                  style={{
                    height: "80vh",
                    display: "flex",
                    width:"80vw",
                   
                  }}
                >
                   {spinner && (
        <Audio
       
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="absolute w-full h-full justify-center items-center bg-[#fff]"
        />
      )}
                  <div
                    style={{
                      background: mode === "dark" ? "#151515" : "white",
                      color: mode === "dark" ? "white" : "black",
                      flex: "1.1",
                    }}
                    className=" p-3 max-h[80vh]  overflow-scroll "
                  >
                    <div>
                      {AllcatData &&
                        AllcatData.map((x) => (
                          <div
                            onMouseEnter={() => sendcatCoursedata(x._id)}
                            className="p-3 flex gap-3 justify-between  header_cate_name hover:cursor-pointer"
                            style={{ background:mode==="dark"&&"black" }}
                          >
                            <p className="flex gap-2 justify-center items-center mb-0" >
                              <img
                                // src={x.category_image}
                                src={x.category_image ==='' 
                            || x.category_image === undefined 
                            ||  x.category_image === null  
                            ? './plceholdernav/placeholder-img.png'
                            :x?.category_image}
                                alt=""
                                className="h-[30px] w-[30px] "
                              />
                              {parse(
                                `${x?.category_name.replaceAll("&lt;", "<")}`
                              )}
                            </p>
                            {/* <ChevronDownIcon className="h-[22px] rotate-[270deg]" /> */}
                          </div>
                        ))}
                    </div>
                  </div>

                  <div
                    className=" eclipseprent    "
                    style={{
                      flex: "4.9",
                      display: "flex",
                      overflowY: "scroll",
                      background: mode === "dark" ? "#151515" : "white",
                      color: mode === "dark" ? "white" : "black",
                    }}
                  >
                    <div className="flex flex-wrap justify-center  cdinner">
                      {filteredData &&
                        filteredData.map((item) => (
                          <Link 
                          style={{ 
                            color:mode==="dark"&&"white",
                          background:mode!=="dark"?"#f5f5f5":"black", margin:"3px"}}
                          to={`/course/${item._id}`} onClick={props.popup}>
                          
                          <div
                            className=" ellipse "
                            style={{
                              height: "50px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="clispser_child">
                              <img
                              src={item.course_image ==='' 
                            || item.course_image === undefined 
                            ||  item.course_image === null  
                            ? './plceholdernav/placeholder-img.png'
                            :item?.course_image}
                                // src={item.course_image}
                                // alt={item?.course_title}
                              />
                              <p className="ellipsis-paragraph ml-2">
                                {parse(
                                  `${item?.course_title.replaceAll(
                                    "&lt;",
                                    "<"
                                  )}`
                                )}
                              </p>
                            </div>
                            {/* <ChevronDownIcon
                              size={"2em"}
                              className="h-[22px] rotate-[270deg]"
                            /> */}
                          </div>

                          </Link>
                        ))}
                    </div>
                  </div>
                </div>
        </div>
    </>
  )
}

export default CoursePopup