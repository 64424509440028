import React from 'react'
import arrow_green from '../../assets/images/arrows-green.png'
import arrow_light from '../../assets/images/arrows.png'
import lets_learn from '../../assets/images/lets-learn.png'

const Services = ({mode}) => {
  return (
    <>
      <section className="service_main_section">
        <div>
          <img
            src={arrow_green}
            alt=""
            className="img-fluid arrow_green_service"
          />
          <img
            src={arrow_light}
            alt=""
            className="img-fluid arrow_light_service"
          />
          <img
            src={lets_learn}
            alt=""
            className="img-fluid lets_learn_service"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="services_heading_side_div">
                <h2 style={{color:mode==="dark"&&"white"}}>What's new you achieve through Navya®</h2>
                <p style={{color:mode==="dark"&&"white"}}>
                Experience a revolution in exam prep with Navya®, featuring advanced AI-driven tools for personalized learning and unparalleled success.
                </p>
              </div>
            </div>
            <div className="col-lg-1 col-md-12 col-sm-12"></div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="services_small_box" style={{background:mode==="dark"&&"#151515"}}>
                    <h3 style={{color:mode==="dark"&&"white"}}>In-depth Analysis</h3>
                    <p style={{color:mode==="dark"&&"white"}}>
                    Our In-depth Analysis Reports, featuring Weak Topic Detection and Parallel Test Reports, provide valuable insights into your performance. Know your strengths and weaknesses effortlessly. Elevate your learning experience with Navya®!
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12" >
                  <div className="services_small_box" style={{background:mode==="dark"&&"#151515"}}>
                    <h3 style={{color:mode==="dark"&&"white"}}>Wide Exam Coverage</h3>
                    <p style={{color:mode==="dark"&&"white"}}>
                    From Kindergarten to NEET PG (NExT), we've got you covered. Dive into a world of comprehensive resources, making exam preparation a breeze. Your one-stop solution for success. Explore, learn, and conquer with Navya® – your partner on the journey to excellence!
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12" >
                  <div className="services_small_box" style={{background:mode==="dark"&&"#151515"}}>
                    <h3 style={{color:mode==="dark"&&"white"}}>Easy-To-Access Portal</h3>
                    <p style={{color:mode==="dark"&&"white"}}>
                    Your Ultimate Learning Companion! Dive into a world of knowledge with our Easy Access Portal and Mobile Apps for both Android & iOS. Enjoy 24/7 support, seamless learning resources, and assistance. Your education, your way – anytime, anywhere!
                    </p>
                  </div>
                </div>
                
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Services
