
import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
// import paymentsuccess from '../payment/paymentsucces.png';
function Test() {
    const usertoken = localStorage.getItem('user_token')

    const [firstName, setFname] = React.useState("");
    const [userMobNo, setNumber] = React.useState("");
    const [amount, setAmount] = React.useState("");
    const amountdetail = {
        name: firstName,
        number: userMobNo,
        amount: amount,
    };


    // const payment = async (event,course_id) => {
    //   event.preventDefault();
    //  let baseURL= 'http://192.168.1.17:8000'
    //   try{
    //     const config = {
    //       method: 'POST',
    //       headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //           Authorization: 'Bearer ' + usertoken,
    //       },
    //       body: JSON.stringify(amountdetail)
    //   }

    //   const response = await fetch(`${baseURL}/api/v1/courses/transaction/payment/${course_id}`, config)
    //   console.log(response)
    //   if(response.ok){
    //     const data =  await response.json()
    //     console.log(data);
    //     // toast.success('Send Message Successfully');

    // }
    // else {
    //     // toast.error(`ERROR : ${response.status}  ${response.statusText}`);
    //     console.log('error');
    //   }
    //   // console.log(data.msg);

    //   }catch(error){

    //     console.log(error)}


    // }

    function payment(e, course_id) {
        e.preventDefault();
        localStorage.setItem('courseId', course_id)
        const instance = axios.create({
            headers: {
                Authorization: 'Bearer ' + usertoken,
            },

        })
        instance
            .post(`/api/v1/courses/transaction/payment/${course_id}`, amountdetail)
            .then((response) => {
                console.log(response);
                // toast.success(response.data.msg)
                window.location.href = response.data.redirectUrl
            })
            .catch((error) => {
                // toast.error(error.response.data.msg)
                console.log(error);
            })


    }
    return (
        //     <div className='w-full flex justify-center items-center'>
        //     <div className='w-[400px]'>
        //     <h1 className='h1'>Phonepay form Test </h1>
        //      <form className='form' onSubmit={(e)=>payment(e,'651fed23e9736435d48e4944')}>
        //      <div className=" my-2 w-full">
        //               <label className="block text-[#775EA5]">First Name</label>
        //               <input
        //                 value={firstName}
        //                 onChange={(e) => setFname(e.target.value)}
        //                 type="text"
        //                 required
        //                 className="rounded p-3 border border-[#cabde2] w-full"
        //                 placeholder="First Name"
        //               />
        //             </div>

        //             <div className=" my-2 w-full">
        //               <label className="block text-[#775EA5]">Number</label>
        //               <input
        //                 value={userMobNo}
        //                 onChange={(e) => setNumber(e.target.value)}
        //                 type="number"
        //                 required
        //                 className="rounded p-3 border border-[#cabde2] w-full"
        //                 placeholder="number"
        //               />
        //             </div>

        //             <div className=" my-2 w-full">
        //               <label className="block text-[#775EA5]">Amount</label>
        //               <input
        //                 value={amount}
        //                 onChange={(e) => setAmount(e.target.value)}
        //                 type="number"
        //                 required
        //                 className="rounded p-3 border border-[#cabde2] w-full"
        //                 placeholder="amount"
        //               />
        //             </div>

        //          <button type='submit' className="text-white p-3 font-semibold rounded border bg-[#66BE5E] border-transparent text-center w-[200px] btn_hover">
        //                           Pay
        //                         </button>
        //      </form>
        //     </div>
        //  </div>
        <>

        <div className='paymentsucces'>
            <div>
                <h3>Success</h3>
                <p>Your Payment has been success</p>
                <img className='m-auto' src='../payment/paymentsucces.png' alt="" />
                <div class="my-2 md:m-0  ">
                    <a href="/signUp">
                        <button class="bg-[#66BE5E] w-full mt-3  py-3 px-11 rounded text-white btn_hover">Done</button>
                    </a>
                </div>
            </div>
        </div>

        <div className='paymentsucces mt-5'>
        <div>
            <h3>Falid</h3>
            <p>Your Payment has been Faild</p>
            <img className='m-auto' src='../payment/paymentfals.png' alt="" />
            <div class="my-2 md:m-0  ">
                <a href="/signUp">
                    <button class="bg-[#f00] w-full mt-3  py-3 px-11 rounded text-white btn_hover">Done</button>
                </a>
            </div>
        </div>
    </div>
        </>

    )
}

export default Test


