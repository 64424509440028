import axios from 'axios';
import React from 'react'
import UseAxios from '../../util/UseAxios';



function TermsService({mode}) {
    const instance = UseAxios()
    const usertoken = localStorage.getItem("user_token");
    const [spinner, setSpinner] = React.useState(false);
    let [tresponseData, setTresponseData] = React.useState("");
    let [terrorData, setTerrorData] = React.useState("");
  
    const fetchData = async () => {
        setSpinner(true);
        
        try {
          const response = await instance.get("/api/v1/app/terms-and-condition");
          console.log(response.data);
          
          setTresponseData({
              ...response.data.terms_and_condition,
              description: response.data.terms_and_condition.description.replaceAll(
                  "&lt;",
                  "<"
                  ),
          });
  
          setSpinner(false);
        } catch (error) {
            setSpinner(false);
            setTerrorData(error);
        }
    };
    
    React.useEffect(() => {
      fetchData();
    }, []);

  return (
    <section  className="tabs_side tabs_side_gap">
    <div className="w-[100%] m-auto other_tabs_headings">
      <div
        dangerouslySetInnerHTML={{
          __html: tresponseData.description,
        }}
      ></div>

     
    </div>
  </section>
  )
}

export default TermsService