import React from 'react'
import PopularCard from '../Popular/PopularCard'
import axios from 'axios'
import Carousel from 'react-multi-carousel'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { userContext } from '../../Context/UserContextProvider'
import Modal from '../modal/Modal'
import ReactHtmlParser   from 'html-react-parser';
import UseAxios from '../../util/UseAxios'

function SimiliarCourses(props) {
  const instance = UseAxios()
  const usertoken = localStorage.getItem('user_token')
  
  const {mode}=props
    console.log(props);
    const {callfunction,
      sharemodel, setSharemodel,
      shareModelcontent, setShareModelcontent,
      responseCourseData,setResponseCourseData,sharemodelType, setSharemodelType,
      shareModelitemid, setShareModelitemid}=React.useContext(userContext)

    
  let [similarcourseData, setSimilarcourseData] = React.useState(responseCourseData)
  let [caterrorData, setcatErrorData] = React.useState('')
  let [classsend, setClasssend] = React.useState('')


    const filteredData = responseCourseData.filter(item => item?.category?._id === props.coursecat_id);

console.log(filteredData);


 
    const fetchData = async () => {
      try {
       
        // setResponseData({...response.data.course,
        //   course_description:response.data.course.course_description.replaceAll('&lt;', '<')})
        const response = await instance.get(`/api/v1/courses/course/all_courses`)
        const all_course = await response.data.coursesWithDetails
        console.log(all_course)
        
        setResponseCourseData(all_course)
      } catch (error) {
        console.log(error)
        setcatErrorData(error)
      }
    }

  React.useEffect(() => {
    fetchData()
  }, [])


// share course author:Nitesh 

const handleShow = (cateName, courseId,name) => {
  console.log('clicked for open modalbox');
  setShareModelcontent(cateName)
  setSharemodel(true)
  setShareModelitemid(courseId)
  setSharemodelType(name)

}


  return (
    <section className="Similar_cources_main_section">
      {/* <ToastContainer /> */}
    <section className="popular_cources_main_section">
      <div className="container">
      <div className="row">
              <div className="col-lg-12">
                <h1  style={{color:mode=='dark'&& "white"}} className="popular_cources_main_section_heading">
                  Similiars
                  <span>Courses We Offer</span>
                </h1>
              </div>
            </div>


        <div className="row">
              <div className="col-lg-12">
                <div 
                itemClass="carousel-item-padding-40-px"
                className='Similiars_Courses_We_Offer'
                >
            {/* <Carousel
                 infinite={true}
                 autoPlay={false}
                 autoPlaySpeed={1500}
                 keyBoardControl={true}
                 responsive={responsive}
                 itemClass= {`${classsend === 'similar_no' ? 'similar_no' :"carousel-item-padding-40-px"}`}
               
              > */}
             
                {filteredData.map((courses,index)=>{
                  return(
                    <div className="card w-[20%]" key={index}  style={{background:mode==="dark"&&"transparent"}}>
                  <div className="card__inner">
                    <div className="card__front" style={{background:mode==="dark"&&"transparent"}}>
                      <div className="">
                        <div className="relative">
                          <div>
                            <img
                              src={courses?.course_image === null 
                                || courses?.course_image === undefined 
                                || courses?.course_image === '' 
                                ? '../plceholdernav/placeholder-img.png'
                                : courses?.course_image
                              }
                              className="w-full"
                              alt=""
                            />
                          </div>
                          {/* <div className="absolute top-0 right-0 p-2">
                            <div>
                              <i className="fa fa-heart-o text-[red] p-2 rounded-full bg-[white]"></i>
                            </div>
                          </div> */}
                          {/* <div className="absolute top-[-.4rem] left-2">
                            <div className="">
                              <img src={tah_m} alt="" className="tag_img" />
                            </div>
                          </div> */}
                        </div>
                        <div className="py-2">
                          <p style={{color:mode=='dark'&& "black"}}
                          className="bg-[#d1f9cd] text-[#268a1d] px-2 py-1 rounded-xl w-fit courses_card_exam_name">
                            {
                              ReactHtmlParser(courses?.course_title.replaceAll('&lt;', '<'))
                            }
                          </p>
                        </div>
                        <div className="">
                          <p 
                          style={{color:mode=='dark'&& "white"}}
                          className="courses_card_heading">
                           {
                            ReactHtmlParser(courses?.course_subtitle.replaceAll('&lt;', '<'))
                           }
                          </p>
                          <p
                          style={{color:mode=='dark'&& "white"}}
                           className="courses_card_rating">
                          {/* {
                            courses.ratings.length !== 0 ? courses.ratings.map((value)=>(<>{}</>))
                          } */}
                            {/* {courses.ratings[0].rating_value} */}
                            <span >
                              <i className="fa fa-star text-[#FFD12D]"></i>
                            </span>
                            (ratings)
                          </p>
                        </div>
                        <div className="py-1">
                          <p className="courses_card_exam_time" style={{color:mode=='dark'&& "white"}}>
                            2 hour <span className=""> ({courses?.course_series.length}) Series</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card__back">
                      <div className=" bg-gradient-to-r from-[#b18cf5] to-[#775EA5] rounded-xl shadow p-2 trendingCourse2">
                        <div className="relative flex justify-between items-center">
                          <div className="bg-[#66BE5E] text-white py-1 pl-2 pr-4  rounded-2xl">
                            <p className='course_cat_name'
                            >
                            {
                             ReactHtmlParser(courses?.category?.category_name.replaceAll('&lt;','<'))
                            }
                            </p>
                          </div>
                          {/* <div className="text-white">
                            <p className="font-semibold">
                              <i className="fa fa-inr">
                                </i>400
                              <span className="line-through text-[.7rem] font-light">
                                500
                              </span>
                            </p>
                          </div> */}
                        </div>
                        <div className="py-3">
                          <p className="text-white py-2 text-[.9rem]">
                           {ReactHtmlParser(courses?.course_title.replaceAll('&lt;','<'))}
                          </p>
                          <p className="text-white mt-1 text-[.9rem]">
                           {/* {courses.ratings} <i className="fa fa-star text-white"></i> */}
                            <span> (ratings)</span>
                          </p>
                          <p className="text-white mt-1 text-[.9rem]">
                            12k <i className="fa fa-users text-white"></i>
                            <span> Students Enrolled</span>
                          </p>
                          <p className="text-white mt-1 text-[.9rem]" >
                            {courses?.student_liked} <i className="fa fa-heart text-white"></i>
                            <span> Students Liked</span>
                          </p>
                        </div>
                        <div className='course_pera_box'>
                          <p className="text-white text-[.8rem] font-light courses_pera">
                            {/* {RegExp('&lt;').test(courses.course_description) 
                            ? ReactHtmlParser(`${courses?.course_description?.replaceAll('&lt;', '<')}`) 
                            : courses.course_description
                            } */}
                            { ReactHtmlParser(`${courses?.course_description?.replaceAll('&lt;', '<')}`)}
                          </p>
                        </div>
                        <div className="flex items-center justify-around pt-5 ">
                          <div>
                            <button className="w-full"  onClick={()=>{callfunction(courses?._id)}}>
                              <i className="fa fa-shopping-cart p-2 rounded-full bg-white text-[#775EA5] icon_bg_color_change"></i>
                            </button>
                          </div>
                          <div className="bg-[#66BE5E] select-none text-white text-center py-1 px-3 rounded-lg hover:bg-green-700">
                            <Link className='text-white' to={`/course/${courses?._id}`}><button className="w-full" >Buy Now</button></Link>
                          </div>
                          <div>
                            <button className="w-full" onClick={() => handleShow(courses?.course_title, courses?._id,"course")}>
                              <i className="fa fa-share p-2 rounded-full bg-white text-[#775EA5]  icon_bg_color_change"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                  )
                })

                }
                
              {/* </Carousel> */}
              </div>
             
              <Modal
                open={sharemodel}
                setOpen={setSharemodel}
                content={shareModelcontent}
                share={'Share with your Friends'}
                courseId={shareModelitemid} 
                // handleCopyClick={handleCopyClick}
                // setText={setText}
                typeShare={sharemodelType}
              />
            </div>
          </div>
    </div>
    </section>
    </section>
  )
}

export default SimiliarCourses