import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import NavyaImg from '../../assets/images/loginPageSigeImg.png'
import {useNavigate} from "react-router-dom"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Audio } from 'react-loader-spinner'
import NoSigningfooter from './NoSigningfooter'
import UseAxios from '../../util/UseAxios'
import ReactSwitch from 'react-switch'
import CountryList from '../../util/country.json'
import MetaData from '../MetaTitle/MetaData'

function ForgotPassword() {
  const instance = UseAxios()
  const location= useLocation()
  const[phone, setPhone] = useState(location?.state?.phone_no);
  const[email, setEmail] = useState(location?.state?.mail);
  let [responseData, setResponseData] = useState('')
  let [errorData, setErrorData] = useState('')
  const [spinner, setSpinner] = React.useState(false);
  const [checked, setChecked] = useState(true)
  let [countryMobile, setcountryMobile] = React.useState(location?.state?.country_code)
  console.log(location?.state)
  const handleChange = (val) => {
    setChecked(val)
    // localStorage.clear()
  }
  const handleCountryChange = (e) => {
    setcountryMobile(e.target.value);
  };

  const body_json = {
    type:"email",
    email: email,
  }
  const body_json_phone = {
    type:"phone",
    country_code : countryMobile,
    phone_no:phone
}

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    
    event.preventDefault()
    console.log(event);

   let sendcodefrom = checked ? body_json_phone : body_json

    setSpinner(true);

    instance
      .post('/api/v1/auth/forget-password/generate-otp',sendcodefrom )
      .then((response) => {
        console.log(response)
        setSpinner(false);
        toast.success(response.data.msg)
       

          if (response.status !== 200 ) {
            setResponseData(response.data)
          } else {
            setResponseData(response.data)
            console.log(response.data.msg)
            localStorage.setItem("otp_data",JSON.stringify(sendcodefrom));
          }
          navigate('/verifyOtp');
      })
      .catch((error) => {
        setSpinner(false);
        toast.error(error.response.data.msg)
        console.log(error)
        if (error) {
          setErrorData(error.response)
        }
      })
  }




    return (
      <>
      <MetaData title={"Navya | Forgot password"} canonicalUrl={"https://navyaedu.com/forgot_password"}/>
     <ToastContainer />
     

      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}
        <div className='flex login_signup_main_section'>

            <div className='basis-1/2 md:block hidden'>
                <img src={NavyaImg} alt="" className='img-fluid login_pgae_img' />
            </div>
            <div className='mx-auto p-5 flex justify-between flex-col'>
                <div>
                    <div>
                        <Link to="/signIn" className='font-medium'><i className='fa fa-arrow-left font-normal'></i><span className='px- text-lg'> Back</span></Link>
                    </div>
                    <div className='font-bold text-2xl pt-8'>
                        <h1 >Forgot Password </h1>
                    </div>
                    <div className='pt-2'>
                        <p>Enter Your Email for the verification Process we will send 6 digits code to your email.</p>
                    </div>
                    <div className='pt-10 w-full'>
                        {/* <div className='pb-2'>
                            <p>Send Through: <span></span></p>
                        </div> */}
                         <div className="pb-2">
                <p className="login_toggle">
                Forgot With:{' '}
                  <label style={{ marginLeft: '5px', marginRight: '10px' }}>
                    Email
                  </label>
                  <ReactSwitch checked={checked} 
                  onChange={handleChange}
                   />
                  <label style={{ marginLeft: '15px' }}>Mobile Number</label>
                </p>
              </div>
                       {checked ? 
                       <form action="" className='' onSubmit={handleSubmit}>
                            <div className='border-[#aeaeae] border rounded p-2'>
                            <select
                            required
                            className="w-25 border-none focus:border-none focus:outline-none input_box"
                            aria-label="Default select example"
                            onChange={handleCountryChange}
                            value={countryMobile}
                          >
                                  <option value=""> Country</option>
                          { 
                              CountryList.map((course) => (
                                <option value={course.dial_code}>
                                  {course.name + '('+course.dial_code+')'}
                                </option>
                              ))
                            }
                        </select>
                                {/* <input type="number" required name="phone" id="" value={phone} onChange={(e) => setPhone(e.target.value)}  className='w-full border-none focus:border-none focus:outline-none' placeholder='Enter Your Mobile Number' /> */}
                                <input
                      value={phone}
                        type="tel"
                        name="phone"
                        className="w-75 border-none focus:border-none focus:outline-none input_box"
                        placeholder="Enter Your Phone Number"
                        // style={{color:mode=="dark"&&"white"}}
                        required
                        onChange={(e) => setPhone(e.target.value)}
                        // onChange={handlePhoneChange}
                      />
                            </div>
                            <div className='my-7'>
                                <div className='bg-[#66BE5E] rounded py-3' >
                                    <input type="submit" name="" id="" className='w-full border-none focus:border-none focus:outline-none text-white' value="Send OTP" />
                                </div>
                            </div>
                        </form>
                        :
                         <form action="" className='' onSubmit={handleSubmit}>
                         <div className='border-[#aeaeae] border rounded p-2'>
                             <input type="email" required name="email" id="" value={email} onChange={(e) => setEmail(e.target.value)}  className='w-full border-none focus:border-none focus:outline-none' placeholder='Enter Your Email' />
                         </div>
                         <div className='my-7'>
                             <div className='bg-[#66BE5E] rounded py-3' >
                                 <input type="submit" name="" id="" className='w-full border-none focus:border-none focus:outline-none text-white' value="Send OTP" />
                             </div>
                         </div>
                     </form>}
                    </div>
                </div>
                <NoSigningfooter/>
               
            </div>
        </div>
        </>
    )
}

export default ForgotPassword