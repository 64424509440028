import React, { useState,useContext } from 'react';
import { PieChart, Pie,Sector, Cell, ResponsiveContainer } from 'recharts';
import { userContext } from '../../../Context/UserContextProvider';




function PieChartReport({test_id ,report_data}){
  const {showetest,setShoweTest} = useContext(userContext)
 console.log(showetest)
console.log(test_id)
console.log(report_data)
//   console.log(report.report_data)
  // console.log(testname.test_name)

// let [attemptedQues,setAttemptedQues]= useState(0)
// let [unattemptedQues,setUnattemptedQues]= useState(0)
// for(let i=0;i>report.report_data.length;i++){
//   console.log(i)
// }


let filtervalue =report_data?.filter(item => item?.reportId === test_id ) 
  console.log(filtervalue)



  const data = [
    { name: 'AttemptedQues', value: filtervalue[0] ? filtervalue[0]?.attempted_question  : report_data[0]?.attempted_question},
    { name: 'UnattemptedQues', value: filtervalue[0] ? filtervalue[0]?.unAttempted_question : report_data[0]?.unAttempted_question },
  ];
  
  const COLORS = ['#5B9BD5', '#ED7D31'];
  
  const RADIAN = Math.PI / 180;
  
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }
  return (
    
 <div className='w-full'>
    <ResponsiveContainer className='--tes--01--analysis--report'>
      <PieChart width={600} height={200} >
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          )
          )}
        </Pie>
       
      </PieChart>

   
    </ResponsiveContainer>

   

 </div>
  )
}

export default PieChartReport;
