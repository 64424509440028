import React, { useContext, useEffect, useRef } from 'react'
import CoursePopup from './CoursePopup'
import { useState } from 'react'
import { RiArrowDropDownLine } from "react-icons/ri";
import { userContext } from '../../../Context/UserContextProvider';

function ImportCoursePopup() {
    const {mode} = useContext(userContext)
  
    const [pop,setPop]=useState(false)
    function popup(){
        setPop(!pop)
    }



  
  return (
    <div >
        <div className='text-[#7D7D7D] font-[500] mr-5 font-Outfit menu_links'>

        <p className={`mb-0 flex justify-center items-center cursor-pointer select-none ${mode === "dark" && 'text-white'} `} onClick={popup}>Course <i><RiArrowDropDownLine /></i></p>
        </div>
        {pop && <CoursePopup pop={pop} popup={popup}  setPop={setPop} /> }
    </div>
  )
}

export default ImportCoursePopup;