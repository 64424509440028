import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PopularNew from "../Popular/PopularNew";
import popular_card_img1 from "../../assets/images/courses/1.png";
import popular_card_img2 from "../../assets/images/courses/2.png";
import popular_card_img3 from "../../assets/images/courses/3.png";
import popular_card_img4 from "../../assets/images/courses/4.png";
import popular_card_img5 from "../../assets/images/courses/5.png";
import axios from "axios";
import tah_m from "../../assets/images/tag_m.png";
import Navbar from "../Header/Navbar";
import Footer from "../Footer/Footer";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { userContext } from "../../Context/UserContextProvider";
import Lottie from "react-lottie";
import No_result from "../../assets/gifs/Results-not-found.json";
import parse from "html-react-parser";
import { fetchData } from "./Popular";
import UseAxios from "../../util/UseAxios";
import MetaData from "../MetaTitle/MetaData";
const SearchResult = () => {
  const usertoken = localStorage.getItem("user_token");
  const { callfunction, responseCourseData, setResponseCourseData,mode } =
    React.useContext(userContext);
  console.log(responseCourseData)
  const instance = UseAxios()
  // author:Nitesh
  let [searchParams, setSearchParams] = useSearchParams();
  const parems = useParams();
  const { result } = parems;
  const { contextvalue } = React.useContext(userContext);
  let [responseData, setResponseData] = React.useState([]);
  let [errorData, setErrorData] = React.useState("");

  console.log(result);
  console.log(searchParams.get("result"));
  const No_result_found = {
    loop: true,
    autoplay: true,
    animationData: No_result,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  console.log(contextvalue);
  useEffect(()=>{
    fetchData(instance,setResponseCourseData)
  },[])
  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const instance = axios.create({
  //         baseURL: 'https://backend.navyaedu.com/',

  //       })

  //       // const response = await instance.get('/api/v1/courses/featured-course')
  //       const response = await instance.get('/api/v1/courses/course/all_courses')
  //       const data =await response.data.coursesWithDetails
  //     //   const convertedData = data.map(item => ({
  //     //     ...item,
  //     //     course_description: item.course_description.replace(/&lt;/g, '<'),
  //     //     category_name: item.category_name.replace(/&lt;/g, '<'),
  //     //     course_title: item.course_title.replace(/&lt;/g, '<'),
  //     //     course_subtitle: item.course_subtitle.replace(/&lt;/g, '<')
  //     // }));

  //       console.log(data)
  //       setResponseData(data)
  //     } catch (error) {
  //       setErrorData(error)
  //       console.log(error)
  //     }
  //   }

  //   fetchData()
  // },[])

  return (
    <>
      <ToastContainer />
      <MetaData title={"Navya | Search Result"} canonicalUrl={"https://navyaedu.com/search"}/>
      <Navbar />

      <section className="popular_cources_main_section search_main_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="popular_cources_main_section_heading">
                Search Result
              </h1>
            </div>
          </div>

          <div className="row">
            {responseCourseData
              .filter((courses) => {
                return contextvalue === null
                  ? courses
                  :   Object.values(courses).some(value =>
                    value && value.toString().toLowerCase().includes(contextvalue.toLowerCase()))
              }
            )
              .map((courses) => {
                return (
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="card    " style={{background:mode === "dark" && "transparent"}}>
                      <div className="card__inner">
                        <div className="card__front">
                          <div className="">
                            <div className="relative">
                              <div>
                                <img
                                  src={
                                    courses?.course_image === null ||
                                    courses?.course_image === undefined ||
                                    courses?.course_image === ""
                                      ? "./plceholdernav/placeholder-img.png"
                                      : courses?.course_image
                                  }
                                  className="w-full"
                                  alt=""
                                />
                              </div>
                              {/* <div className="absolute top-0 right-0 p-2">
                              <div>
                                <i className="fa fa-heart-o text-[red] p-2 rounded-full bg-[white]"></i>
                              </div>
                            </div> */}
                              {/* <div className="absolute top-[-.4rem] left-2">
                              <div className="">
                                <img src={tah_m} alt="" width={50} />
                              </div>
                            </div> */}
                            </div>
                            <div className="py-2">
                              <p className="bg-[#d1f9cd] text-[#268a1d] px-2 py-1 rounded-xl w-fit courses_card_exam_name">
                                {parse(
                                  `${courses?.course_title.replaceAll(
                                    "&lt;",
                                    "<"
                                  )}`
                                )}
                              </p>
                            </div>
                            <div className="" >
                              <p className="courses_card_heading"  style={{color:mode === "dark" && "#fff"}}>
                                {parse(
                                  `${courses?.course_subtitle.replaceAll(
                                    "&lt;",
                                    "<"
                                  )}`
                                )}
                              </p>
                              <p className="courses_card_rating" style={{color:mode === "dark" && "#fff"}} >
                                4.8{" "}
                                <span>
                                  <i className="fa fa-star text-[#FFD12D]"></i>
                                </span>{" "}
                                (878 ratings )
                              </p>
                            </div>
                            <div className="py-1">
                              <p className="courses_card_exam_time"  style={{color:mode === "dark" && "#fff"}}>
                                1 Hour{" "}
                                <span className="">
                                  {" "}
                                  ({courses?.courseSeries?.length}) Series
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card__back">
                          <div className=" bg-gradient-to-r from-[#b18cf5] to-[#775EA5] rounded-xl shadow p-2 trendingCourse2">
                            <div className="relative flex justify-between items-center">
                              <div className="bg-[#66BE5E] text-white py-1 px-2 rounded-2xl">
                                <p className="course_cat_name">
                                  {parse(
                                    `${courses?.category?.category_name.replaceAll(
                                      "&lt;",
                                      "<"
                                    )}`
                                  )}
                                </p>
                              </div>
                              {/* <div className="text-white">
                              <p className="font-semibold">
                                <i className="fa fa-inr"></i>1820{' '}
                                <span className="line-through text-[.7rem] font-light">
                                  2200
                                </span>
                              </p>
                            </div> */}
                            </div>
                            <div className="py-3">
                              <p className="text-white py-2 text-[.9rem]">
                                {parse(
                                  `${courses?.course_title.replaceAll(
                                    "&lt;",
                                    "<"
                                  )}`
                                )}
                              </p>
                              <p className="text-white mt-1 text-[.9rem]">
                                {courses?.ratings?.rating_value}{" "}
                                <i className="fa fa-star text-white"></i>{" "}
                                <span> (878 ratings)</span>
                              </p>
                              <p className="text-white mt-1 text-[.9rem]">
                                14k <i className="fa fa-users text-white"></i>{" "}
                                <span> Students Enrolled</span>
                              </p>
                              {/* <p className="text-white mt-1 text-[.9rem]">
                              992 <i className="fa fa-heart text-white"></i>{' '}
                              <span> Students Liked</span>
                            </p> */}
                            </div>
                            <div className="course_pera_box">
                              <p className="text-white text-[.8rem] font-light courses_pera">
                                {parse(
                                  `${courses?.course_description.replaceAll(
                                    "&lt;",
                                    "<"
                                  )}`
                                )}
                              </p>
                            </div>
                            <div className="flex items-center justify-around pt-5">
                              <div>
                                <button
                                  className="w-full "
                                  onClick={() => {
                                    callfunction(courses._id);
                                  }}
                                >
                                  <i className="fa fa-shopping-cart p-2 rounded-full bg-white text-[#775EA5] icon_bg_color_change"></i>
                                </button>
                              </div>
                              <div className="bg-[#66BE5E] text-white text-center py-2 px-7 rounded-lg hover:bg-green-700">
                                <Link
                                  className="text-white"
                                  to={`/course/${courses?._id}`}
                                >
                                  <button className="w-full">Buy Now</button>
                                </Link>
                              </div>
                              <div>
                                <button className="w-full">
                                  <i className="fa fa-share p-2 rounded-full bg-white text-[#775EA5]  icon_bg_color_change"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {responseCourseData.filter((courses) => {
              return contextvalue === null
                ? courses
                :   Object.values(courses).some(value =>
                  value && value.toString().toLowerCase().includes(contextvalue.toLowerCase()))
            }).length === 0 && (
              <div className="flex w-[100vw] justify-center items-center">
                <div className="w-full text-center">
                  <h3> No Results Found : {contextvalue}</h3>
                  <div className="paymentsucces flex justify-center items-center">
                    <div>
                      <Lottie
                        options={No_result_found}
                        isClickToPauseDisabled={true}
                        height="100%"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default SearchResult;
