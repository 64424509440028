import React from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Header/Navbar";
import { Link } from "react-router-dom";
import { HiOutlineHome, HiOutlineMail } from "react-icons/hi";
import { RiFacebookCircleLine } from "react-icons/ri";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { AiFillGoogleCircle } from "react-icons/ai";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import ConatctUsForm from "../ContactUs/ConatctUsForm";
import UseAxios from "../../util/UseAxios";
import { userContext } from "../../Context/UserContextProvider";
import MetaData from "../MetaTitle/MetaData";

const Contactus = () => {
  const instance = UseAxios()
  const [spinner, setSpinner] = React.useState(false);
  let [responseData, setResponseData] = React.useState("");
  let [errorData, setErrorData] = React.useState("");
  const {capVal,setCapval ,mode}=React.useContext(userContext)

  const usertoken = localStorage.getItem("user_token");

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true);

      try {
        const response = await instance.get("/api/v1/app/contact-us");
        console.log(response.data);
        setResponseData(response.data.contact_us);
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setErrorData(error);
      }
    };

    fetchData();
  }, [usertoken]);

  return (
    <>
      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}
           <MetaData title={"Navya | Contact us"} canonicalUrl={"https://navyaedu.com/contact-us"}/>

      <Navbar />
      <div className="w-[90%] m-auto pb-10 ">
        {/* <div className="pt-2">
          <h1 className="refer_heading">Contact Us</h1>
        </div> */}
        <div className="about_contact_row contact_page">
          <div className="row ">
            <div className="col-lg-6 col-md-6 col-sm-12 about_contact_col">
              {/* <h1>
                <span> Get In Touch </span>
              </h1> */}
              <div className="">
               
                <ConatctUsForm />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-5 contact_socail_icons_div">
              <div className="address_div_main pt-3">
                <h4>Contact Details</h4>
                <div className="address_div">
                  <span>
                    <HiOutlineMail className="icons" />
                  </span>
                  <p> {responseData.email}</p>
                </div>
                <div className="address_div">
                  <span>
                    <HiOutlineHome className="icons" />
                  </span>
                  <p> {responseData.address}</p>
                </div>
                <div className="Followus_div ">
                  <h1 style={{color:mode=="dark"&&"white"}}>Follow us on:</h1>
                </div>
                <div className="socail_icons_div ">
                  <Link to="https://www.facebook.com/navyaedtech">
                    <RiFacebookCircleLine className="icons" />
                  </Link>
                  <Link to="https://www.instagram.com/navyaedu/">
                    <BsInstagram className="icons" />
                  </Link>
                  <Link to="https://www.linkedin.com/company/navyaedu">
                    <FaLinkedin className="icons" />
                  </Link>
                  <Link to="https://twitter.com/navya_edu">
                    <FaXTwitter className="icons" />
                  </Link>
                  <Link to="#">
                    <AiFillGoogleCircle className="icons" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <div class="map-div">
                {/* <iframe src="https://maps.google.com/maps?q=Navya%C2%AE&t=k&z=12&ie=UTF8&iwloc=&output=embed" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                

                <iframe
                  width="100%"
                  height="450"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=Navya%C2%AE&t=&z=12&ie=UTF8&iwloc=&output=embed"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                 
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Contactus;
