import React from 'react'
import LoginNavbar from '../LoginNavbar/LoginNavbar'
import Footer from '../Footer/Footer'
import { RiDownloadCloud2Fill } from "react-icons/ri";

function Certificate() {
  return (
    <div>
        <LoginNavbar />
        <div className=' position-relative flex justify-center items-center my-5'>
            <img className=' ' src="https://backend.navyaedu.com/public/images/certificate.jpg" alt="" srcset="" />
            <div className='position-absolute bottom-10 z-50 right-96 cursor-pointer '>
                <i className=''>
                    <RiDownloadCloud2Fill size={'3em'} color='#66be5e'/>
                </i>
            </div>
        </div>


        <Footer></Footer>
    </div>
  )
}

export default Certificate