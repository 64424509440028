import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Logo from '../assets/images/logo.png'
import white from '../assets/images/logo-white.png'
import { Link, useNavigate } from 'react-router-dom'
import { AiFillStar } from 'react-icons/ai'
import { Rating } from 'react-simple-star-rating'
import Pdf from 'react-to-pdf'
// import axios from 'axios'
import { Audio } from 'react-loader-spinner'
// import Weakchart from './Testreport/Weakchart'
import { HiArrowNarrowRight } from 'react-icons/hi'
// import XYZreportChart from './Testreport/XYZreportChart'
// import Sectionchart from './Testreport/Sectionchart'
// import PieChartReport from './Testreport/PieChartReport'
// import PieChartnotfill from './Testreport/PieChartnotfill'
import { useContext } from 'react'
import ReactHtmlParser from 'html-react-parser';
import { toast } from "react-toastify";
import MetaData from '../components/MetaTitle/MetaData'
import NoCourses from '../components/new/NoCourses'
import UseAxios from '../util/UseAxios'
import { userContext } from '../Context/UserContextProvider'
import PieChartReport from '../components/TestReport/Testreport/PieChartReport'
import PieChartnotfill from '../components/TestReport/Testreport/PieChartnotfill'
const RADIAN = Math.PI / 180


const TestReport = () => {
  const instance = UseAxios()
  const { showetest, setShoweTest,mode } = useContext(userContext)
  let [responseReport, setResponseReport] = React.useState([]);
  console.log(responseReport)
  let [showetable, setShowetable] = React.useState(5);
  let Navigate = useNavigate()
  // let [showetest,setShoweTest] = React.useState(localStorage.getItem("quizId"))
  // rating

  const [rating, setRating] = useState(0)
  const [spinner, setSpinner] = React.useState(false)

  // Redirect to home when the back button is clicked in browser 
// useEffect(()=>{
//   window.history.pushState(null, "", window.location.href);
//   window.onpopstate = function() {
//       window.history.pushState(null, "", window.location.href);
//   };
// },[])
window.onpopstate = function(event) {

  // window.location.href = "/";
  Navigate("/")

  
};

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate)
  }


  const [Successopen, setSuccessOpen] = useState(false)
  const SuccesshandleShow = (e) => {
    return e.preventDefault(), setSuccessOpen(true)
  }

  const SuccesshandleClose = (e) => {
    return e.preventDefault(), setSuccessOpen(false)
  }
  const params = new URLSearchParams(window.location.search)
  const [quiz, setQuiz] = React.useState(params.get('quiz_id'))
  const [resultans, setResultans] = React.useState({})
  


  let [repsecresponseData, setrepsecResponseData] = React.useState('');
  let [repsecerrorData, setrepsecErrorData] = React.useState('');
  console.log(repsecresponseData)

  if (resultans.length > 0) {

    const quizReportData = {
      section_detail: resultans.map((result, index) => ({
        of_section: result.sectionId,
        time_taken: 0, // Fill with actual time taken for the section
        attempted_questions: result.questions.answered,
        unattempted_questions: result.questions.not_answered,
        correct_questions: result.questions.answered_right,
        incorrect_questions: result.questions.answered_wrong,
        marks_obtained: 0, // Fill with actual marks obtained for the section
        question_option_selected: {}, // Fill with the user's selected options for each question
      })),
    };



    const fetchData = async () => {
      try {
        const response = await instance.post('/api/v1/quizzes/report/' + quiz, quizReportData);
        console.log(response.data);

        setrepsecResponseData(response.data);
      } catch (error) {
        setrepsecErrorData(error);
      }
    };


  }

  // user test report author : Nitesh 
  const sendstatus = {
    is_quiz_live: false,
  };
  const sendquizstatus = async () => {
    const response = await instance.patch(
      `/api/v1/quizzes/quiz/quiz-attempt-details/${quiz}`,
      sendstatus
    );
    console.log(response.data);
    // toast.success(response.data.message);
  };


  React.useEffect(() => {
    sendquizstatus()
  })
  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)
      try {


        const response = await instance.get('/api/v1/scholarship/reports');
        setSpinner(false)
        console.log(response.data.data);
        setResponseReport(response.data.data)
        localStorage.setItem("quizId", response.data.data[0].reportId)
        setShoweTest(response.data.data[0].reportId)

      } catch (error) {
        console.log(error)
        setSpinner(false)
      }
    };

    fetchData();
  }, []);

  console.log(responseReport)
  const [testid, setTestid] = useState('')

  function handleChange(e) {
    setTestid(e.target.value)

  }

  function clikTestshowe(testid) {
    console.log(testid)

    setShoweTest(testid)
  }



  let filterdata = responseReport?.find(item => item?.reportId === showetest)
  console.log(filterdata?.topicScore)
  console.log(filterdata)


  function viewMoreLess(flag){
    if(flag==="more"){

      setShowetable(showetable + 10)
    }
    if(flag==="less"){

      setShowetable(5)
    }
  }



  return (
    <>
    <MetaData title={"Navya | Report"} canonicalUrl={"https://navyaedu.com/test-report"}/>

      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass={`${mode==="dark" ?"loaderdark":"loader"}`}
          
        />
      )}

      {responseReport.length
        ?
        <section className='select-none'>
          {/* <LoginNavbar></LoginNavbar> */}
          <section className="only_logo_section" style={
            {
              background:mode==="dark"&&"black"
            }
          }>
            <a href='/' >
            <img className="logo" src={mode==="dark"? white :Logo} alt="" />
            </a>
          </section>
          <section className=" only_logo_top_section Congratulations_report">
            <div className="help_feedback_div">
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="test_card_dark_blue" style={{background:mode=="dark"?"black":"white", color:mode==="dark"&&"white"}}>
                  <div className="test_name_rate_div">
                 
                  <h1
                  style={{ color:mode==="dark"&&"white"}}
                  > {ReactHtmlParser(filterdata?.testName?.replaceAll('&lt;', '<'))}</h1>
                    <button
                      className=" !hidden RateOurCourse "
                    // onClick={SuccesshandleShow}
                    >
                      <AiFillStar className="icons" /> Rate Our Course
                    </button>
                  </div>
                  <div className="Congratulations_div">
                    <div className="Congratulations_side_content">
                      <h3 style={{ color:mode==="dark"&&"white"}} >
                        Congratulations! <br />
                        
                        <h3 style={{ color:mode==="dark"&&"white"}}   className='mb-0 inline uppercase'>{ReactHtmlParser(filterdata?.username?.replaceAll('&lt;', '<'))}</h3>, you have scored
                        {/* <span className={responseaccData > 36 ? 'span_passed' : 'span_failed'}> {resultans?.length > 0 ? responseaccData : 0}% </span> */}

                        <h3  style={{ color:mode==="dark"&&"white"}}  className='mb-0 inline uppercase '> {filterdata?.scored_Marks}<span className=''>% </span></h3>
                        in the
                        <h4 style={{ color:mode==="dark"&&"white"}}  className='mb-0 inline uppercase'> {ReactHtmlParser(filterdata?.testName?.replaceAll('&lt;', '<'))} </h4>
                        test.
                      </h3>
                      {/* <h6>
                        Nulla Lorem mollit cupidatat irure. Laborum magna nulla
                        duis ullamco cillum dolor. Voluptate ex
                      </h6>
                      <p>
                        E. Laborum magna nulla duis ullamco cillum dolor.
                        Voluptate gna nulla duis ullamco
                      </p> */}
                    </div>
                    <div></div>
                  </div>
                  <div className='test_name_rate_name'>
                    <h1 style={{ color:mode==="dark"&&"white"}}>Taken Tests:</h1>
                    <span style={{ color:mode==="dark"&&"white"}}>I Couldn’t Understand. <a href="/setting?page=raiseticket">Need Help?</a></span>
                  </div>
                  <div className='All_test_list flex gap-3 mt-7'>
                    {
                      responseReport.map((userreport, index) => {
                        console.log(userreport)
                        return (
                          <div className='pt-7 pb-7' onClick={() => clikTestshowe(userreport?.reportId)}>
                            <div className='scholarshiptest_list_card px-2 hover:bg-slate-600'>
                              <h5 style={{ color:"white"}} className=' style={{ color:mode==="dark"&&"white"}}   style={{ color:mode==="dark"&&"white"}}   mb-0 text-center overflow-hidden'>{ReactHtmlParser(userreport?.testName?.replaceAll('&lt;', '<'))}</h5>
                            </div>
                            {/* <a className='mt-2 inline-block'>View Report_1</a> */}
                          </div>
                        )
                      })
                    }



                  </div>
                  <div className='all_text_table overflow-scroll'>
                    <table class="table table-bordered mt-8">
                      <thead>
                        <tr>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Test</th>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Attempted Ques</th>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Unattempted Ques</th>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Not visited</th>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Correct Ans</th>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Incorrect Ans </th>
                          <th   style={{ color:mode==="dark"&&"white"}}  scope="col" className='text-center'>Obtained Marks </th>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Accuracy in % </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        {
                          responseReport.slice(0, showetable).map((userreport, index) => {
                            return (
                              <tr key={index}>
                                <td  style={{ color:mode==="dark"&&"white"}} >{ReactHtmlParser(userreport?.testName?.replaceAll('&lt;', '<'))}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport?.attempted_question}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport?.unAttempted_question}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport.not_visited.length}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport?.correct_answer}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport?.incorrect_answer}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport?.obtained_Marks}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport?.accuracy}%</td>
                                {/* <td  style={{ color:mode==="dark"&&"white"}}  className='flex justify-center'>{userreport?.weakSection?.map((x, i) => {
                                  return (

                                    <p>{`${x}${i !== userreport.weakSection.length - 1 ? ">" : ""}`}</p>

                                  )

                                })}</td> */}

                              </tr>
                            )
                          })
                        }

                      </tbody>
                    </table>
              
                  </div>
     
                </div>
              </div>
            </div>

          

            <section className="test_main_sections only_logo_top_section">
             
                <div className="row">
                  <div className="col-lg-12">
                    <div className="test_card_dark_blue" style={{background:mode=="dark"?"black":"white", color:mode==="dark"&&"white"}}>
                      <div className='flex items-center justify-between'>
                        <h2  style={{ color:mode==="dark"&&"white"}}className="report_other_headings text-[#52bb49]">Test Analysis:</h2>
                        <select 
                        style={{background:mode=="dark"?"black":"#775da8", color:mode==="dark"&&"#fff"}}
                        className="form-select form-select_two" onChange={handleChange}>
                         
                          {
                            responseReport.map((userreport, index) => {
                              return (
                                <option style={{background:mode=="dark"?"black":"white", color:mode==="dark"&&"white"}} key={index} value={userreport?.reportId} >{ReactHtmlParser(userreport?.testName?.replaceAll('&lt;', '<'))}</option>

                              )
                            })
                          }


                        </select>
                      </div>

                      <div className="w-full xl:flex mb-5 px-8">
                        <div className="w-full py-12 border text-center">
                          <PieChartReport report_data={responseReport} test_id={testid} />
                          <div className='xl:flex items-center justify-center'>
                            <div className='flex items-center mx-2'><p className='bg-[#5B9BD5] w-3 h-3 m-0 me-1'></p>Attempted Ques</div>
                            <div className='flex items-center mx-2'><p className='bg-[#ED7D31] w-3 h-3 m-0 me-1'></p>Unattempted Ques</div>
                          </div>

                        </div>
                        <div className="w-full py-12 border  text-center">
                          <PieChartnotfill report_data={responseReport} test_id={testid} />
                          <div className='xl:flex items-center justify-center'>
                            <div className='flex items-center mx-2'><p className='bg-[#A5A5A5] w-3 h-3 m-0 me-1'></p>CorrectAnswers</div>
                            <div className='flex items-center mx-2'><p className='bg-[#FFC000] w-3 h-3 m-0 me-1'></p>IncorrectAnswers</div>
                          </div>

                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="test_report_marks_detail_div">
                            <p>
                              <span>
                                <HiArrowNarrowRight />

                              </span>
                              {/* You Attempted {answeredset} Questions out of {answeredset + notansweredset} Questions */}
                              You Attempted {filterdata?.attempted_question} Questions out of {filterdata?.total_Questions} Questions
                            </p>
                            <p>
                              <span>
                                <HiArrowNarrowRight />
                              </span>
                              {/* You Attempted {answeredsetright} Right Answer And {notansweredsetwrong} Wrong Answer */}
                              You Attempted {filterdata?.correct_answer} Right Answer And {filterdata?.incorrect_answer} Wrong Answer
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='grafh_report_content'>
                        <div>
                          <p style={{ color:mode==="dark"&&"white"}}>{ReactHtmlParser(filterdata?.username?.replaceAll('&lt;', '<'))}, you scored {filterdata?.scored_Marks}% marks out of  {filterdata?.maximum_Marks} on the first attempt of &nbsp;
                            {ReactHtmlParser(filterdata?.testName?.replaceAll('&lt;', '<'))} on {filterdata?.test_attempted_date?.split('T')[0]}</p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="help_feedback_div px-4">
                  {/* <div>
                    <p>
                      Wao! how good it is?{' '}
                      <Link to="#" className="feedback">
                        {' '}
                        Feedback{' '}
                      </Link>
                    </p>
                  </div> */}
                  <div>
                    <Link to="/setting?page=raiseticket" className="help">

                      NEED HELP?
                    </Link>
                  </div>
                </div>
                {/* <div className="Download_Test_Report_div xl:flex items-center   justify-center">
                <button className="   ">
                              <Link to={"/scholarshipcertificate"} className="text-white bg-[#66BE5E] py-3 px-5 mt-7 rounded text-white font-semibold w-[90%] m-auto">
                                Get Certificate
                              </Link>
                            </button>
    
              </div> */}
               <div className="Download_Test_Report_div xl:flex items-center   justify-center">
                <button className="   ">
                              <Link to={"/"} className="text-white bg-[#66BE5E] hover:bg-[#775da8] py-3 px-5 mt-7 rounded text-white font-semibold w-[90%] m-auto">
                              <i class="fa fa-long-arrow-left me-2" aria-hidden="true"></i>
                               Go Home Page
                              </Link>
                            </button>
    
              </div>
            </section>

          </section>
        </section>
        :
        <section className='select-none'>
          <section className="only_logo_section">
            <a href='/' >
              <img src={Logo} className="img-fluid" />
            </a>
          </section>
          <div className='h-[100vh] flex justify-center items-center'>
            <NoCourses stat="Report" />
          </div>
        </section>
      }

      <Transition.Root show={Successopen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10  select-none"
          // initialFocus={cancelButtonRef}
          onClose={setSuccessOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4  ">
                    <div className="success_edit_profile">
                      <h4>Rate Our Course </h4>
                      <form className="w-full">
                        <div className="rating_star">
                          <Rating
                            className=""
                            onClick={handleRating}
                          // onPointerEnter={onPointerEnter}
                          // onPointerLeave={onPointerLeave}
                          // onPointerMove={onPointerMove}
                          // showTooltip={true}
                          // rtl={true}
                          /* Available Props */
                          />
                        </div>
                        <div className="mx-0  my-2 w-full mt-4">
                          <textarea
                            className="border border-[#cabde2] w-full rounded p-2"
                            rows={4}
                            placeholder="Enter Your Issue Topic Here"
                          ></textarea>
                        </div>
                      </form>
                    </div>

                    <div className="Personal_Info_div ">
                      <div>
                        <div className="pt-5">
                          <div className="text-center">
                            <button className="   ">
                              <Link className="text-white bg-[#66BE5E] py-3 px-5 mt-7 rounded text-white font-semibold w-[90%] m-auto">
                                Send Feedback
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default TestReport;