// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
//   };

const firebaseConfig = {
  apiKey: "AIzaSyDWGtT2fo5INav43f-B2NL1TyU15sO5qzk",
  authDomain: "navyaedu-ce1f8.firebaseapp.com",
  projectId: "navyaedu-ce1f8",
  storageBucket: "navyaedu-ce1f8.appspot.com",
  messagingSenderId: "1079916508117",
  appId: "1:1079916508117:web:493c4a5502fb51a112c35c",
  measurementId: "G-T4Z1Z6J8HZ" 
};
  
  export default firebaseConfig;
  