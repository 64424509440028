import React from 'react'
import ConatctUsForm from './ConatctUsForm'
import ContactUsImg from './ContactUsImg'

function ConatctUs() {
  return (

    <div className='w-[90vw] mt-5 m-auto flex justify-around border rounded-xl flex-col lg:flex-row md:flex-column my-10 md:my-0 contact_us_form_main'>
       <div className='row'>
        <div className='col-lg-6 col-md-12 col-sm-12'>
        <ContactUsImg />
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
        <ConatctUsForm />
          </div>
       </div>
        {/* <ContactUsImg></ContactUsImg>
        <ConatctUsForm></ConatctUsForm> */}
    </div>
    
  )
}

export default ConatctUs