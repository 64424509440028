import React from 'react'
import Footer from '../Footer/Footer'
import 'react-multi-carousel/lib/styles.css'
import axios from 'axios'
import { Link } from 'react-router-dom'
import LoginNavbar from '../LoginNavbar/LoginNavbar'
import { ToastContainer} from 'react-toastify'
import NoCourses from './NoCourses'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { userContext } from '../../Context/UserContextProvider'
import Modal from '../modal/Modal'
import ReactHtmlParser   from 'html-react-parser';
import MetaData from '../MetaTitle/MetaData'
function ScrollToTopOnRouteChange() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const SimilarCourses = () => {
  let [responseData, setResponseData] = React.useState([])
  let [errorData, setErrorData] = React.useState('')
  const {callfunction,
    sharemodel, setSharemodel,
    shareModelcontent, setShareModelcontent,
    shareModelitemid, setShareModelitemid,sharemodelType, setSharemodelType,}=React.useContext(userContext)
  // const usertoken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDcxODEwYWJhMzc0OGZmZDc4ZjAwN2IiLCJyb2xlIjoidXNlciIsImlhdCI6MTY4NTI1MTUzOSwiZXhwIjoxNjg3ODQzNTM5fQ.5V86CNEz3a_OEzpCMaAF4b5bW-vhigMqUi7a_LIugBQ';
  const params = new URLSearchParams(window.location.search)
  
  const [course, setCourse] = React.useState(params.get('catrgory_id'))
  

  const usertoken = localStorage.getItem('user_token')

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const instance = axios.create({
          baseURL: 'https://backend.navyaedu.com/',
          headers: {
            Authorization: 'Bearer ' + usertoken,
          },
        })

        const response = await instance.get('/api/v1/courses/course/' + course)
        setResponseData(response.data.courses)
          console.log(response.data.courses)
        // if (Array.isArray(response.data.courses)) {
        // } else {
        //   console.log('newnew')
        // }
      } catch (error) {
        setErrorData(error)
      }
    }

    fetchData()
  }, [usertoken])

  const handleCourse = (courseId) => {
    localStorage.setItem('courseId', courseId)
  }

  
  const handleShow = (cateName, courseId,name) => {
    console.log('clicked for open modalbox');
    setShareModelcontent(cateName)
    setSharemodel(true)
    setShareModelitemid(courseId)
    setSharemodelType(name)
  }

  return (
    <>
    <MetaData title={"Navya | Similar Course"} canonicalUrl={"https://navyaedu.com/Similar-Course"}/>
              <Modal
                open={sharemodel}
                setOpen={setSharemodel}
                content={shareModelcontent}
                share={'Share with your Friends'}
                courseId={shareModelitemid} 
                // handleCopyClick={handleCopyClick}
                // setText={setText}
                typeShare={sharemodelType}
              />
    <ToastContainer />
    <ScrollToTopOnRouteChange />
    <LoginNavbar></LoginNavbar>
    <section className="Similar_cources_main_section">
      <section className="popular_cources_main_section">
        <div className="container">
          {/* <div className="flex items-center">
            <Link to="/myorder" className="d-flex">
              <p className="bg-[#775DA8] rounded-full w-5 h-5 relative text-center mt-0.5">
                <i className="fa fa-angle-left absolute top-[2px] left-0 right-0.5 bottom-0 m-auto text-white"></i>
              </p>
              <p className="text-black font-semibold mx-2"> Back</p>
            </Link>
          </div> */}
          <div className="row">
            <div className="col-lg-12">
              <h1 className="popular_cources_main_section_heading">
                Similiars
                <span>Courses We Offer</span>
              </h1>
            </div>
          </div>

          <div className="row py-10">
            <div className="col-lg-12 Similiars_Courses_We_Offer"
            responsive={responsive}>
              
            
              {Array.isArray(responseData) && responseData.length > 0 ? (
                  responseData.map((course) => (
                    <div className="card w-[17%] similarCoures_card">
                      <div className="card__inner">
                        <div className="card__front">
                          <div className="">
                            <div className="relative">
                              <div>
                              <img
                                  src={course?.course_image === null
                                    || course?.course_image === undefined
                                    || course?.course_image === ''
                                    ? './plceholdernav/placeholder-img.png'
                                    : course?.course_image
                                  }
                                  className="w-full"
                                  alt=""
                                />
                              </div>
                              {/* <div className="absolute top-0 right-0 p-2">
                                <div>
                                  <i className="fa fa-heart-o text-[red] p-2 rounded-full bg-[white]"></i>
                                </div>
                              </div> */}
                              {/* <div className="absolute top-[-.4rem] left-2">
                               
                                  <img
                                    src={tah_m}
                                    alt=""
                                    className="tag_img"
                                  />
                               
                              </div> */}
                            </div>
                            <div className="py-2 ">
                              <p className="bg-[#d1f9cd] text-[#268a1d] mx-auto px-2 py-1 rounded-xl w-fit courses_card_exam_name">
                                {ReactHtmlParser(`${course?.of_course_category?.category_name.replaceAll('&lt;', '<')}`)}
                              </p>
                            </div>
                            <div className=" text-center">
                              <p className="courses_card_heading my-0">
                                {ReactHtmlParser(course?.course_title.replaceAll('&lt;', '<'))}
                              </p>
                              <p className="my-0">
                                4.8
                                <span>
                                  <i className="fa fa-star text-[#FFD12D]"></i>
                                </span>
                                (878 ratings)
                              </p>
                            </div>
                            <div className="py-1 text-center">
                              <p className="courses_card_exam_time my-0">
                                1 Hour <span className=""> (M) Series</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card__back">
                          <div className=" bg-gradient-to-r from-[#b18cf5] to-[#775EA5] rounded-xl shadow p-2 trendingCourse2 h-[350px]">
                            <div className="relative flex justify-between items-center">
                              <div className="bg-[#66BE5E] text-white py-1 px-2 rounded-2xl mr-2 course_cat_name">
                                <p>
                                  {ReactHtmlParser(course?.of_course_category?.category_name.replaceAll('&lt;', '<'))}
                                </p>
                              </div>
                              {/* <div className="text-white">
                                <p className="font-semibold">
                                  <i className="fa fa-inr"></i>1820
                                  <span className="line-through text-[.7rem] font-light">
                                    2200
                                  </span>
                                </p>
                              </div> */}
                            </div>
                            <div className="py-3">
                              <p className="text-white py-2 text-[.9rem]">
                                {ReactHtmlParser(`${course?.course_subtitle.replaceAll('&lt;', '<')}`)}
                              </p>
                              <p className="text-white mt-1 text-[.9rem]">
                                4.8 <i className="fa fa-star text-white"></i>
                                <span> (878 ratings)</span>
                              </p>
                              <p className="text-white mt-1 text-[.9rem]">
                                14k <i className="fa fa-users text-white"></i>
                                <span> Students Enrolled</span>
                              </p>
                              <p className="text-white mt-1 text-[.9rem]">
                                992 <i className="fa fa-heart text-white"></i>
                                <span> Students Liked</span>
                              </p>
                            </div>
                            <div className='course_pera_box'>
                              <p className="text-white text-[.8rem] font-light line_style courses_pera">
                               
                                {ReactHtmlParser(`${course?.course_description.replaceAll('&lt;', '<')}`)}
                              </p>
                            </div>
                            <div className="flex items-center w-full justify-center pt-[8rem] absolute bottom-3 left-0">
                              <div>
                                <button className="w-full" onClick={()=>{callfunction(course?._id)}}>
                                  <i className="fa fa-shopping-cart p-2 rounded-full bg-white text-[#775EA5] icon_bg_color_change"></i>
                                </button>
                              </div>
                              <div className="bg-[#66BE5E] select-none text-white text-center py-1 px-3 rounded-lg hover:bg-green-700 ml-3 mr-3">
                                <Link
                                  to={`/course/${course?._id}`}
                                  onClick={() => handleCourse(course?._id)}
                                  className="font-medium text-white"
                                >
                                  Buy Now
                                </Link>
                              </div>
                              <div>
                                <button className="w-full" onClick={() => handleShow(course?.course_title, course._id,'course')}>
                                  <i className="fa fa-share p-2 rounded-full bg-white text-[#775EA5] icon_bg_color_change"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoCourses />
                )}
              </div>
            </div>
          </div>
      </section>
    </section>
    <Footer></Footer>
  </>
  
  )
}

export default SimilarCourses
