import React, { useState } from 'react';
import {
  BarChart, Bar, Rectangle, XAxis,Brush, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const data = [
  {
    name: 'Section 1',
    AttemptedQues: 30,
    UnattemptedQues: 2,
    CorrectAnswers: 25,
    IncorrectAnswers: 3,
    ObtainedMarks: parseInt( Math.floor(Math.random() * 100) + 7),
    amt: 2400,
  },
  {
    name: 'Section 5',
    AttemptedQues: 20,
    UnattemptedQues: 2,
    CorrectAnswers: 16,
    IncorrectAnswers: 2,
    ObtainedMarks: parseInt( Math.floor(Math.random() * 100) + 7),
    amt: 2400,
  }
];

function Sectionchart(report) {
  console.log(report.report_data)
  const{mode}=report
  const convertedData = report.report_data.map(item => ({
    ...item,
    testName: item.testName.replace(/&lt;/g, '<')
}));
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={500}
        height={300}
        data={convertedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="testName" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="attempted_question" fill="#5B9BD5" activeBar={<Rectangle  />} />
        <Bar dataKey="unAttempted_question" fill="#ED7D31" activeBar={<Rectangle  />} />
        <Bar dataKey="correct_answer" fill="#A5A5A5" activeBar={<Rectangle  />} />
        <Bar dataKey="incorrect_answer" fill="#FFC000" activeBar={<Rectangle  />} />
        <Bar dataKey="obtained_Marks" fill="#4472C4" activeBar={<Rectangle  />} />
        <Brush dataKey="name" height={20} startInde={0} endIndex={report.report_data.length >= 20 ? 8 : report.report_data.length-1} stroke="#8884d8" />

      </BarChart>
    </ResponsiveContainer>
  )
}

export default Sectionchart