import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {useNavigate} from "react-router-dom"
import axios from 'axios'
import NavyaImg from '../../assets/images/loginPageSigeImg.png'
import PinInput from 'react-pin-input';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Audio } from 'react-loader-spinner'
import { FcHighPriority,FcApproval } from "react-icons/fc";
import NoSigningfooter from './NoSigningfooter'
import Cookies from 'js-cookie'
import UseAxios from '../../util/UseAxios'


function EmailNotVerify() {


  const navigate = useNavigate();

  const location = useLocation()
  console.log(location)
  const [email, setEmail] = useState(location.state.email)
  const [phone, setPhone] = useState('')

 

  let [otp, setOtp] = React.useState('')
  let [Potp, setPotp] = React.useState('')
  let [otpResponseData, setOtpResponseData] = React.useState('')
  let [otpErrorData, setOtpErrorData] = React.useState('')
  const [spinner, setSpinner] = React.useState(false);
  const [emaildisabled, setEmaildisabled] = React.useState(true);
  const [phonedisabled, setPhonedisabled] = React.useState(true);
  const[EotpDisable,setEOtpDisable]=useState(false)
  

  const handleOtpChange = (value) => {
    const otpValue = Array.from(value).reduce((acc, currentValue) => {
      return acc + currentValue;
    }, '');
    if(otpValue.length < 6){
      setEmaildisabled(true)
    }else{
      setEmaildisabled(false)
    }
    setOtp(otpValue);
  }
  const handlePOtpChange = (value) => {
    const otpValue = Array.from(value).reduce((acc, currentValue) => {
      return acc + currentValue;
    }, '');
    if(otpValue.length<6){
      setPhonedisabled(true)
    }else{
      setPhonedisabled(false)
  }
    setPotp(otpValue);

  }
 

    const instance = UseAxios()


      var url = 'api/v1/auth/account-verification/activate-account';
     
    

      const otp_json = {
        email: email,
        otp: parseInt(otp),
      }
    
    
      
      const [formSubmitted, setFormSubmitted] = useState(false);

      const otpSubmit = (event) => {
        console.log("click button")
      event.preventDefault()
      setFormSubmitted(true);
        if (formSubmitted) {

      
          setSpinner(true);
        
          instance
            .post('api/v1/auth/account-verification/activate-account', otp_json)
            .then((response) => {
              console.log(response)
              setSpinner(false);

              toast.success('Email Verified Successfully')
              setEOtpDisable(true)
                setOtpResponseData(response.data)
                setEmaildisabled(true)
                navigate('/signIn'); // Navigate to other page after 2 seconds
              
              

            })
            .catch((error) => {
              console.log(error)
              setSpinner(false);

              if (error) {
                const finalError= error.response.data.msg
                // if(finalError==="Email is already verified"){
                //   setEOtpDisable(true)
                //   setEmaildisabled(true)
                // }
                // console.log(error)
                toast.error(error.response.data.msg)
              }
            })
        }

      
      }
const [resendSpiner,setresendSpiner] = useState(false)
const [resendSpinerphone,setresendSpinerphone] = useState(false)


      
function ResendOtp(){
        
          setresendSpiner(true)
          const email_data ={
            email: email,
          }

      
        instance
            .post('api/v1/auth/account-verification/generate-otp',email_data )
            .then((response) => {
              console.log(response)
              setresendSpiner(false)
              toast.success(response.data.msg)
              navigate('/signIn');
            })
            .catch((error) => {
              console.log(error)
              toast.error(error.response.data.msg)
              setresendSpiner(false)
            
            })
          
        
       
      }

useEffect(()=>{
    ResendOtp()
},[email])


  
  const clearResponse = () => {
    console.log("Edit email")
    localStorage.clear();
    Cookies.remove('user_token')
        navigate('/signIn');
  };
  

  return (
    <>
      <ToastContainer />

    {spinner && (
      <Audio
        height="80"
        width="80"
        radius="9"
        color="green"
        ariaLabel="loading"
        background="transparent"
        wrapperClass="loader"
      />
    )}
    <div className='flex login_signup_main_section'>
      {/* <ToastContainer /> */}
            <div className='basis-1/2 md:block hidden'>
                <img src={NavyaImg} alt="" className='img-fluid login_pgae_img' />
            </div>
            <div className='w-[40%] mx-auto p-5 flex justify-between flex-col'>
                <div>
                    <div>
                        <button onClick={clearResponse}><i className='fa fa-arrow-left font-normal'></i><span className='px- text-lg' > Back</span></button>
                    </div>
                    <div className='font-bold text-2xl pt-8'>
                        <h1>Verification Code</h1>
                    </div>
                    <div className='pt-2'>
                        <p>We texted a one-time verification code To</p>
                        <p className='flex items-center'>
                          {email}
                          <span className='flex items-center pl-1'>
                            {/* <FcHighPriority /> <FcApproval /> */}
                            </span>
                          </p>
                          {/* <p className='flex items-center'>+{65} {76579546856}  <span className='flex items-center pl-1'>
                            <FcHighPriority /> <FcApproval />
                            </span></p> */}
                        {/* <p className='text-[red] select-none ps-1 cursor-pointer' onClick={clearResponse} >Edit Email?</p> */}
                        
                    </div>

                    <div className='pt-10'>
                    <form onSubmit={(event) => {
                      otpSubmit(event);
                    }}>
                      <h5>Email Verification </h5>
                      <PinInput
                          length={6} 
                          initialValue=""
                          // secret
                          disabled={EotpDisable}
                          // secretDelay={100} 
                          onChange={handleOtpChange} 
                          focus={true}
                          type="numeric" 
                          inputMode="number"
                          style={{padding: '10px',display:'flex',justifyContent:"space-between"}}
                          inputFocusStyle={{borderColor: '#D1D1D1'}}
                          onComplete={handleOtpChange}
                          autoSelect={true}
                          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                          name="otp"
                                id="otp"
                                value={otp.split('')}
                                className="opt_input"

                        />
                        <div className='flex justify-end select-none'>
                        { !resendSpiner ? <h6 onClick={ResendOtp} className='text-[#3085d6] cursor-pointer'>Resend Email Otp
                          </h6>
                      : <div className='loadingbox'>
                           <div class="resendloading"></div>

                      </div>}
                      
                        </div>
                      <div>
                      <div className='my-7'>
                                <div className={`${emaildisabled ? "bg-[#a0a0a0] rounded" :"bg-[#66BE5E] rounded"} `} >
                                    <button
                                    disabled={emaildisabled}
                                     type="submit" name="" id="" className='w-full py-3 border-none focus:border-none focus:outline-none text-white' value="Verify And Continue" >
                                    Verify Email
                                      </button>
                                </div>
                            </div>
                      </div>
                    </form>
                    </div>
                 
                </div>
                <NoSigningfooter/>
            </div>
        </div>
      </>
  )
}

export default EmailNotVerify